<template>
  <v-card
    class="ma-3"
    v-if="AuthenticationMixin_LoggedIn && AuthenticationMixin_HasRights(['gebruiker','admin'])">
    <v-row class="justify-space-between">
      <v-col cols="9" md="6" lg="4">
        <v-text-field
          class="pa-2"
          clearable
          v-model="search"
          v-on:input="callDebounceSearch"
          append-icon="mdi-magnify"
          label="Zoeken"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="6" lg="8">
        <v-btn
          class="ma-5 float-right d-none d-md-block"
          color="primary"
          @click="openSchatting()"
        >
          <v-icon>mdi-plus</v-icon>
          Nieuwe schatting
        </v-btn>
      <v-btn
        class="ma-5 float-right d-md-none"
        color="primary"
        @click="openSchatting()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :search="search"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Schattingen per pagina',
        'items-per-page-options': [25, 25, 50, 100]
      }"
      class="elevation-1"
      @click:row="openSchatting"
    >
      <!-- <template v-slot:[`item.status`]="{ item }">
        <v-chip
        style="width: 100px;"
          small
          :color="getStatusColor(item.status)"
          dark
        >
        <span style="width: 100px; text-align: center;">{{ item.status }}</span>
        </v-chip>
      </template> -->

      <template v-slot:[`item.createTimestamp`]="{ item }">
        {{ FormatMixin_FormatDateTime(item.createTimestamp, formatMixin.date.database, formatMixin.date.date) }}
      </template>

      <template v-slot:[`item.datumPlaatsbezoek`]="{ item }">
        {{ FormatMixin_FormatDateTime(item.datumPlaatsbezoek, formatMixin.date.database, formatMixin.date.date) }}
      </template>

      <template v-slot:[`item.datumOpdracht`]="{ item }">
        {{ FormatMixin_FormatDateTime(item.datumOpdracht, formatMixin.date.database, formatMixin.date.date) }}
      </template>

      <template v-slot:[`item.datumOpmaakVerslag`]="{ item }">
        {{ FormatMixin_FormatDateTime(item.datumOpmaakVerslag, formatMixin.date.database, formatMixin.date.date) }}
      </template>

      <template v-slot:[`item.adres`]="{ item }">
        {{ item.straat }} {{ item.huisnummer }}
      </template>

      <template v-slot:[`item.acties`]="{ item }">
        <v-btn
          class="downloadTemplate mr-2"
          icon
          @click="downloadTemplate(item)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn
          class="deleteItem"
          icon
          @click="deleteItem(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
        v-model="deletedialog"
        max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Schatting verwijderen
          </v-card-title>
          <v-card-text v-if="selectedItem">
            Ben je zeker dat je '<strong>{{ selectedItem.straat }} {{ selectedItem.huisnummer }}, {{ selectedItem.gemeente }}</strong>' wilt verwijderen?
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              @click="deletedialog = false">
              Nee
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="DeleteSchatting">
              Ja, verwijderen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-card>
  <v-card class="ma-3" v-else>
    <h2 class="primary--text pa-3">Log in om de schattingenlijst te kunnen bekijken.</h2>
  </v-card>
</template>

<script>
import _ from 'lodash'
import errorMixin from '../mixins/errorMixin'
import schattingService from '../services/schattingService'
import authenticationMixin from '../mixins/authenticationMixin'
import formatMixin from '../mixins/formatMixin'

export default {
  mixins: [
    errorMixin,
    authenticationMixin,
    formatMixin
  ],
  name: 'SchattingList',
  data () {
    return {
      deletedialog: false,
      action: false,
      selectedItem: null,
      totalItems: 0,
      items: [],
      search: null,
      loading: true,
      options: {},
      headers: [
        // {
        //   text: 'Status',
        //   align: 'start',
        //   value: 'status'
        // },
        {
          text: 'Schatting aangemaakt',
          align: 'start',
          value: 'createTimestamp'
        },
        {
          text: 'Opdracht',
          align: 'start',
          value: 'opdracht'
        },
        {
          text: 'Opsteller verslag',
          align: 'start',
          value: 'gebruikerNaamOmgekeerd'
        },
        {
          text: 'Adres',
          align: 'start',
          value: 'adres'
        },
        {
          text: 'Gemeente',
          align: 'start',
          value: 'gemeente'
        },
        {
          text: 'Datum opdracht',
          align: 'start',
          value: 'datumOpdracht'
        },
        {
          text: 'Datum opmaak verslag',
          align: 'start',
          value: 'datumOpmaakVerslag'
        },
        {
          text: 'Datum plaatsbezoek',
          align: 'start',
          value: 'datumPlaatsbezoek'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'acties'
        }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    deletedialog () {
      if (!this.deletedialog) {
        this.action = false
      }
    }
  },
  mounted () {
    schattingService.Init(this)
  },
  methods: {
    async GetSchattingenPaged (sortBy, sortType, search, page, pageSize) {
      const response = await schattingService.GetSchattingenPaged(sortBy, sortType, search, page, pageSize)
      if (response && response.data) {
        const data = response.data
        this.items = data.items
        this.totalItems = data.totalItems
      }
    },
    async getDataFromApi () {
      this.loading = true
      await this.GetSchattingenPaged(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc', this.search, this.options.page, this.options.itemsPerPage).then(data => {
        this.loading = false
      })
    },
    getStatusColor (status) {
      switch (status) {
        case 'Afgehandeld':
          return '#2e7a2e'
        case 'Aanpassen':
          return '#356191'
        case 'Concept':
          return 'darkgrey'
      }
    },
    callDebounceSearch: _.debounce(function () {
      this.debounceSearch()
    }, 500),
    debounceSearch () {
      this.getDataFromApi()
    },
    deleteItem (item) {
      this.selectedItem = item
      this.action = true
      this.deletedialog = true
    },
    downloadTemplate (item) {
      this.selectedItem = item
      this.action = true
      this.$router.push({ path: 'Template', query: { id: item.idSchatting } })
    },
    async openSchatting (id) {
      if (!this.action) {
        if (id) {
          this.$router.push({ path: 'Schatting', query: { id: id.idSchatting } })
        } else {
          var gebruiker = this.AuthenticationMixin_GetCurrentGebruiker()
          if (gebruiker && gebruiker.idGebruiker) {
            var response = await schattingService.CreateNewSchatting({ idGebruiker: gebruiker.idGebruiker })
            if (response && response.data) {
              var idNewSchatting = response.data
              this.idSchatting = idNewSchatting
              this.$router.push({ path: 'Schatting', query: { id: idNewSchatting } })
            }
          }
        }
      }
    },
    async DeleteSchatting () {
      const response = await schattingService.DeleteSchatting(this.selectedItem.idSchatting)
      if (response && response.data) {
        this.getDataFromApi()
      }
      this.editdialog = false
      this.deletedialog = false
    }
  }
}
</script>
