<template>
  <v-row>
    <v-col
      cols="12"
      lg="8"
      @mouseleave="MouseLeave('date')"
      @mouseover="MouseOver('date')"
    >
      <v-text-field
        v-if="!readonly && !disabled && (hoveringDate || focusDate)"
        v-model="date"
        :dense="dense"
        :disabled="disabled"
        :label="dateLabel"
        :readonly="readonly"
        ref="controlDate"
        :rules="Rule"
        type="date"
        @blur="focusDate = false"
        @focus="focusDate = true"
      />
      <v-text-field
        v-if="readonly || disabled || !(hoveringDate || focusDate) || focusDateWrong"
        :dense="dense"
        :disabled="disabled"
        :label="dateLabel"
        :readonly="readonly"
        :rules="Rule"
        :value="DateText"
        @focus="FocusWrong('date')"
      />
    </v-col>
    <v-col
      cols="12"
      lg="4"
      @mouseleave="MouseLeave('time')"
      @mouseover="MouseOver('time')"
    >
      <v-text-field
        v-if="!readonly && !disabled && (hoveringTime || focusTime)"
        v-model="time"
        :dense="dense"
        :disabled="disabled"
        :label="timeLabel"
        :readonly="readonly"
        ref="controlTime"
        :rules="Rule"
        type="time"
        @blur="focusTime = false"
        @focus="focusTime = true"
      />
      <v-text-field
        v-if="readonly || disabled || !(hoveringTime || focusTime) || focusTimeWrong"
        :dense="dense"
        :disabled="disabled"
        :label="timeLabel"
        :readonly="readonly"
        :rules="Rule"
        :value="TimeText"
        @focus="FocusWrong('time')"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ControlDateTime',
  props: {
    dateLabel: String,
    dense: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
    requiredText: {
      default: 'Required.',
      type: String
    },
    timeLabel: String,
    value: String
  },
  data () {
    return {
      date: null,
      focusDate: false,
      focusDateWrong: false,
      focusTime: false,
      focusTimeWrong: false,
      hoveringDate: false,
      hoveringTime: false,
      ignore: false,
      requiredRule: value => !!value || this.requiredText,
      time: null
    }
  },
  mounted () {
    this.FormatData()
  },
  computed: {
    DateText () {
      return this.date ? moment(this.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    Focus () {
      return this.focusDate || this.focusTime
    },
    Rule () {
      if (this.required || (this.date && this.date.trim() !== '') || (this.time && this.time.trim() !== '')) {
        return [this.requiredRule]
      } else {
        return []
      }
    },
    TimeText () {
      return this.time ? this.time : ''
    }
  },
  watch: {
    date () {
      this.Emit()
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    time () {
      this.Emit()
    },
    value () {
      if (this.ignore && !this.disabled) {
        this.ignore = false
      } else {
        this.FormatData()
      }
    }
  },
  methods: {
    Emit () {
      this.ignore = true

      if ((this.date && this.date.trim() !== '') && (this.time && this.time.trim() !== '')) {
        this.$emit('input', `${this.date}T${this.time}:00`)
      } else {
        this.$emit('input', null)
      }
    },
    FocusWrong (control) {
      if (control === 'date') {
        this.focusDateWrong = true
        this.focusDate = true

        this.$nextTick(() => {
          this.$refs.controlDate.focus()
          this.focusDateWrong = false
        })
      } else if (control === 'time') {
        this.focusTimeWrong = true
        this.focusTime = true

        this.$nextTick(() => {
          this.$refs.controlTime.focus()
          this.focusTimeWrong = false
        })
      }
    },
    FormatData () {
      if (this.value) {
        this.date = moment(this.value, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
        this.time = moment(this.value, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')
      } else {
        this.date = null
        this.time = null
      }
    },
    MouseLeave (control) {
      if (control === 'date') {
        this.hoveringDate = false
      } else if (control === 'time') {
        this.hoveringTime = false
      }

      this.$emit('mouseleave')
    },
    MouseOver (control) {
      if (control === 'date') {
        this.hoveringDate = true
      } else if (control === 'time') {
        this.hoveringTime = true
      }

      this.$emit('mouseover')
    }
  }
}
</script>
