<template>
  <vergelijkingspunt-list />
</template>

<script>
import VergelijkingspuntList from '../components/VergelijkingspuntList.vue'

export default {
  name: 'VergelijkingspuntListView',
  components: {
    VergelijkingspuntList
  }
}
</script>
