import DetailView from '../views/DetailView.vue'
import ListView from '../views/ListView.vue'
import ErrorDetailView from '../views/ErrorDetailView.vue'
import SchattingView from '../views/SchattingView.vue'
import SchattingListView from '../views/SchattingListView.vue'
import TheContainer from '../components/TheContainer.vue'
import AdminGebruikersView from '../components/AdminGebruikersView.vue'
import AdminPostcodesView from '../components/AdminPostcodesView.vue'
import AdminTemplatesView from '../components/AdminTemplatesView.vue'
import VergelijkingpuntListView from '../views/VergelijkingspuntListView.vue'
import TemplateDoc from '../views/TemplateView.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'

Vue.use(VueRouter)
Vue.use(VueCookie)

const routes = [
  {
    children: [
      {
        component: DetailView,
        name: 'Detail',
        path: 'Detail'
      },
      {
        component: ListView,
        name: 'List',
        path: 'List'
      },
      {
        component: ErrorDetailView,
        name: 'Error',
        path: 'Error'
      },
      {
        component: SchattingView,
        name: 'Schatting',
        path: 'Schatting'
      },
      {
        component: SchattingListView,
        name: 'Schattingen',
        path: '/'
      },
      {
        component: TemplateDoc,
        name: 'Template',
        path: 'Template'
      },
      {
        component: VergelijkingpuntListView,
        name: 'Vergelijkingspunten',
        path: 'Vergelijkingspunten'
      },
      {
        component: AdminGebruikersView,
        name: 'Gebruikers',
        path: 'Gebruikers'
      },
      {
        component: AdminPostcodesView,
        name: 'Postcodes',
        path: 'Postcodes'
      },
      {
        component: AdminTemplatesView,
        name: 'Templates',
        path: 'Templates'
      },
      {
        component: ErrorDetailView,
        name: 'Error 404',
        path: '*'
      }
    ],
    component: TheContainer,
    path: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
