<template>
  <div
    v-if="AuthenticationMixin_LoggedIn && AuthenticationMixin_HasRights(['admin'])">
  <v-card
    class="ma-3">
    <v-row class="justify-space-between">
      <v-col cols="9" md="6" lg="4">
        <v-text-field
          class="pa-2"
          clearable
          v-model="search"
          v-on:input="CallDebounceSearch"
          append-icon="mdi-magnify"
          label="Zoeken"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="6" lg="8">
        <v-btn
          class="ma-5 float-right d-none d-md-block"
          color="primary"
          @click="OpenTemplate()"
        >
          <v-icon>mdi-plus</v-icon>
          Nieuwe template
        </v-btn>
        <v-btn
        class="ma-5 float-right d-md-none"
        color="primary"
        @click="OpenTemplate()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :search="search"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Templates per pagina',
        'items-per-page-options': [25, 25, 50, 100, -1]
      }"
      class="elevation-1"
      @click:row="OpenTemplate"
    >
      <template v-slot:[`item.acties`]="{ item }">
        <v-btn
        icon
        class="deleteItem"
        @click="DeleteItem(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.isActief`]="{ item }">
        <v-simple-checkbox
        class="ma-0"
          color="green"
          v-model="item.isActief"
          disabled
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    <v-dialog
        v-model="deletedialog"
        max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Template verwijderen
        </v-card-title>
        <v-card-text v-if="selectedItem">
          Ben je zeker dat je '<strong>{{ selectedItem.naam }}</strong>' wilt verwijderen?
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            @click="deletedialog = false">
            Nee
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="DeleteTemplate">
            Ja, verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form
      v-model="valid"
      ref="form"
      lazy-validation>
      <v-dialog
        v-model="editdialog"
        max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            {{ editdialogTitle }}
          </v-card-title>
          <v-card-text v-if="selectedItem">
              <v-text-field
                v-model="selectedItem.Naam"
                label="Naam*"
                required
                :rules="[v => !!v || 'Naam is verplicht']"/>
              <v-text-field
                v-model="selectedItem.Omschrijving"
                label="Omschrijving"/>
              <v-checkbox
                label="Actief"
                v-model="selectedItem.IsActief"
                required/>
              <label for="template">Template (word document)*</label>
              <control-file-input-drag-and-drop-vue id="template" single v-model="template" @download="Download($event)" :accept="['.doc', '.docx']"/>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              text
              @click="editdialog = false">
              Terug
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isEditTemplateDialog"
              color="primary darken-1"
              @click="CreateTemplate">
              Aanmaken
            </v-btn>
            <v-btn
              v-if="isEditTemplateDialog"
              color="primary darken-1"
              @click="UpdateTemplate">
              Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

  </v-card>
  <TemplateParameters />
  </div>
  <v-card class="ma-3" v-else>
    <h2 class="primary--text pa-3">Log in als admin om de template lijst te kunnen bekijken.</h2>
  </v-card>
</template>

<script>
import _ from 'lodash'
import authenticationMixin from '../mixins/authenticationMixin'
import templateService from '../services/templateService'
import ControlFileInputDragAndDropVue from './ControlFileInputDragAndDrop.vue'
import blobMixin from '../mixins/blobMixin'
import commonMixin from '../mixins/commonMixin'
import errorMixin from '../mixins/errorMixin'
import TemplateParameters from '../components/TemplateParameters'

export default {
  name: 'Templates',
  components: {
    ControlFileInputDragAndDropVue,
    TemplateParameters
  },
  mixins: [
    authenticationMixin,
    blobMixin,
    commonMixin,
    errorMixin
  ],
  data () {
    return {
      valid: true,
      items: [],
      search: null,
      loading: true,
      totalItems: 0,
      options: {},
      action: false,
      headers: [
        {
          text: 'Naam',
          align: 'start',
          value: 'naam'
        },
        {
          text: 'Omschrijving',
          align: 'start',
          value: 'omschrijving'
        },
        {
          text: 'Actief',
          align: 'start',
          value: 'isActief'
        },
        {
          text: 'Bestand',
          align: 'start',
          value: 'bestand'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'acties'
        }
      ],
      editdialogTitle: '',
      isEditTemplateDialog: false,
      selectedItem: null,
      editdialog: false,
      deletedialog: false,
      template: null,
      templateType: '24fd1c05-a5fc-4f05-8509-508ea036f5fa'
    }
  },
  mounted () {
    templateService.Init(this)
  },
  watch: {
    options: {
      handler () {
        this.GetDataFromApi()
      },
      deep: true
    },
    deletedialog () {
      if (!this.deletedialog) {
        this.action = false
      }
    }
  },
  methods: {
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    async GetTemplatesPaged (sortBy, sortType, search, page, pageSize) {
      const response = await templateService.GetTemplatesPaged(sortBy, sortType, search, page, pageSize)

      if (response && response.data) {
        const data = response.data
        this.items = data.items
        var blobs = await this.BlobMixin_GetFiles(this.templateType, null)
        this.items.forEach(x => {
          var blob = blobs.filter(b => b.idBlob === x.idBlob)
          x.bestand = blob.length > 0 ? blob[0].name : null
          return x
        })
        this.totalItems = data.totalItems
      }
    },
    async GetDataFromApi () {
      this.loading = true
      await this.GetTemplatesPaged(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc', this.search, this.options.page, this.options.itemsPerPage).then(data => {
        this.loading = false
      })
    },
    CallDebounceSearch: _.debounce(function () {
      this.DebounceSearch()
    }, 500),
    DebounceSearch () {
      this.GetDataFromApi()
    },
    async OpenTemplate (template) {
      this.$refs.form.resetValidation()
      if (!this.action) {
        if (template) {
          this.editdialogTitle = 'Wijzig template'
          this.isEditTemplateDialog = true
          this.selectedItem = {
            IdTemplate: template.idTemplate,
            Naam: template.naam,
            Omschrijving: template.omschrijving,
            IsActief: template.isActief,
            IdBlob: template.idBlob,
            CreateTimestamp: template.createTimestamp
          }
          var blobs = (await this.BlobMixin_GetFiles(this.templateType, null)).filter(x => x.idBlob === this.selectedItem.IdBlob)
          this.template = []
          if (blobs.length > 0) {
            this.template.push(blobs[0])
          }
        } else {
          this.editdialogTitle = 'Nieuwe template'
          this.isEditTemplateDialog = false
          this.selectedItem = {
            Naam: null,
            Omschrijving: null,
            IsActief: true
          }
          this.template = []
        }
        this.editdialog = true
      }
    },
    DeleteItem (item) {
      this.selectedItem = item
      this.action = true
      this.deletedialog = true
    },
    async HandleBlob (handleType = 'create') {
      var success = false
      this.selectedItem.IdBlob = null
      if (this.$refs.form.validate()) {
        if (this.template !== null) {
          // save new or updated blobs
          const savedFiles = await this.BlobMixin_SaveFiles(this.template.filter(x => x.status !== this.blobMixin.status.deleted), this.templateType, null)

          if (savedFiles != null) {
            this.selectedItem.IdBlob = savedFiles[0].idBlob

            let response = null
            switch (handleType) {
              case 'create' :
                response = await templateService.Create(this.selectedItem)
                break
              case 'update' :
                response = await templateService.Update(this.selectedItem)
                break
            }

            // delete 'deleted' blobs
            await this.BlobMixin_SaveFiles(this.template.filter(x => x.status === this.blobMixin.status.deleted), this.templateType, null)

            if (response && response.data) {
              success = true
              this.editdialog = false
              this.GetDataFromApi()
            }
          }
        }
      }

      switch (handleType) {
        case 'create' :
          this.CommonMixin_SuccessOrFailMessage(success, 'Template aangemaakt.', 'Template aanmaken mislukt.')
          break
        case 'update' :
          this.CommonMixin_SuccessOrFailMessage(success, 'Template aangepast.', 'Template aanpassen mislukt.')
          break
      }
    },
    async CreateTemplate () {
      this.HandleBlob()
    },
    async UpdateTemplate () {
      this.HandleBlob('update')
    },
    async DeleteTemplate () {
      this.BlobMixin_DeleteBlobById(this.selectedItem.idBlob)
      const response = await templateService.Delete(this.selectedItem.idTemplate)

      if (response && response.data) {
        this.editdialog = false
        this.deletedialog = false
        this.GetDataFromApi()
      }
    }
  }
}
</script>
