<template>
  <v-form class="my-2"
    v-model="detailMixin.valid"
    ref="formAfmeting">
    <div
    v-if="detailMixin.element && detailMixin.element.afmetingen"
    :class="DetailMixin_Dirty ? 'dirtyForm' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editForm' : ''"
    >
      <h2 class="primary--text">Afmetingen</h2>
      <h3 class="secondary--text my-3">Perceel</h3>
      <v-row class="pb-0">
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="Perceelbreedte" suffix="m" v-model="detailMixin.element.afmetingen.perceelBreedte" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="Perceeloppervlakte" suffix="m²" v-model="detailMixin.element.afmetingen.perceelOppervlakte" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="Breedte voorgevel" suffix="m" v-model="detailMixin.element.afmetingen.voorgevelBreedte" />
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Bebouwde oppervlakte</h3>
      <v-row class="pb-0">
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="Kelder" suffix="m²" v-model="detailMixin.element.afmetingen.oppervlakteKelder" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="Gelijkvloers" suffix="m²" v-model="detailMixin.element.afmetingen.oppervlakteGelijkvloers" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="1ste Verdieping" suffix="m²" v-model="detailMixin.element.afmetingen.oppervlakteVerdieping1" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="2de Verdieping" suffix="m²" v-model="detailMixin.element.afmetingen.oppervlakteVerdieping2" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="3de Verdieping" suffix="m²" v-model="detailMixin.element.afmetingen.oppervlakteVerdieping3" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="Dakverdieping" suffix="m²" v-model="detailMixin.element.afmetingen.oppervlakteVerdiepingDak" />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <control-number :decimals="2" label="Bijgebouw" suffix="m²" v-model="detailMixin.element.afmetingen.oppervlakteBijgebouw" />
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Extra afmetingen</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6">
          <v-textarea label="Beschrijving" auto-grow single-line outlined v-model="detailMixin.element.afmetingen.extra" />
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Bijlage</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6">
          <control-file-input-drag-and-drop v-model="detailMixin.element.files" @download="Download($event)" :accept="['.png', '.jpg', '.jpeg', '.gif']"/>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import ControlNumber from '../ControlNumber.vue'
import ControlFileInputDragAndDrop from '../ControlFileInputDragAndDrop.vue'
import schattingService from '../../services/schattingService'
import commonMixin from '../../mixins/commonMixin'
import errorMixin from '../../mixins/errorMixin'
import detailMixin from '../../mixins/detailMixin'
import blobMixin from '../../mixins/blobMixin'

export default {
  mixins: [
    commonMixin,
    errorMixin,
    detailMixin,
    blobMixin
  ],
  components: {
    ControlNumber,
    ControlFileInputDragAndDrop
  },
  props: {
    idSchatting: {
      default: null,
      type: String
    },
    visible: Boolean
  },
  data () {
    return {
      referentieType: 'e800b130-e7f5-453d-8cd8-fe73c7959e88'
    }
  },
  mounted () {
    schattingService.Init(this)
  },
  watch: {
    async visible () {
      if (this.visible) {
        await this.SetVariables()
        await this.GetSchatting()
      } else if (this.DetailMixin_Dirty) {
        this.DetailMixin_Save()
      }
    }
  },
  methods: {
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    async GetSchatting () {
      this.CommonMixin_Load(this.$options.name, true)

      const id = this.DetailMixin_GetId()

      if (id) {
        const responseAfmetingen = await schattingService.GetAfmetingenByIdSchatting(id)
        const element = {
          afmetingen: {},
          files: []
        }

        element.files = await this.BlobMixin_GetFiles(this.idSchatting, this.referentieType)

        if (responseAfmetingen && (responseAfmetingen.data || responseAfmetingen.data !== '')) {
          element.afmetingen = this.CommonMixin_Clone(responseAfmetingen.data)

          this.$forceUpdate()
        }

        this.detailMixin.element = this.CommonMixin_Clone(element)

        this.DetailMixin_InitDirtyWatch()
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    SetVariables () {
      this.detailMixin.key = 'id'
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.detailMixin.functions.validate = this.$refs.formAfmeting.validate
      this.detailMixin.functions.save = this.save
    },
    async save (element) {
      var schattingAfmeting = await schattingService.UpdateSchattingAfmeting(element.afmetingen)
      await this.BlobMixin_SaveFiles(element.files, this.idSchatting, this.referentieType)
      var schattingAfmetingData = null
      if (schattingAfmeting && schattingAfmeting.data) {
        schattingAfmetingData = schattingAfmeting.data
      }
      return {
        data: {
          afmetingen: schattingAfmetingData
        }
      }
    },
    Validate () {
      this.$refs.formAfmeting.validate()
      return this.detailMixin.valid
    }
  }
}
</script>
