export default {
  data () {
    return {
      constantMixin: {
        authenticatie: {
          type: {
            onbekend: 0,
            admin: 1,
            gebruiker: 2
          }
        },
        mapType: {
          grb_bsk: 0,
          grb_bsk_grijs: 1,
          gewestplan: 2,
          google_stratenplan: 3
        }
      }
    }
  }
}
