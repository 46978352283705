export default {
  methods: {
    JsonTree (json, name = 'debugger') {
      let html = ''
      if (json && typeof json !== 'object') {
        json = JSON.parse(json)
      }

      if (json && typeof json === 'object') {
        html = `<ul class="tree"><li><details><summary><strong>${name}</strong> {${Object.keys(json).length}}</summary><ul>${this.GetHtml(json)}</ul></details></li></ul>`
      } else {
        html = `<ul class="tree"><li><strong>${name}</strong> <span class="debugMixin-null">${json}</span></li></ul>`
      }

      return html
    },
    GetHtml (json) {
      let html = ''
      if (json && typeof json === 'object') {
        const keys = Object.keys(json)
        if (keys.length > 0) {
          keys.forEach(key => {
            const objectHtml = '<li><details><summary><span class="debugMixin-key">"' + key + '": </span>' + this.GetValue(json[key]) + '</summary><ul>' + this.GetHtml(json[key]) + '</ul></details></li>'
            if (json[key] && (this.IsObject(json[key]) && !Array.isArray(json[key]))) {
              html += objectHtml
            } else if (Array.isArray(json[key]) && json[key].length > 0) {
              html += objectHtml
            } else {
              html += '<li><span class="debugMixin-key">"' + key + '": </span>' + this.GetValue(json[key]) + '</li>'
            }
          })
        }
      }
      return html
    },
    IsObject (value) {
      return typeof value === 'object'
    },
    GetValue (value) {
      let cls
      let val
      if (value === null || value === undefined) {
        cls = 'debugMixin-null'
        val = value
      } else if (typeof value === 'string') {
        cls = 'debugMixin-string'
        val = `"${value}"`
      } else if (typeof value === 'boolean') {
        cls = 'debugMixin-boolean'
        val = value
      } else if (typeof value === 'number') {
        cls = 'debugMixin-number'
        val = value
      } else if (Array.isArray(value)) {
        val = `[${value.length}]`
      } else if (typeof value === 'object') {
        cls = 'debugMixin-object'
        val = `{${Object.keys(value).length}}`
      }

      return `<span class="${cls}">${val}</span>`
    }
  }
}
