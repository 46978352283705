import axios from 'axios'

let _svc
let _that

export default {
  GetSchattingenPaged: (sortBy, sortType, search, page = 1, pageSize = 10) => {
    try {
      var res = _svc.get('Schatting/Paged', {
        params: {
          sortBy: sortBy === undefined || sortBy === null ? 'CreateTimestamp' : sortBy,
          sortType: sortType === undefined || sortType === null ? 'desc' : sortType,
          search: search === undefined || search === null ? ' ' : search,
          page: page,
          pageSize: pageSize
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetSchattingById: (id) => {
    try {
      var res = _svc.get('Schatting/GetSchattingById', {
        params: {
          id: id
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetAlgemeneGegevensByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetAlgemeneGegevensByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetAfmetingenByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetAfmetingenByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetBeschrijvingPandByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetBeschrijvingPandByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetBouwmateriaalByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetBouwmateriaalByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetNutsvoorzieningComfortByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetNutsvoorzieningComfortByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetWaardeByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetWaardeByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetVergelijkingspuntenByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetVergelijkingspuntenByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetBijzonderElementByIdSchatting: (idSchatting) => {
    try {
      var res = _svc.get('Schatting/GetBijzonderElementByIdSchatting', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  UpdateSchatting: (schatting) => {
    try {
      var res = _svc.put('Schatting/UpdateSchatting', schatting)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingAlgemeneGegevens: (schattingAlgemeneGegevens) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingAlgemeneGegevens', schattingAlgemeneGegevens)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingAfmeting: (schattingAfmeting) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingAfmeting', schattingAfmeting)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingBeschrijvingPand: (schattingBeschrijvingPand) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingBeschrijvingPand', schattingBeschrijvingPand)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingBouwmateriaal: (schattingBouwmateriaal) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingBouwmateriaal', schattingBouwmateriaal)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingNutsvoorzieningComfort: (schattingNutsvoorzieningComfort) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingNutsvoorzieningComfort', schattingNutsvoorzieningComfort)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingWaarde: (schattingWaarde) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingWaarde', schattingWaarde)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingVergelijkingspunten: (schattingVergelijkingspunten) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingVergelijkingspunten', schattingVergelijkingspunten)
      return res
    } catch {
      return null
    }
  },

  UpdateSchattingBijzonderElement: (schattingBijzonderElement) => {
    try {
      var res = _svc.put('Schatting/UpdateSchattingBijzonderElement', schattingBijzonderElement)
      return res
    } catch {
      return null
    }
  },

  CreateNewSchatting: (gebruiker) => {
    try {
      var res = _svc.post('Schatting/CreateNewSchatting', gebruiker)
      return res
    } catch {
      return null
    }
  },

  DeleteSchatting: (idSchatting) => {
    try {
      var res = _svc.delete('Schatting/Delete', {
        params: {
          idSchatting: idSchatting
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetEntireSchattingById: (id) => {
    try {
      var res = _svc.get('Schatting/GetEntireSchattingById', {
        params: {
          id: id
        }
      })
      return res
    } catch {
      return null
    }
  },

  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  }
}
