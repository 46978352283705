import axios from 'axios'

let _svc
let _that

export default {
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  },
  GetGeoMapGRB: (straat, huisnummer, gemeente, width, height, mapType) => {
    try {
      return _svc.get('GeoMap/GRB', {
        params: {
          straat: straat,
          huisnummer: huisnummer,
          gemeente: gemeente,
          width: width,
          height: height,
          mapType: mapType
        }
      })
    } catch {
      return null
    }
  }
}
