<template>
  <div
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-text-field
      v-if="readonly || disabled || !(hovering || focus) || focusWrong"
      :append-icon="reverse ? icon : ''"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :prefix="reverse ? suffix : prefix"
      :prepend-inner-icon="reverse ? '' : icon"
      :readonly="readonly"
      :reverse="reverse"
      :rules="Rules"
      :suffix="reverse ? prefix : suffix"
      :value="TextValue"
      @focus="FocusWrong()"
    />
    <v-text-field
      v-if="!readonly && !disabled && (hovering || focus)"
      v-model="number"
      :append-icon="reverse ? icon : ''"
      :clearable="ShowControls"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :prefix="reverse ? suffix : prefix"
      :prepend-inner-icon="reverse ? '' : icon"
      :readonly="readonly"
      ref="control"
      :reverse="reverse"
      :rules="Rules"
      :suffix="reverse ? prefix : suffix"
      @blur="Change()"
      @click:clear="Change()"
      @focus="Focus()"
      @keypress="IsNumber($event)"
      @keyup="CheckFormat()"
    />
  </div>
</template>
<script>
export default {
  name: 'ControlNumber',
  props: {
    prefix: String,
    suffix: String,
    currency: Boolean,
    decimals: Number,
    dense: Boolean,
    disabled: Boolean,
    icon: {
      default: '',
      type: String
    },
    invalidNumberText: {
      default: 'Invalid number.',
      type: String
    },
    label: String,
    max: Number,
    min: Number,
    naturalNumber: Boolean,
    readonly: Boolean,
    required: Boolean,
    requiredText: {
      default: 'Required.',
      type: String
    },
    reverse: {
      default: true,
      type: Boolean
    },
    value: [Number, String],
    withDotSeparator: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      focus: false,
      focusWrong: false,
      hovering: false,
      number: null,
      originalValue: null,
      rules: {
        between: value => {
          let valid = true
          if ((value < this.min) || (value > this.max)) {
            valid = false
          }
          return value == null || valid || this.invalidNumberText
        },
        naturalNumber: value => {
          const pattern = /^(?:0|[1-9][0-9]*)$/
          return (value == null || value === '' || isNaN(value) || pattern.test(value)) || this.invalidNumberText
        },
        required: value => value != null || this.requiredText
      },
      zeroesAfterDot: false
    }
  },
  mounted () {
    this.FormatData()
  },
  computed: {
    Rules () {
      const rules = [this.rules.between]
      if (this.naturalNumber) {
        rules.push(this.rules.naturalNumber)
      }
      if (this.required) {
        rules.push(this.rules.required)
      }
      return rules
    },
    ShowControls () {
      return !this.readonly && (this.hovering || this.focus)
    },
    TextValue () {
      if ((this.number && !isNaN(this.number)) || this.number === 0) {
        const number = this.SetDecimals(this.number, null, 'de-DE')
        return this.withDotSeparator ? number : number.replaceAll('.', '')
      } else {
        return null
      }
    }
  },
  watch: {
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      this.FormatData()
    }
  },
  methods: {
    Change () {
      this.number = this.SetDecimals(this.number)
      this.focus = false
      if (this.originalValue !== this.number) {
        this.$emit('change', this.number)
        this.Emit()
      }
    },
    CheckFormat () {
      if (this.number && this.number.includes('-') && !this.number.startsWith('-')) {
        this.number = this.number.replace('-', '')
      }
    },
    Emit () {
      if ((this.number != null && !isNaN(this.number))) {
        this.$emit('input', parseFloat(parseFloat(this.number).toFixed(this.decimals ?? 0)))
      } else {
        this.$emit('input', null)
      }
    },
    Focus () {
      this.focus = true
      this.originalValue = this.number
    },
    FocusWrong () {
      this.focusWrong = true
      this.focus = true
      this.$nextTick(() => {
        this.$refs.control.focus()
        this.focusWrong = false
      })
    },
    FormatData () {
      this.number = this.SetDecimals(this.value)
    },
    MouseLeave () {
      this.hovering = false
      this.$emit('mouseleave')
    },
    MouseOver () {
      this.hovering = true
      this.$emit('mouseover')
    },
    IsNumber (value) {
      const char = String.fromCharCode(value.keyCode)
      let prevent = false
      if (!(/^[-.0-9]+$/.test(char))) {
        prevent = true
      } else {
        const singleUseChars = ['-', '.']
        if (singleUseChars.includes(char) && this.number && this.number.includes(char)) {
          prevent = true
        }
      }
      if (prevent) {
        value.preventDefault()
      }
    },
    SetDecimals (value, decimals = null, format = 'en-US') {
      if (value) {
        const formatter = new Intl.NumberFormat(format, {
          style: 'decimal',
          maximumFractionDigits: decimals ?? this.decimals ?? 0,
          minimumFractionDigits: decimals ?? this.decimals ?? 0
        })
        if (format === 'en-US') {
          return formatter.format(value ?? 0).replaceAll(',', '')
        } else {
          return formatter.format(value ?? 0)
        }
      } else {
        return value
      }
    }
  }
}
</script>
