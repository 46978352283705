<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setSidebar(!sidebar)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light darkMode"
      v-text="$route.name"
    />
    <v-spacer/>
    <div class="d-flex" v-if="AuthenticationMixin_GetCurrentGebruiker()">
      <v-icon class="pr-2">mdi-account-circle</v-icon>
      <h3 class="primary--text pr-5">{{ AuthenticationMixin_GetCurrentGebruiker().naamOmgekeerd }}</h3>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import authenticationMixin from '../mixins/authenticationMixin'

export default {
  name: 'TheTopbar',
  mixins: [
    authenticationMixin
  ],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['sidebar'])
  },
  methods: {
    ...mapMutations({
      setSidebar: 'SET_SIDEBAR'
    })
  }
}
</script>
