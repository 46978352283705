<template>
  <div>
    <v-list-group
      :group="group"
      :prepend-icon="item.icon"
      :sub-group="subGroup"
      append-icon="mdi-menu-down"
      :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1'"
      :class="`primary ${$vuetify.theme.isDark ? 'white' : 'black'}--text`"
      v-if="item.children"
      v-model="activeGroup"
    >
      <template v-slot:activator>
        <v-list-item-icon
          v-if="text"
          class="v-list-item__icon--text"
          v-text="computedText"
        />
        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
        </v-list-item-content>
      </template>
      <template v-for="(child, i) in children">
        <menu-item-sub-group
          v-if="child.children && AuthenticationMixin_HasRights(child.types)"
          :key="`sub-group-${i}`"
          :item="child"
        />
        <menu-item
          v-else-if="AuthenticationMixin_HasRights(child.types)"
          :key="`item-${i}`"
          :item="child"
          text
        />
      </template>
    </v-list-group>
    <menu-item
          v-else-if="AuthenticationMixin_HasRights(item.types)"
          :item="item"
        />
  </div>
</template>

<script>
// Utilities
import authenticationMixin from '@/mixins/authenticationMixin'
import kebabCase from 'lodash/kebabCase'
import MenuItem from './MenuItem.vue'
import MenuItemSubGroup from './MenuItemSubGroup.vue'
import { mapState } from 'vuex'

export default {
  name: 'ItemGroup',
  components: {
    MenuItem,
    MenuItemSubGroup
  },
  mixins: [
    authenticationMixin
  ],
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: undefined
      })
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeGroup: false
    }
  },
  mounted () {
    this.SetActiveGroup()
  },
  watch: {
    async '$route.path' () {
      this.$nextTick(() => {
        this.SetActiveGroup()
      })
    }
  },
  computed: {
    ...mapState(['barColor']),
    children () {
      if (this.item.children) {
        return this.item.children.map(item => ({
          ...item,
          to: !item.to ? undefined : `${this.item.group}/${item.to}`
        }))
      }
      return this.item.children
    },
    computedText () {
      if (!this.item || !this.item.title) return ''

      let text = ''

      this.item.title.split(' ').forEach(val => {
        text += val.substring(0, 1)
      })

      return text
    },
    group () {
      return this.genGroup(this.item.children)
    }
  },
  methods: {
    genGroup (children) {
      return children
        .filter(item => item.to)
        .map(item => {
          const parent = item.group || this.item.group
          let group = `${parent}/${kebabCase(item.to)}`

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`
          }

          return group
        }).join('|')
    },
    SetActiveGroup () {
      if (this.item.children) {
        this.activeGroup = this.children.filter(child => this.$route.path === child.to).length > 0
      }
    }
  }
}
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
