import authenticationService from '@/services/authenticationService'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import axios from 'axios'

export default {
  mixins: [
    commonMixin,
    constantMixin
  ],
  data () {
    return {
      authenticationMixin: {
        cookie: {
          name: 'LSGLogin',
          timespan: '1Y'
        },
        header: 'lsg-authorization',
        key: 'LSGLandmetersAuthentication'
      }
    }
  },
  computed: {
    AuthenticationMixin_LoggedIn () {
      return this.$store.state.authentication != null
    },
    AuthenticationMixin_LoggedInType () {
      return this.AuthenticationMixin_LoggedIn ? this.$store.state.authentication.type : null
    }
  },
  created () {
    authenticationService.Init(this)
    this.AuthenticationMixin_LoadSessionStorageAuthentication()
  },
  methods: {
    AuthenticationMixin_GetCurrentGebruiker () {
      if (this.$store.state.authentication) {
        return {
          idGebruiker: this.$store.state.authentication.idGebruiker,
          achternaam: this.$store.state.authentication.achternaam,
          naam: `${this.$store.state.authentication.voornaam} ${this.$store.state.authentication.achternaam}`.trim(),
          naamOmgekeerd: `${this.$store.state.authentication.achternaam} ${this.$store.state.authentication.voornaam}`.trim(),
          voornaam: this.$store.state.authentication.voornaam,
          email: this.$store.state.authentication.email
        }
      }
      return null
    },
    AuthenticationMixin_HasRights (allowedTypes) {
      return this.AuthenticationMixin_LoggedInType && allowedTypes ? allowedTypes.map(type => this.constantMixin.authenticatie.type[type]).includes(this.AuthenticationMixin_LoggedInType) : false
    },
    AuthenticationMixin_LoadSessionStorageAuthentication () {
      this.$store.state.authentication = JSON.parse(sessionStorage.getItem(this.authenticationMixin.key))

      if (this.$store.state.authentication) {
        this.$store.state.loggedIn = true
        this.AuthenticationMixin_SetHeader(this.authenticationMixin.header, this.$store.state.authentication.token)
      } else {
        this.$store.state.loggedIn = false
      }
    },
    async AuthenticationMixin_Login (account, remember = false) {
      if (account) {
        this.CommonMixin_Load(this.$options.name, true)
        const resp = await authenticationService.Login(account)

        if (resp) {
          if (remember) {
            this.$cookie.set(this.authenticationMixin.cookie.name, account.email, { expires: this.authenticationMixin.cookie.timespan })
          } else if (this.$cookie) {
            this.$cookie.delete(this.authenticationMixin.cookie.name)
          }
          this.$store.state.authentication = resp.data
          sessionStorage.setItem(this.authenticationMixin.key, JSON.stringify(this.$store.state.authentication))
          this.AuthenticationMixin_SetHeader(this.authenticationMixin.header, resp.data.token)
          this.CommonMixin_Load(this.$options.name, false)
          location.reload()
          return true
        } else {
          this.CommonMixin_Load(this.$options.name, false)
          return false
        }
      }
    },
    async AuthenticationMixin_Logout () {
      this.CommonMixin_Load(this.$options.name, true)
      this.$store.state.authentication = null
      this.$store.state.loggedIn = false
      this.AuthenticationMixin_SetHeader(this.authenticationMixin.header, null)
      sessionStorage.clear(this.authenticationMixin.key)
      this.CommonMixin_Load(this.$options.name, false)

      const redirectLocation = '/'

      if (this.$router.currentRoute.path !== redirectLocation) {
        await this.$router.push('/')
      }

      location.reload()
    },
    AuthenticationMixin_SetHeader (headerName, value) {
      axios.defaults.headers.common[headerName] = value
    }
  }
}
