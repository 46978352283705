<template>
  <v-app>
    <the-loader />
    <the-sidebar />
    <the-topbar />
    <the-view />
    <the-snackbar-stack />
  </v-app>
</template>

<script>
import TheLoader from './TheLoader.vue'
import TheSidebar from './TheSidebar.vue'
import TheSnackbarStack from './TheSnackbarStack.vue'
import TheTopbar from './TheTopbar.vue'
import TheView from './TheView.vue'

export default {
  name: 'DashboardIndex',
  components: {
    TheLoader,
    TheSidebar,
    TheSnackbarStack,
    TheTopbar,
    TheView
  },
  data: () => ({
    expandOnHover: false
  })
}
</script>
