<template>
  <v-text-field
    class="option-list"
    v-model="selection"
    dense
    :label="label"
    :rules="rules"
    @input="Input($event)"
  >
    <template slot="prepend">
      <v-menu
        right
        offset-x
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-icon
            v-bind="attrs"
            v-on="on"
          >
            mdi-text-box-edit-outline
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in list"
            :key="index"
            class="v-menu-list-item"
            @click="Input(item)"
          >
            <v-list-item-title>
              {{ item }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: String,
    list: Array,
    label: String,
    rules: Array
  },
  data () {
    return {
      selection: null
    }
  },
  mounted () {
    this.SetSelection()
  },
  watch: {
    value () {
      this.SetSelection()
    }
  },
  methods: {
    SetSelection () {
      this.selection = this.value
    },
    Input (item) {
      this.$emit('input', item)
    }
  }
}
</script>

<style scoped>
.option-list {
  padding-top: 6px !important;
}
</style>
