<template>
  <v-card
    class="ma-3"
    v-if="AuthenticationMixin_LoggedIn && AuthenticationMixin_HasRights(['admin'])">
  </v-card>
</template>

<script>
import authenticationMixin from '../mixins/authenticationMixin'
export default {
  name: 'Postcodes',
  mixins: [
    authenticationMixin
  ]
}
</script>
