<template>
 <v-form class="px-3 my-2">
    <div>
      <h2 v-if="schatting" class="primary--text my-3">{{ schatting.straat }} {{ schatting.huisnummer }}, {{ schatting.gemeente }}</h2>
      <h3 class="secondary--text my-3">Template</h3>

      <v-row class="pb-0">
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="template"
            :items="templates"
            item-text="naam"
            return-object/>
        </v-col>
        <v-col cols="12" md="6">
            <v-textarea
              v-if="template"
              v-model="template.omschrijving"
              label="Omschrijving"
              rows="1"
              auto-grow
              readonly
              />
            <v-textarea
              v-else
              label="Omschrijving"
              rows="1"
              auto-grow
              readonly
              />
        </v-col>
      </v-row>
      <v-btn class="my-3" color="primary" :disabled="output == null && !loadingOutput" :loading="loadingOutput" @click="DownloadTemplate()">Download document</v-btn>
    </div>
  </v-form>
</template>

<script>
import commonMixin from '../mixins/commonMixin'
import errorMixin from '../mixins/errorMixin'
import templateService from '../services/templateService'
import schattingService from '../services/schattingService'
import blobService from '../services/blobService'
import blobMixin from '../mixins/blobMixin'

export default {
  mixins: [
    errorMixin,
    commonMixin,
    blobMixin
  ],
  data: function () {
    return {
      templates: [],
      template: null,
      output: null,
      loadingOutput: false,
      idSchatting: null,
      schatting: null
    }
  },
  mounted () {
    templateService.Init(this)
    schattingService.Init(this)
    blobService.Init(this)
    this.GetTemplates()
    this.getIdSchatting()
  },
  watch: {
    template () {
      this.loadingOutput = true
      if (this.template !== null) {
        this.processTemplate()
      }
    },
    idSchatting () {
      if (this.idSchatting) {
        this.GetSchatting()
      }
    }
  },
  methods: {
    async GetTemplates () {
      const response = await templateService.GetAll(true)
      if (response && response.data) {
        this.templates = response.data
      }
    },
    getIdSchatting () {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('id')) {
        this.idSchatting = urlParams.get('id')
      } else {
        this.$router.push({ path: '/' })
      }
    },
    async GetSchatting () {
      this.CommonMixin_Load(this.$options.name, true)

      const id = this.idSchatting
      const responseSchatting = await schattingService.GetAlgemeneGegevensByIdSchatting(id)
      if (responseSchatting && responseSchatting.data) {
        this.schatting = responseSchatting.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    async processTemplate () {
      const resp = await blobService.Download(this.template.idBlob)
      if (resp && resp.data) {
        const reader = new FileReader()
        const blob = resp.data
        reader.onload = (e) => {
          this.GetDocument({
            Blob: {
              BlobDataString: e.target.result,
              BlobDataPrefix: `${e.target.result.substr(0, e.target.result.indexOf('base64,') + 7)}`
            },
            IdSchatting: this.idSchatting
          })
        }
        reader.readAsDataURL(blob)
      }
    },
    async GetDocument (template) {
      var response = await templateService.GetDocument(template)
      if (response && response.data) {
        this.output = response.data
        this.loadingOutput = false
      }
    },
    DownloadTemplate () {
      if (this.output != null) {
        const link = document.createElement('a')
        link.href = this.output
        link.download = this.schatting.gemeente.substring(5) + ' ' + this.schatting.straat + ' ' + this.schatting.huisnummer + '.docx'
        link.click()
      }
    }
  }
}
</script>
