export default {
  data () {
    return {
      recentMixin: {
        key: 'Recent',
        max: 10
      }
    }
  },
  computed: {
    RecentMixin_RecentItems () {
      return this.$store.state.recent
    }
  },
  created () {
    this.RecentMixin_LoadSessionStorageRecent()
  },
  methods: {
    RecentMixin_LoadSessionStorageRecent () {
      this.$store.state.recent = JSON.parse(sessionStorage.getItem(this.recentMixin.key)) ?? []
    },
    RecentMixin_AddRecentItem (item) {
      this.RecentMixin_LoadSessionStorageRecent()
      const index = this.$store.state.recent.findIndex(x => x.id === item.id)

      if (index >= 0) {
        this.$store.state.recent.splice(index, 1)
      }

      this.$store.state.recent.unshift(item)

      if (this.$store.state.recent.length > this.recentMixin.max) {
        this.$store.state.recent = this.$store.state.recent.slice(0, this.recentMixin.max)
      }

      sessionStorage.setItem(this.recentMixin.key, JSON.stringify(this.$store.state.recent))
    }
  }
}
