<template>
  <div>
    <!-- <v-btn
      v-if="!edit"
      class="float-right"
      color="primary"
      :disabled="disabled"
      @click="$emit('edit')"
    >
      Wijzigen
    </v-btn> -->
    <v-btn
      v-if="edit && revertable"
      class="float-right ml-1"
      color="primary"
      :disabled="disabled"
      @click="$emit('revertChanges')"
    >
      Annuleren
    </v-btn>
    <v-btn
      v-if="dirty"
      class="float-right ml-1"
      color="primary"
      :disabled="disabled"
      @click="$emit('save')"
    >
      Opslaan
    </v-btn>
    <v-btn
      v-else-if="revertable"
      class="float-right ml-1"
      color="primary"
      :disabled="disabled"
      @click="$emit('cancel')"
    >
      Opslaan
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ControlEditCommands',
  props: {
    dirty: Boolean,
    disabled: Boolean,
    edit: Boolean,
    revertable: Boolean
  }
}
</script>
