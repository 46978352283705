<template>
  <schatting ref="schatting" />
</template>

<script>
import Schatting from '../components/Schatting'

export default {
  name: 'SchattingView',
  components: {
    Schatting
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.schatting.Leave(next)
  }
}
</script>
