import { render, staticRenderFns } from "./under-construction.vue?vue&type=template&id=052302ff&"
import script from "./under-construction.vue?vue&type=script&lang=js&"
export * from "./under-construction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports