<template>
  <div>
    <template-doc />
  </div>
</template>

<script>
import TemplateDoc from '../components/TemplateDoc'

export default {
  name: 'TemplateView',
  components: {
    TemplateDoc
  }
}
</script>
