<template>
  <v-container class="pa-0" fluid>
    <v-skeleton-loader v-if="!title"
            type="heading"
          ></v-skeleton-loader>
    <h3 v-if="title" class="secondary--text my-3">{{ title }}</h3>
    <v-row class="pa-0 px-3">
      <v-col>
        <div v-if="!list">
          <v-row class="pa-0">
            <v-col class="py-2" v-for="col in cols" v-bind:key="col">
              <v-skeleton-loader
                type="list-item,list-item,list-item,list-item"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
        <div v-if="!radio && list">
          <v-row class="pa-0 ma-5">
            <v-col v-for="col in cols" v-bind:key="col">
              <div
                v-for="item in itemLists[col-1]"
                v-bind:key="item.idCode">
                <v-row class="pa-0" v-if="!item.heeftExtraOmschrijving">
                  <v-checkbox
                    v-model="item.checked"
                    :label="item.langeBeschrijving"
                    :value="item.idCode"
                    @change="forceUpdate()"
                    @click="updateFields()">
                  </v-checkbox>
                </v-row>
                <v-row class="pa-0" v-if="item.heeftExtraOmschrijving">
                  <v-checkbox
                    hide-details
                    v-model="item.checked"
                    :value="item.idCode"
                    @change="forceUpdate()"
                    @click="updateFields()">
                  </v-checkbox>
                  <div class="pt-2" style="width: 250px; height: 54px;">
                    <v-text-field class="ma-0" :disabled="!item.checked" :label="item.langeBeschrijving" v-model="item.extraOmschrijving" @input="updateFields()"></v-text-field>
                  </div>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-radio-group v-if="radio && list" v-model="selectedItem">
          <v-row class="pa-0 ma-5">
            <v-col class="py-2" v-for="col in cols" v-bind:key="col">
              <div
                v-for="item in itemLists[col-1]"
                v-bind:key="item.idCode">
                <v-row class="pa-1" v-if="!item.heeftExtraOmschrijving">
                  <v-radio
                    class="py-4"
                    :label="item.langeBeschrijving"
                    :value="item.idCode"
                    @change="forceUpdate()"
                    @click="updateFields()">
                  </v-radio>
                </v-row>
                <v-row class="pa-1" v-if="item.heeftExtraOmschrijving">
                  <v-radio
                    class="py-4"
                    hide-details
                    :value="item.idCode"
                    @change="forceUpdate()"
                    @click="updateFields()">
                  </v-radio>
                  <div class="pt-2" style="width: 250px">
                    <v-text-field :disabled="selectedItem !== item.idCode" :label="item.langeBeschrijving" v-model="item.extraOmschrijving" @input="updateFields()"></v-text-field>
                  </div>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>
    </v-row>
        <v-progress-linear
        :active="!isLoaded"
        :indeterminate="!isLoaded"
        color="secondary"
        ></v-progress-linear>
  </v-container>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'

export default {
  name: 'ControlMultiCheck',
  mixins: [
    commonMixin
  ],
  props: {
    list: {
      default: null,
      Type: Array
    },
    cols: {
      default: 1,
      Type: Number
    },
    radio: Boolean,
    selectieControlTypes: {
      default: null,
      Type: Array
    },
    value: {
      default: null,
      Type: [Array, Object]
    },
    title: String
  },
  data () {
    return {
      itemLists: [],
      selectedItem: null,
      selectedItems: [],
      fieldsAreLoaded: false,
      selectionsAreLoaded: false
    }
  },
  mounted () {
    this.itemLists = this.GetItemLists()
    this.SetSelectedFields()
  },
  computed: {
    isLoaded () {
      return this.fieldsAreLoaded && this.selectionsAreLoaded
    }
  },
  watch: {
    list () {
      this.itemLists = this.GetItemLists()
      this.SetSelectedFields()
    },
    value () {
      this.itemLists = this.GetItemLists()
      this.SetSelectedFields()
    },
    itemLists: {
      handler () {
        if (!this.radio) {
          this.GetSelectedCheckboxes()
        } else {
          this.GetSelectedRadiobutton()
        }
        this.fieldsAreLoaded = true
      },
      deep: true
    },
    selectedItem () {
      this.GetSelectedRadiobutton()
    }
  },
  methods: {
    GetItemLists () {
      this.itemLists = []
      const listClone = this.CommonMixin_Clone(this.list)
      if (listClone) {
        var rest = listClone.length % this.cols
        var counter = 0
        for (var c = 0; c < this.cols; c++) {
          var array = []
          const itemsPerCol = Math.floor(listClone.length / this.cols)
          for (var i = 0; i < itemsPerCol; i++) {
            array.push(listClone[counter])
            counter++
          }
          if (rest > 0) {
            array.push(listClone[counter])
            counter++
            rest--
          }
          this.itemLists.push(array)
        }
        return this.itemLists
      }
      return []
    },
    GetSelectedCheckboxes () {
      this.selectedItems = []
      this.CommonMixin_Clone(this.itemLists).forEach(arr => {
        arr.forEach(item => {
          if (item.checked) {
            this.selectedItems.push({
              extraOmschrijving: item.extraOmschrijving && item.extraOmschrijving.length ? item.extraOmschrijving : null,
              idCode: item.idCode
            })
          }
        })
      })
    },
    GetSelectedRadiobutton () {
      this.selectedItems = []
      this.CommonMixin_Clone(this.itemLists).forEach(arr => {
        arr.forEach(item => {
          if (item.idCode === this.selectedItem) {
            this.selectedItems.push({
              extraOmschrijving: item.extraOmschrijving && item.extraOmschrijving.length ? item.extraOmschrijving : null,
              idCode: item.idCode
            })
          }
        })
      })
    },
    SetSelectedFields () {
      if (this.value && this.itemLists) {
        this.CommonMixin_Clone(this.value).forEach(selectie => {
          this.itemLists.forEach(arr => {
            arr.forEach(item => {
              if (item.idCode === selectie.idCode) {
                item.checked = item.idCode
                this.selectedItem = item.idCode
                item.extraOmschrijving = selectie.extraOmschrijving && selectie.extraOmschrijving.length ? selectie.extraOmschrijving : null
              }
            })
          })
        })
      }
      this.selectionsAreLoaded = true
    },
    updateFields () {
      if (!this.radio) {
        this.GetSelectedCheckboxes()
      } else {
        this.GetSelectedRadiobutton()
      }
      this.forceUpdate()
      this.Emit()
    },
    forceUpdate () {
      this.$forceUpdate()
    },
    Emit () {
      this.$emit('input', this.selectedItems)
    }
  }
}
</script>
