<template>
  <v-form class="my-2"
      v-model="detailMixin.valid"
      ref="formBijzonderElement">
      <div
      v-if="detailMixin.element && detailMixin.element.multicheck && detailMixin.element.fields"
      :class="DetailMixin_Dirty ? 'dirtyForm' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editForm' : ''"
      >
      <h2 class="primary--text">Bijzondere elementen</h2>

      <v-row class="pb-0 mb-5">
        <v-col cols="12" md="5" lg="3">
          <h3 class="secondary--text my-3">Bouwjaar</h3>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number label="Van" :max="9999" v-model="detailMixin.element.fields.bouwjaarVan" :withDotSeparator="false"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number label="Tot" :max="9999" v-model="detailMixin.element.fields.bouwjaarTot" :withDotSeparator="false"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="3">
          <h3 class="secondary--text my-3">Renovatiejaar</h3>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number label="Van" :max="9999" v-model="detailMixin.element.fields.renovatiejaarVan" :withDotSeparator="false"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number label="Tot" :max="9999" v-model="detailMixin.element.fields.renovatiejaarTot" :withDotSeparator="false"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <div v-if="selectieControlTypes && selectieControlTypes.length">
      <control-multi-check radio :cols="3" :list="staatOnderhoudGebouwTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[0].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[0].langeBeschrijving"/>
      <v-divider/>
      <control-multi-check :cols="3" :list="staatOnderhoudTuinTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[1].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[1].langeBeschrijving"/>
      </div>

      <v-divider class="mb-5"></v-divider>

      <h3 class="secondary--text my-3">Werken</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Recent uitgevoerde werken" auto-grow outlined v-model="detailMixin.element.fields.recentUitgevoerdeWerken"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Nodige renovatiewerken" auto-grow outlined v-model="detailMixin.element.fields.nodigeRenovatiewerken"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Bijzondere kosten, geplande werken" auto-grow outlined v-model="detailMixin.element.fields.bijzondereKostenGeplandeWerken"/>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <div v-if="selectieControlTypes && selectieControlTypes.length">
      <control-multi-check radio :cols="3" :list="erfdienstbaarheidTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[2].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[2].langeBeschrijving"/>
      <v-divider/>
      <control-multi-check :cols="3" :list="gebruikPandTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[3].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[3].langeBeschrijving"/>
      </div>

      <v-divider class="mb-5"></v-divider>

      <h3 class="secondary--text my-3">Huurinkomsten</h3>
      <v-row class="pb-0 mb-5">
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Aantal entiteiten voor werken<br/>(totaal: {{ detailMixin.element.fields.huurinkomstenPriveAantalEntiteitenVoorWerken + detailMixin.element.fields.huurinkomstenBeroepsAantalEntiteitenVoorWerken }})</h4>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number label="Privé" v-model="detailMixin.element.fields.huurinkomstenPriveAantalEntiteitenVoorWerken"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number label="Beroeps" v-model="detailMixin.element.fields.huurinkomstenBeroepsAantalEntiteitenVoorWerken"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Aantal entiteiten na werken<br/>(totaal: {{ detailMixin.element.fields.huurinkomstenPriveAantalEntiteitenNaWerken + detailMixin.element.fields.huurinkomstenBeroepsAantalEntiteitenNaWerken }})</h4>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number label="Privé" v-model="detailMixin.element.fields.huurinkomstenPriveAantalEntiteitenNaWerken"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number label="Beroeps" v-model="detailMixin.element.fields.huurinkomstenBeroepsAantalEntiteitenNaWerken"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Huur voor werken<br/>(totaal: € {{ this.FormatMixin_FormatDecimal(detailMixin.element.fields.huurinkomstenPriveHuurVoorWerken + detailMixin.element.fields.huurinkomstenBeroepsHuurVoorWerken) }})</h4>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Privé" prefix="€" v-model="detailMixin.element.fields.huurinkomstenPriveHuurVoorWerken"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Beroeps" prefix="€" v-model="detailMixin.element.fields.huurinkomstenBeroepsHuurVoorWerken"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Huur na werken<br/>(totaal: € {{ this.FormatMixin_FormatDecimal(detailMixin.element.fields.huurinkomstenPriveHuurNaWerken + detailMixin.element.fields.huurinkomstenBeroepsHuurNaWerken) }})</h4>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Privé" prefix="€" v-model="detailMixin.element.fields.huurinkomstenPriveHuurNaWerken"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Beroeps" prefix="€" v-model="detailMixin.element.fields.huurinkomstenBeroepsHuurNaWerken"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Kostprijs werken</h3>
      <v-row class="pb-0 mb-5">
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Volgens klant<br/>(totaal: € {{ this.FormatMixin_FormatDecimal(detailMixin.element.fields.kostprijsWerkenVolgensKlantViaAannemer + detailMixin.element.fields.kostprijsWerkenVolgensKlantEigenWerk) }})</h4>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Via aannemer" prefix="€" v-model="detailMixin.element.fields.kostprijsWerkenVolgensKlantViaAannemer"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Eigen werk" prefix="€" v-model="detailMixin.element.fields.kostprijsWerkenVolgensKlantEigenWerk"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Volgens schatter<br/>(totaal: € {{ this.FormatMixin_FormatDecimal(detailMixin.element.fields.kostprijsWerkenVolgensSchatterViaAannemer + detailMixin.element.fields.kostprijsWerkenVolgensSchatterEigenWerk) }})</h4>
          <v-row class="pa-0">
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Via aannemer" prefix="€" v-model="detailMixin.element.fields.kostprijsWerkenVolgensSchatterViaAannemer"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Eigen werk" prefix="€" v-model="detailMixin.element.fields.kostprijsWerkenVolgensSchatterEigenWerk"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <div v-if="selectieControlTypes && selectieControlTypes.length">
      <control-multi-check radio :cols="3" :list="verkoopbaarheidTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[4].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[4].langeBeschrijving"/>
      <v-divider/>
      <control-multi-check :cols="3" :list="apectBetrokkenOmgevingTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[5].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[5].langeBeschrijving"/>
      </div>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Overige</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Ligging ten opzichte van dienstverlenende centra" auto-grow outlined v-model="detailMixin.element.fields.liggingTovDienstverlening"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Verkeersmogelijkheden - bereikbaarheid" auto-grow outlined v-model="detailMixin.element.fields.bereikbaarheid"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Mogelijkheden ten opzichte van het openbaar vervoer" auto-grow outlined v-model="detailMixin.element.fields.openbaarVervoer"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Weguitrusting" auto-grow outlined v-model="detailMixin.element.fields.weguitrusting"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Milieuvriendelijke of -storende elementen" auto-grow outlined v-model="detailMixin.element.fields.milieuvriendelijkeOfStorendeElementen"/>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Bijlage</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6">
          <control-file-input-drag-and-drop v-model="detailMixin.element.files" @download="Download($event)" :accept="['.png', '.jpg', '.jpeg', '.gif']"/>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import ControlFileInputDragAndDrop from '../ControlFileInputDragAndDrop.vue'
import ControlMultiCheck from '../ControlMultiCheck.vue'
import codeService from '../../services/codeService'
import schattingService from '../../services/schattingService'
import errorMixin from '@/mixins/errorMixin'
import commonMixin from '@/mixins/commonMixin'
import ControlNumber from '../ControlNumber.vue'
import detailMixin from '../../mixins/detailMixin'
import blobMixin from '../../mixins/blobMixin'

export default {
  components: {
    ControlMultiCheck,
    ControlFileInputDragAndDrop,
    ControlNumber
  },
  mixins: [
    errorMixin,
    commonMixin,
    detailMixin,
    blobMixin
  ],
  mounted () {
    codeService.Init(this)
    schattingService.Init(this)
  },
  props: {
    idSchatting: {
      default: null,
      type: String
    },
    visible: Boolean
  },
  data () {
    return {
      referentieType: '69c19641-94ad-4cc0-a670-328fe8d38e9b',
      staatOnderhoudGebouwTypes: null,
      staatOnderhoudTuinTypes: null,
      apectBetrokkenOmgevingTypes: null,
      verkoopbaarheidTypes: null,
      erfdienstbaarheidTypes: null,
      gebruikPandTypes: null,
      selectionList: null,
      selectieControlTypes: null,
      extraBeschrijving: null
    }
  },
  watch: {
    async visible () {
      if (this.visible) {
        await this.SetVariables()
        await this.loadEverything()
        await this.SetSelectionList()
      } else if (this.DetailMixin_Dirty) {
        this.DetailMixin_Save()
      }
    }
  },
  methods: {
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    async loadEverything () {
      this.CommonMixin_Load(this.$options.name, true)
      await this.SetAspectBetrokkenOmgevingTypes()
      await this.SetVerkoopbaarheidTypes()
      await this.SetErfdienstbaarheidTypes()
      await this.SetGebruikPandTypes()
      await this.SetStaatOnderhoudTuinTypes()
      await this.SetStaatOnderhoudGebouwTypes()
      await this.SetSelectieControlTypes()
      this.CommonMixin_Load(this.$options.name, false)
    },
    async SetAspectBetrokkenOmgevingTypes () {
      const response = await codeService.GetAspectBetrokkenOmgevingTypes()
      if (response && response.data) {
        const data = response.data
        this.apectBetrokkenOmgevingTypes = data
      }
    },
    async SetStaatOnderhoudTuinTypes () {
      const response = await codeService.GetStaatOnderhoudTuinTypes()
      if (response && response.data) {
        const data = response.data
        this.staatOnderhoudTuinTypes = data
      }
    },
    async SetStaatOnderhoudGebouwTypes () {
      const response = await codeService.GetStaatOnderhoudGebouwTypes()
      if (response && response.data) {
        const data = response.data
        this.staatOnderhoudGebouwTypes = data
      }
    },
    async SetVerkoopbaarheidTypes () {
      const response = await codeService.GetVerkoopbaarheidTypes(false)
      if (response && response.data) {
        const data = response.data
        this.verkoopbaarheidTypes = data
      }
    },
    async SetErfdienstbaarheidTypes () {
      const response = await codeService.GetErfdienstbaarheidTypes()
      if (response && response.data) {
        const data = response.data
        this.erfdienstbaarheidTypes = data
      }
    },
    async SetGebruikPandTypes () {
      const response = await codeService.GetGebruikPandTypes()
      if (response && response.data) {
        const data = response.data
        this.gebruikPandTypes = data
      }
    },
    async SetSelectionList () {
      const id = this.DetailMixin_GetId()

      if (id) {
        const responseBijzondereElementenMulticheck = await codeService.GetCodeSelectie(id, this.referentieType)
        var element = {
          multicheck: {},
          fields: {},
          files: []
        }

        element.files = await this.BlobMixin_GetFiles(this.idSchatting, this.referentieType)

        if (responseBijzondereElementenMulticheck && (responseBijzondereElementenMulticheck.data || responseBijzondereElementenMulticheck.data !== '')) {
          element.multicheck = this.CommonMixin_Clone(responseBijzondereElementenMulticheck.data)
          this.$forceUpdate()
        }

        const responseBijzondereElementen = await schattingService.GetBijzonderElementByIdSchatting(id)
        if (responseBijzondereElementen && (responseBijzondereElementen.data || responseBijzondereElementen.data !== '')) {
          element.fields = this.CommonMixin_Clone(responseBijzondereElementen.data)
          this.$forceUpdate()
        }

        this.detailMixin.element = this.CommonMixin_Clone(element)

        this.DetailMixin_InitDirtyWatch()
      }
    },
    async SetSelectieControlTypes () {
      const response = await codeService.GetSelectieControlTypes()
      if (response && response.data) {
        const data = response.data
        this.selectieControlTypes = data.filter(x => x.waarde >= 600 && x.waarde <= 699)
      }
    },
    SetVariables () {
      this.detailMixin.key = 'id'
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.detailMixin.functions.validate = this.$refs.formBijzonderElement.validate
      this.detailMixin.functions.save = this.save
    },
    async save (element) {
      var multicheck = await codeService.SaveMulticheck({ IdReferentie: this.idSchatting, IdCodeReferentieType: this.referentieType, MultiSelects: element.multicheck })
      var fields = await schattingService.UpdateSchattingBijzonderElement(element.fields)
      await this.BlobMixin_SaveFiles(element.files, this.idSchatting, this.referentieType)
      var multicheckData = null
      var fieldsData = null
      if (multicheck && multicheck.data) {
        multicheckData = multicheck.data
      }
      if (fields && fields.data) {
        fieldsData = fields.data
      }
      return {
        data: {
          multicheck: multicheckData,
          fields: fieldsData
        }
      }
    },
    Validate () {
      this.$refs.formBijzonderElement.validate()
      return this.detailMixin.valid
    }
  }
}
</script>
