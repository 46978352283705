<template>
  <div>
    <v-list-item
      v-if="item.to"
      :dense="item.dense"
      :href="href"
      :rel="href && href !== '#' ? 'noopener' : undefined"
      :target="href && href !== '#' ? '_blank' : undefined"
      :to="item.to"
      :active-class="`primary ${$vuetify.theme.isDark ? 'white' : 'black'}--text`"
    >
      <v-list-item-icon
        v-if="text && !item.icon"
        class="v-list-item__icon--text"
        v-text="computedText"
      />
      <v-list-item-icon v-else-if="item.icon">
        <v-icon :style="`color: #E4ECF5; ${item.dense ? 'padding-left: 20px' : ''}`" v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content style="color: #E4ECF5" v-if="item.title || item.subtitle">
        <v-list-item-title v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-else
      :dense="item.dense"
      :rel="href && href !== '#' ? 'noopener' : undefined"
      :target="href && href !== '#' ? '_blank' : undefined"
      :active-class="`primary ${$vuetify.theme.isDark ? 'white' : 'black'}--text`"
      @click="Click()"
    >
      <v-list-item-icon
        v-if="text && !item.icon"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-icon v-else-if="item.icon">
        <v-icon style="color: #E4ECF5; ${item.dense ? 'padding-left: 20px' : ''}`" v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content style="color: #E4ECF5" v-if="item.title || item.subtitle">
        <v-list-item-title v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'
import Themeable from 'vuetify/lib/mixins/themeable'

export default {
  name: 'MenuItem',
  mixins: [
    commonMixin,
    Themeable
  ],
  props: {
    item: {
      type: Object,
      default: () => ({
        dense: false,
        href: undefined,
        icon: undefined,
        redirect: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined
      })
    },
    text: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedText () {
      // if (!this.item || !this.item.title) return ''

      // let text = ''

      // this.item.title.split(' ').forEach(val => {
      //   text += val.substring(0, 1)
      // })

      // return text
      return ''
    },
    href () {
      return this.item.href || (!this.item.to ? '#' : undefined)
    }
  },
  methods: {
    Click () {
      this.$emit('click')
      if (this.$route && this.item.redirect && !(this.CommonMixin_HardCompare(`/${this.item.redirect.path}`, this.$route.path) && this.CommonMixin_HardCompare(this.item.redirect.query, this.$route.query))) {
        this.$router.replace(this.item.redirect).catch(() => {})
      }
    }
  }
}
</script>
