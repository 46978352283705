<template>
  <v-form class="my-2"
    v-model="detailMixin.valid"
    ref="formVergelijkingspunten">
    <div
      v-if="detailMixin.element.vergelijkingspunten"
      :class="DetailMixin_Dirty ? 'dirtyForm' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editForm' : ''"
      >
      <h2 class="primary--text">Vergelijkingspunten</h2>
      <h3 class="primary--text">Gekoppelde vergelijkingspunten</h3>
      <v-data-table
        v-model="gekoppeldeVergelijkingspuntenSelectie"
        item-key="idVergelijkingspunt"
        dense
        show-select
        :headers="headers"
        :items="gekoppeldeVergelijkingspunten"
        :options.sync="gekoppeldeOptions"
        :server-items-length="gekoppeldeVergelijkingspunten.length"
        :loading="gekoppeldeLoading"
        :footer-props="{
          itemsPerPageText: 'Vergelijkingspunten per pagina',
          'items-per-page-options': [10, 25, 50, 100]
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.idPostcode`]="{ item }">
          {{ GetGemeenteByIdPostcode(item.idPostcode) }}
        </template>
        <template v-slot:[`item.perceelBreedte`]="{ item }">
          <template v-if="item.perceelBreedte != null">
          {{ FormatMixin_FormatDecimal(item.perceelBreedte, 0) }} m
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.verkoopType`]="{ item }">
          <template v-if="item.verkoopType != null">
          {{ item.verkoopType }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.perceelOppervlakte`]="{ item }">
          <template v-if="item.perceelOppervlakte != null">
          {{ FormatMixin_FormatDecimal(item.perceelOppervlakte, 0) }} m²
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.bewoonbareOppervlakte`]="{ item }">
          <template v-if="item.bewoonbareOppervlakte != null">
          {{ FormatMixin_FormatDecimal(item.bewoonbareOppervlakte, 0) }} m²
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.bouwjaar`]="{ item }">
          <template v-if="item.bouwjaar != null">
          {{ item.bouwjaar }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.ki`]="{ item }">
          <template v-if="item.ki != null">
          € {{ FormatMixin_FormatDecimal(item.ki, 2) }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.datumVerkoop`]="{ item }">
          <template v-if="item.datumVerkoop != null">
          {{ FormatMixin_FormatDateTime(item.datumVerkoop, formatMixin.date.database, formatMixin.date.monthnameYear, 'nl-BE') }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.verkoopprijs`]="{ item }">
          <template v-if="item.verkoopprijs != null">
          € {{ FormatMixin_FormatDecimal(item.verkoopprijs, 0) }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.adres`]="{ item }">
          {{ item.straat }} {{ item.huisnummer }}
        </template>
        <template v-slot:[`item.renovatiejaar`]="{ item }">
          <template v-if="item.renovatiejaar != null">
          {{ item.renovatiejaar }}
          </template>
          <template v-else>
            /
          </template>
        </template>
      </v-data-table>
      <div class="text-center">
        <v-btn
          class="ma-2"
          outlined
          @click="OntkoppelVergelijkingspunten()"
        >
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
        <v-btn
          class="ma-2"
          outlined
          @click="KoppelVergelijkingspunten()"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
      <h3 class="primary--text">Beschikbare vergelijkingspunten</h3>
      <v-data-table
        v-model="beschikbareVergelijkingspuntenSelectie"
        item-key="idVergelijkingspunt"
        dense
        show-select
        :headers="headers"
        :items="beschikbareVergelijkingspunten"
        :options.sync="beschikbareOptions"
        :server-items-length="totalItems"
        :loading="beschikbareLoading"
        :footer-props="{
          itemsPerPageText: 'Vergelijkingspunten per pagina',
          'items-per-page-options': [10, 25, 50, 100]
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.idPostcode`]="{ item }">
          {{ GetGemeenteByIdPostcode(item.idPostcode) }}
        </template>
        <template v-slot:[`item.perceelBreedte`]="{ item }">
          <template v-if="item.perceelBreedte != null">
          {{ FormatMixin_FormatDecimal(item.perceelBreedte, 0) }} m
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.verkoopType`]="{ item }">
          <template v-if="item.verkoopType != null">
          {{ item.verkoopType }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.perceelOppervlakte`]="{ item }">
          <template v-if="item.perceelOppervlakte != null">
          {{ FormatMixin_FormatDecimal(item.perceelOppervlakte, 0) }} m²
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.bewoonbareOppervlakte`]="{ item }">
          <template v-if="item.bewoonbareOppervlakte != null">
          {{ FormatMixin_FormatDecimal(item.bewoonbareOppervlakte, 0) }} m²
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.bouwjaar`]="{ item }">
          <template v-if="item.bouwjaar != null">
          {{ item.bouwjaar }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.ki`]="{ item }">
          <template v-if="item.ki != null">
          € {{ FormatMixin_FormatDecimal(item.ki, 2) }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.datumVerkoop`]="{ item }">
          <template v-if="item.datumVerkoop != null">
          {{ FormatMixin_FormatDateTime(item.datumVerkoop, formatMixin.date.database, formatMixin.date.monthnameYear, 'nl-BE') }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.verkoopprijs`]="{ item }">
          <template v-if="item.verkoopprijs != null">
          € {{ FormatMixin_FormatDecimal(item.verkoopprijs, 0) }}
          </template>
          <template v-else>
            /
          </template>
        </template>
        <template v-slot:[`item.adres`]="{ item }">
          {{ item.straat }} {{ item.huisnummer }}
        </template>
        <template v-slot:[`item.renovatiejaar`]="{ item }">
          <template v-if="item.renovatiejaar != null">
          {{ item.renovatiejaar }}
          </template>
          <template v-else>
            /
          </template>
        </template>
      </v-data-table>
    </div>

    <div
      class="text-end pa-0 pt-5"
    >
      <v-btn
        color="primary darken-1"
        @click="OpenVergelijkingspuntDialog()">
        {{ hasVergelijkingspunt ? 'Wijzig vergelijkingspunt van deze schatting' : 'Maak vergelijkingspunt van deze schatting' }}
      </v-btn>
    </div>

    <v-dialog
      v-model="openVergelijkingspuntDialog"
      max-width="750">
      <v-card
        v-if="openVergelijkingspuntDialog"
      >
        <v-form
          v-model="vergelijkingspuntValid"
          ref="formDialog">
          <v-card-title class="text-h5 primary--text">
            {{ editdialogTitle }}
          </v-card-title>
          <v-card-text v-if="vergelijkingspunt">
            <v-text-field
              v-model="vergelijkingspunt.pand"
              label="*Pand"
              readonly
            />
            <v-row class="pb-0">
              <v-col cols="8" sm="10">
                <v-text-field
                  v-model="vergelijkingspunt.straat"
                  label="Straat"
                  readonly
                />
              </v-col>
              <v-col cols="4" sm="2">
                <v-text-field
                  v-model="vergelijkingspunt.huisnummer"
                  label="Huisnr."
                  readonly
                />
              </v-col>
              <v-col cols="12">
                <control-postcode
                  v-model="vergelijkingspunt.idPostcode"
                  label="Gemeente"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col class="py-0" cols="12" sm="4">
                <v-text-field
                  v-model="vergelijkingspunt.kadastraleAfdeling"
                  label="Kadastrale afdeling"
                  readonly
                />
              </v-col>
              <v-col class="py-0" cols="12" sm="4">
                <v-text-field
                  v-model="vergelijkingspunt.kadastraleSectie"
                  label="Kadastrale sectie"
                  readonly
                />
              </v-col>
              <v-col class="py-0" cols="12" sm="4">
                <v-text-field
                  v-model="vergelijkingspunt.kadastraleNummers"
                  label="Kadastrale nrs."
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col class="py-0" cols="12" sm="4">
                <control-number
                  v-model="vergelijkingspunt.perceelBreedte"
                  label="Perceel breedte"
                  suffix="m"
                  readonly
                />
              </v-col>
              <v-col class="py-0" cols="12" sm="4">
                <control-number
                  v-model="vergelijkingspunt.perceelOppervlakte"
                  label="Perceel oppervlakte"
                  suffix="m²"
                  readonly
                />
              </v-col>
              <v-col class="py-0" cols="12" sm="4">
                <control-number
                  v-model="vergelijkingspunt.bewoonbareOppervlakte"
                  label="Bewoonbare oppervlakte"
                  suffix="m²"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col class="py-0" cols="6" sm="4">
                <control-number
                  v-model="vergelijkingspunt.ki"
                  label="KI"
                  prefix="€"
                  readonly
                />
              </v-col>
              <v-col class="py-0" cols="6" sm="4">
                <control-number
                  v-model="vergelijkingspunt.epcScore"
                  label="EPC-score (kWh/m²/jaar)"
                  readonly
                  :decimals="0"
                />
              </v-col>
            </v-row>
            <v-row class="py-0">
            </v-row>
            <v-row class="py-0">
              <v-col class="py-0" cols="6" sm="3">
                <control-number
                  v-model="vergelijkingspunt.bouwjaar"
                  label="Bouwjaar"
                  readonly
                  :withDotSeparator="false"
                />
              </v-col>
              <v-col class="py-0" cols="6" sm="3">
                <control-number
                  v-model="vergelijkingspunt.renovatiejaar"
                  label="Renovatiejaar"
                  readonly
                  :withDotSeparator="false"
                />
              </v-col>
              <v-col class="py-0" cols="6" sm="3">
                <control-select
                  v-model="vergelijkingspunt.maandVerkoop"
                  :items="months"
                  itemText="text"
                  itemValue="value"
                  label="Maand verkoop"
                  required
                  requiredText="Maand verkoop is verplicht"
                />
              </v-col>
              <v-col class="py-0" cols="6" sm="3">
                  <control-number
                    v-model="vergelijkingspunt.jaarVerkoop"
                    label="Jaar verkoop"
                    required
                    requiredText="Jaar verkoop is verplicht"
                    :withDotSeparator="false"
                  />
              </v-col>
              <v-col class="py-0" cols="12" sm="6">
                <control-number
                  v-model="vergelijkingspunt.verkoopprijs"
                  label="Verkoopprijs"
                  prefix="€"
                  required
                  requiredText="Verkoopprijs is verplicht"
                />
              </v-col>
              <v-col class="py-0" cols="12" sm="6">
                <control-select
                  v-model="vergelijkingspunt.idCodeVerkoopType"
                  :items="verkoopTypeList"
                  label="Type verkoop"
                  :itemValue="'idCode'"
                  :itemText="'langeBeschrijving'"
                  required
                  requiredText="Type verkoop is verplicht"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              text
              @click="openVergelijkingspuntDialog = false">
              Terug
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              @click="UpdateVergelijkingspunt()"
            >
              Opslaan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import ControlSelect from '../ControlSelect'
import ControlNumber from '../ControlNumber'
import schattingService from '../../services/schattingService'
import commonMixin from '../../mixins/commonMixin'
import errorMixin from '../../mixins/errorMixin'
import detailMixin from '../../mixins/detailMixin'
import postService from '../../services/postService'
import vergelijkingspuntService from '../../services/vergelijkingspuntService'
import ControlPostcode from '../ControlPostcode.vue'
import codeService from '../../services/codeService'
import guidService from '../../services/guidService'
import moment from 'moment'

export default {
  components: {
    ControlPostcode,
    ControlSelect,
    ControlNumber
  },
  mixins: [
    commonMixin,
    errorMixin,
    detailMixin
  ],
  props: {
    idSchatting: {
      default: null,
      type: String
    },
    visible: Boolean
  },
  data () {
    return {
      beschikbareLoading: true,
      beschikbareOptions: {},
      beschikbareVergelijkingspunten: [],
      beschikbareVergelijkingspuntenSelectie: [],
      editdialogTitle: 'Vergelijkingspunt aanmaken',
      gekoppeldeLoading: false,
      gekoppeldeOptions: {},
      gekoppeldeVergelijkingspuntenIds: [],
      gekoppeldeVergelijkingspuntenSelectie: [],
      headers: [
        {
          text: 'Pand',
          align: 'start',
          value: 'pand'
        },
        {
          text: 'Adres',
          align: 'start',
          value: 'adres'
        },
        {
          text: 'Gemeente',
          align: 'start',
          value: 'idPostcode'
        },
        {
          text: 'Bouwjaar',
          align: 'end',
          value: 'bouwjaar'
        },
        {
          text: 'Renovatiejaar',
          align: 'end',
          value: 'renovatiejaar'
        },
        {
          text: 'Perceel breedte',
          align: 'end',
          value: 'perceelBreedte'
        },
        {
          text: 'Perceel opp.',
          align: 'end',
          value: 'perceelOppervlakte'
        },
        {
          text: 'Bewoonbare opp.',
          align: 'end',
          value: 'bewoonbareOppervlakte'
        },
        {
          text: 'KI',
          align: 'end',
          value: 'ki'
        },
        {
          text: 'Datum verkoop',
          align: 'end',
          value: 'datumVerkoop'
        },
        {
          text: 'Verkoopprijs',
          align: 'end',
          value: 'verkoopprijs'
        },
        {
          text: 'Verkooptype',
          align: 'end',
          value: 'verkoopType'
        }
      ],
      months: [
        { value: '1', text: 'januari' },
        { value: '2', text: 'februari' },
        { value: '3', text: 'maart' },
        { value: '4', text: 'april' },
        { value: '5', text: 'mei' },
        { value: '6', text: 'juni' },
        { value: '7', text: 'juli' },
        { value: '8', text: 'augustus' },
        { value: '9', text: 'september' },
        { value: '10', text: 'oktober' },
        { value: '11', text: 'november' },
        { value: '12', text: 'december' }
      ],
      openVergelijkingspuntDialog: false,
      pocoStatus: {
        unchanged: 0,
        created: 1,
        changed: 2,
        deleted: 3
      },
      postcodes: null,
      referentieType: '5c03455b-88b2-45fe-8309-dd8c81aaec77',
      vergelijkingspunt: {},
      verkoopTypeList: [],
      totalItems: 0,
      vergelijkingspuntValid: true,
      hasVergelijkingspunt: false
    }
  },
  mounted () {
    schattingService.Init(this)
    vergelijkingspuntService.Init(this)
    postService.Init(this)
    codeService.Init(this)
    guidService.Init(this)
  },
  computed: {
    gekoppeldeVergelijkingspunten () {
      if (this.detailMixin.element.vergelijkingspunten && this.detailMixin.element.vergelijkingspunten.vergelijkingspunten) {
        return this.detailMixin.element.vergelijkingspunten.vergelijkingspunten.filter(x => x.pocoStatus !== this.pocoStatus.deleted)
      }

      return []
    }
  },
  watch: {
    beschikbareOptions: {
      handler () {
        this.GetDataFromApi()
      },
      deep: true
    },
    async visible () {
      if (this.visible) {
        await this.SetVariables()
        await this.GetPosten()
        await this.GetVerkoopTypeList()
        await this.SetFields()
      } else if (this.DetailMixin_Dirty) {
        this.DetailMixin_Save()
      }
    }
  },
  methods: {
    KoppelVergelijkingspunten () {
      const movedCount = this.beschikbareVergelijkingspuntenSelectie.length
      const vergelijkingspunten = this.detailMixin.element.vergelijkingspunten.vergelijkingspunten
      this.beschikbareVergelijkingspuntenSelectie.forEach(vergelijkingspunt => {
        if (!this.gekoppeldeVergelijkingspunten.map(x => x.idVergelijkingspunt).includes(vergelijkingspunt.idVergelijkingspunt)) {
          vergelijkingspunt.pocoStatus = this.pocoStatus.created
          vergelijkingspunten.push(vergelijkingspunt)
        }
      })
      for (var i = this.beschikbareVergelijkingspuntenSelectie.length - 1; i >= 0; i--) {
        this.beschikbareVergelijkingspuntenSelectie.splice(i, 1)
      }
      this.gekoppeldeVergelijkingspuntenIds = this.gekoppeldeVergelijkingspunten.map(x => x.idVergelijkingspunt)
      if (movedCount > 0) {
        this.GetDataFromApi()
      }
    },
    OntkoppelVergelijkingspunten () {
      const movedCount = this.gekoppeldeVergelijkingspuntenSelectie.length
      const vergelijkingspunten = this.detailMixin.element.vergelijkingspunten.vergelijkingspunten
      for (var i = this.gekoppeldeVergelijkingspuntenSelectie.length - 1; i >= 0; i--) {
        const selectie = this.gekoppeldeVergelijkingspuntenSelectie[i]
        if (selectie.pocoStatus === this.pocoStatus.created) {
          vergelijkingspunten.splice(vergelijkingspunten.indexOf(selectie), 1)
        } else {
          const index = vergelijkingspunten.findIndex(x => x.idVergelijkingspunt === selectie.idVergelijkingspunt && x.pocoStatus !== this.pocoStatus.deleted)
          const gekoppeldeVergelijkingspunt = vergelijkingspunten[index]
          gekoppeldeVergelijkingspunt.pocoStatus = this.pocoStatus.deleted
        }
        this.gekoppeldeVergelijkingspuntenSelectie.splice(this.gekoppeldeVergelijkingspuntenSelectie.indexOf(selectie), 1)
      }
      this.gekoppeldeVergelijkingspuntenIds = this.gekoppeldeVergelijkingspunten.map(x => x.idVergelijkingspunt)
      if (movedCount > 0) {
        this.GetDataFromApi()
      }
    },
    async OpenVergelijkingspuntDialog () {
      this.openVergelijkingspuntDialog = true
      this.$refs.formVergelijkingspunten.resetValidation()
      // Get vergelijkingspunt if existing
      const idVergelijkingspunt = this.detailMixin.element.schatting.idVergelijkingspunt
      if (idVergelijkingspunt != null) {
        const response = await vergelijkingspuntService.GetById(idVergelijkingspunt)
        if (response && response.data) {
          this.vergelijkingspunt = response.data
          this.vergelijkingspunt.jaarVerkoop = this.vergelijkingspunt.datumVerkoop ? new Date(this.vergelijkingspunt.datumVerkoop).getFullYear() : null
          this.vergelijkingspunt.maandVerkoop = this.vergelijkingspunt.datumVerkoop ? (new Date(this.vergelijkingspunt.datumVerkoop).getMonth() + 1).toString() : null
        }
      } else {
        const response = await guidService.New()
        let newGuid = null
        if (response && response.data) {
          newGuid = response.data
        }
        this.vergelijkingspunt = {
          idSchatting: this.detailMixin.element.algemeneGegevens.idSchatting,
          idVergelijkingspunt: newGuid,
          pand: this.detailMixin.element.algemeneGegevens.aardGoed,
          straat: this.detailMixin.element.algemeneGegevens.straat,
          huisnummer: this.detailMixin.element.algemeneGegevens.huisnummer,
          idPostcode: this.detailMixin.element.algemeneGegevens.idPostcode,
          bouwjaar: this.detailMixin.element.bijzondereElementen.bouwjaarVan,
          perceelOppervlakte: this.detailMixin.element.afmetingen.perceelOppervlakte,
          bewoonbareOppervlakte: this.detailMixin.element.afmetingen.oppervlakteGelijkvloers +
          this.detailMixin.element.afmetingen.oppervlakteVerdieping1 +
          this.detailMixin.element.afmetingen.oppervlakteVerdieping2 +
          this.detailMixin.element.afmetingen.oppervlakteVerdieping3 +
          this.detailMixin.element.afmetingen.oppervlakteVerdiepingDak,
          perceelBreedte: this.detailMixin.element.afmetingen.perceelBreedte,
          ki: this.detailMixin.element.algemeneGegevens.kadastraalInkomen,
          jaarVerkoop: null,
          maandVerkoop: null,
          datumVerkoop: null,
          verkoopprijs: null,
          idCodeVerkoopType: null,
          epcScore: this.detailMixin.element.bouwmaterialen.epcScore,
          renovatiejaar: this.detailMixin.element.bijzondereElementen.renovatiejaarVan,
          kadastraleAfdeling: this.detailMixin.element.algemeneGegevens.kadastraleAfdeling,
          kadastraleSectie: this.detailMixin.element.algemeneGegevens.kadastraleSectie,
          kadastraleNummers: this.detailMixin.element.algemeneGegevens.kadastraleNummers
        }
      }
    },
    async UpdateVergelijkingspunt () {
      if (this.$refs.formDialog.validate()) {
        this.vergelijkingspunt.datumVerkoop = (this.vergelijkingspunt.jaarVerkoop && this.vergelijkingspunt.maandVerkoop) ? moment(new Date(this.vergelijkingspunt.jaarVerkoop, this.vergelijkingspunt.maandVerkoop)) : null
        const response = await vergelijkingspuntService.Create(this.vergelijkingspunt)
        if (response && response.data) {
          await this.SetFields()
          await this.GetDataFromApi()
          this.openVergelijkingspuntDialog = false
        }
      }
    },
    async GetBeschikbareVergelijkingspuntenPaged (sortBy, sortType, page, pageSize, aard, idPostcode) {
      // voeg eigen vergelijkingspunt toe aan excluded
      const excludedVergelijkingspunten = this.CommonMixin_Clone(this.gekoppeldeVergelijkingspuntenIds)
      excludedVergelijkingspunten.push(this.detailMixin.element.schatting.idVergelijkingspunt)

      const response = await vergelijkingspuntService.GetVergelijkingspuntenPaged(sortBy, sortType, null, page, pageSize, aard, idPostcode, excludedVergelijkingspunten)
      if (response && response.data) {
        const data = response.data
        this.beschikbareVergelijkingspunten = data.items
        this.totalItems = data.totalItems
      }
    },
    GetGemeenteByIdPostcode (idPostcode) {
      if (this.postcodes != null) {
        var gemeente = this.postcodes.find(x => x.idPostcode === idPostcode)
        if (gemeente && gemeente.tekst) {
          return gemeente.tekst
        }
      }
    },
    async GetPosten () {
      const response = await postService.GetPosten()
      if (response && response.data) {
        const data = response.data
        this.postcodes = data
      }
    },
    async GetDataFromApi () {
      this.beschikbareLoading = true
      var sortBy = this.beschikbareOptions.sortBy ? this.beschikbareOptions.sortBy[0] : null
      var sortDesc = (this.beschikbareOptions.sortDesc && this.beschikbareOptions.sortDesc[0]) ? 'desc' : 'asc'
      if (this.detailMixin.element && this.detailMixin.element.algemeneGegevens) {
        await this.GetBeschikbareVergelijkingspuntenPaged(sortBy, sortDesc, this.beschikbareOptions.page, this.beschikbareOptions.itemsPerPage, this.detailMixin.element.algemeneGegevens.aardGoed, this.detailMixin.element.algemeneGegevens.idPostcode).then(data => {
          this.beschikbareLoading = false
        })
      } else {
        this.beschikbareLoading = false
      }
    },
    async GetVerkoopTypeList () {
      const response = await codeService.GetVerkoopTypeList()
      if (response && response.data) {
        const data = response.data
        this.verkoopTypeList = data
      }
    },
    async SetFields () {
      this.CommonMixin_Load(this.$options.name, true)
      const id = this.DetailMixin_GetId()

      if (id) {
        var element = {
          afmetingen: {},
          algemeneGegevens: {},
          bijzondereElementen: {},
          bouwmaterialen: {},
          schatting: {},
          vergelijkingspunten: {
            idSchatting: id,
            vergelijkingspunten: []
          }
        }

        const responseSchatting = await schattingService.GetSchattingById(id)
        if (responseSchatting && (responseSchatting.data || responseSchatting.data !== '')) {
          element.schatting = this.CommonMixin_Clone(responseSchatting.data)
          this.$forceUpdate()
        }

        const responseAlgemeneGegevens = await schattingService.GetAlgemeneGegevensByIdSchatting(id)
        if (responseAlgemeneGegevens && (responseAlgemeneGegevens.data || responseAlgemeneGegevens.data !== '')) {
          element.algemeneGegevens = this.CommonMixin_Clone(responseAlgemeneGegevens.data)
          this.$forceUpdate()
        }

        const responseVergelijkingspunten = await schattingService.GetVergelijkingspuntenByIdSchatting(id)
        if (responseVergelijkingspunten && (responseVergelijkingspunten.data || responseVergelijkingspunten.data !== '')) {
          element.vergelijkingspunten = this.CommonMixin_Clone(responseVergelijkingspunten.data)
          this.gekoppeldeVergelijkingspuntenIds = element.vergelijkingspunten.vergelijkingspunten.map(x => x.idVergelijkingspunt)
          console.log(element.vergelijkingspunten.vergelijkingspunten)
          this.$forceUpdate()
        }

        const responseBijzondereElementen = await schattingService.GetBijzonderElementByIdSchatting(id)
        if (responseBijzondereElementen && (responseBijzondereElementen.data || responseBijzondereElementen.data !== '')) {
          element.bijzondereElementen = this.CommonMixin_Clone(responseBijzondereElementen.data)
          this.$forceUpdate()
        }

        const responseBouwmaterialen = await schattingService.GetBouwmateriaalByIdSchatting(id)
        if (responseBouwmaterialen && (responseBouwmaterialen.data || responseBouwmaterialen.data !== '')) {
          element.bouwmaterialen = this.CommonMixin_Clone(responseBouwmaterialen.data)
          this.$forceUpdate()
        }

        const responseAfmetingen = await schattingService.GetAfmetingenByIdSchatting(id)
        if (responseAfmetingen && (responseAfmetingen.data || responseAfmetingen.data !== '')) {
          element.afmetingen = this.CommonMixin_Clone(responseAfmetingen.data)
          this.$forceUpdate()
        }

        this.detailMixin.element = this.CommonMixin_Clone(element)

        this.DetailMixin_InitDirtyWatch()
      }

      if (this.detailMixin.element.schatting.idVergelijkingspunt) {
        this.hasVergelijkingspunt = true
      }
      await this.GetDataFromApi()

      this.CommonMixin_Load(this.$options.name, false)
    },
    SetVariables () {
      this.detailMixin.key = 'id'
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.detailMixin.functions.validate = this.$refs.formVergelijkingspunten.validate
      this.detailMixin.functions.save = this.save
    },
    async save (element) {
      await schattingService.UpdateSchattingVergelijkingspunten(element.vergelijkingspunten)
      return {
        data: {
          algemeneGegevens: this.detailMixin.element.algemeneGegevens,
          schatting: this.detailMixin.element.schatting,
          vergelijkingspunten: this.detailMixin.element.vergelijkingspunten
        }
      }
    },
    Validate () {
      this.$refs.formVergelijkingspunten.validate()
      return this.detailMixin.valid
    }
  }
}
</script>
