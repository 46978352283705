<template>
  <div
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-text-field
      v-if="!readonly && !disabled && (hovering || focus)"
      v-model="date"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :readonly="readonly"
      ref="control"
      :rules="Rule"
      type="date"
      @blur="focus = false"
      @change="$emit('change', $event)"
      @focus="focus = true"
    />
    <v-text-field
      v-if="readonly || disabled || !(hovering || focus) || focusWrong"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :readonly="readonly"
      :rules="Rule"
      :value="DateText"
      @focus="FocusWrong()"
    />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ControlDate',
  props: {
    dense: Boolean,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    required: Boolean,
    requiredText: {
      default: 'Required.',
      type: String
    },
    value: String
  },
  data () {
    return {
      date: null,
      focus: false,
      focusWrong: false,
      hovering: false,
      requiredRule: value => !!value || this.requiredText
    }
  },
  mounted () {
    this.FormatData()
  },
  computed: {
    DateText () {
      return this.date ? moment(this.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    Rule () {
      if (this.required) {
        return [this.requiredRule]
      } else {
        return []
      }
    },
    ShowControls () {
      return !this.readonly && this.hovering
    }
  },
  watch: {
    date () {
      this.Emit()
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      this.FormatData()
    }
  },
  methods: {
    Emit () {
      if ((this.date && this.date.trim() !== '')) {
        this.$emit('input', `${this.date}T00:00:00`)
      } else {
        this.$emit('input', null)
      }
    },
    FocusWrong () {
      this.focusWrong = true
      this.focus = true

      this.$nextTick(() => {
        this.$refs.control.focus()
        this.focusWrong = false
      })
    },
    FormatData () {
      if (this.value) {
        this.date = moment(this.value, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
      } else {
        this.date = null
      }
    },
    MouseLeave () {
      this.hovering = false
      this.$emit('mouseleave')
    },
    MouseOver () {
      this.hovering = true
      this.$emit('mouseover')
    }
  }
}
</script>
