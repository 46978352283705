<template>
  <v-card
    class="ma-3"
    v-if="AuthenticationMixin_LoggedIn && AuthenticationMixin_HasRights(['gebruiker','admin'])">
    <v-row class="justify-space-between">
      <v-col cols="9" md="6" lg="4">
        <v-text-field
          class="pa-2"
          clearable
          v-model="search"
          v-on:input="callDebounceSearch"
          append-icon="mdi-magnify"
          label="Zoeken"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="6" lg="8">
        <v-btn
          class="ma-5 float-right d-none d-md-block"
          color="primary"
          @click="openVergelijkingspunt()"
        >
          <v-icon>mdi-plus</v-icon>
          Nieuw vergelijkingspunt
        </v-btn>
      <v-btn
        class="ma-5 float-right d-md-none"
        color="primary"
        @click="openVergelijkingspunt()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :search="search"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Vergelijkingspunten per pagina',
        'items-per-page-options': [25, 25, 50, 100]
      }"
      class="elevation-1"
      @click:row="openVergelijkingspunt"
    >
    <template v-slot:[`item.perceelBreedte`]="{ item }">
      <template v-if="item.perceelBreedte != null">
      {{ FormatMixin_FormatDecimal(item.perceelBreedte, 0) }} m
      </template>
      <template v-else>
        /
      </template>
    </template>
    <template v-slot:[`item.perceelOppervlakte`]="{ item }">
      <template v-if="item.perceelOppervlakte != null">
      {{ FormatMixin_FormatDecimal(item.perceelOppervlakte, 0) }} m²
      </template>
      <template v-else>
        /
      </template>
    </template>
    <template v-slot:[`item.bewoonbareOppervlakte`]="{ item }">
      <template v-if="item.bewoonbareOppervlakte != null">
      {{ FormatMixin_FormatDecimal(item.bewoonbareOppervlakte, 0) }} m²
      </template>
      <template v-else>
        /
      </template>
    </template>
    <template v-slot:[`item.bouwjaar`]="{ item }">
      <template v-if="item.bouwjaar != null">
      {{ item.bouwjaar }}
      </template>
      <template v-else>
        /
      </template>
    </template>
    <template v-slot:[`item.verkoopType`]="{ item }">
      <template v-if="item.verkoopType != null">
      {{ item.verkoopType }}
      </template>
      <template v-else>
        /
      </template>
    </template>
    <template v-slot:[`item.renovatiejaar`]="{ item }">
      <template v-if="item.renovatiejaar != null">
      {{ item.renovatiejaar }}
      </template>
      <template v-else>
        /
      </template>
    </template>
    <template v-slot:[`item.ki`]="{ item }">
        <template v-if="item.ki != null">
        € {{ FormatMixin_FormatDecimal(item.ki, 2) }}
        </template>
        <template v-else>
          /
        </template>
      </template>
    <template v-slot:[`item.datumVerkoop`]="{ item }">
        <template v-if="item.datumVerkoop != null">
        {{ FormatMixin_FormatDateTime(item.datumVerkoop, formatMixin.date.database, formatMixin.date.monthnameYear, 'nl-BE') }}
        </template>
        <template v-else>
          /
        </template>
      </template>
    <template v-slot:[`item.verkoopprijs`]="{ item }">
      <template v-if="item.verkoopprijs != null">
      € {{ FormatMixin_FormatDecimal(item.verkoopprijs, 0) }}
      </template>
      <template v-else>
        /
      </template>
    </template>
    <template v-slot:[`item.adres`]="{ item }">
        {{ item.straat }} {{ item.huisnummer }}
      </template>
    <template v-slot:[`item.idPostcode`]="{ item }">
        {{ GetGemeenteByIdPostcode(item.idPostcode) }}
      </template>
      <template v-slot:[`item.acties`]="{ item }">
        <v-btn
        icon
        class="deleteItem"
        @click.stop="deleteItem (item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
    </template>
    </v-data-table>

    <v-dialog
        v-model="deletedialog"
        max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Vergelijkingspunt verwijderen
          </v-card-title>
          <v-card-text v-if="selectedItem">
            Ben je zeker dat je '<strong>{{ selectedItem.pand }}</strong>' wilt verwijderen?
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              @click="deletedialog = false">
              Nee
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="DeleteVergelijkingspunt">
              Ja, verwijderen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-form
        v-model="valid"
        ref="form"
        lazy-validation>
        <v-dialog
          v-model="editdialog"
          max-width="750">
          <v-card>
            <v-card-title class="text-h5 primary--text">
              {{ editdialogTitle }}
            </v-card-title>
            <v-card-text v-if="selectedItem">
              <option-list
                v-model="selectedItem.pand"
                :list="aarden"
                label="Pand"
                :rules="[validationMixin.rules.required]"
              />
              <v-row class="pb-0">
                <v-col cols="8" sm="10">
                  <v-text-field
                    label="Straat"
                    v-model="selectedItem.straat"
                    :rules="[v => !!v || 'Straat is verplicht']"
                  />
                </v-col>
                <v-col cols="4" sm="2">
                  <v-text-field
                    label="Huisnr."
                    v-model="selectedItem.huisnummer"
                    :rules="[v => !!v || 'Huisnr. is verplicht']"
                  />
                </v-col>
                <v-col cols="12">
                  <contol-postcode
                    label="Gemeente"
                    v-model="selectedItem.idPostcode"
                    required
                    requiredText="Gemeente is verplicht"
                  />
                </v-col>
              </v-row>
              <v-row class="py-0">
                <v-col class="py-0" cols="12" sm="4">
                  <v-text-field
                    v-model="selectedItem.kadastraleAfdeling"
                    label="Kadastrale afdeling"
                    :rules="[v => !!v || 'Kadastrale afdeling is verplicht']"
                  />
                </v-col>
                <v-col class="py-0" cols="12" sm="4">
                  <v-text-field
                    v-model="selectedItem.kadastraleSectie"
                    label="Kadastrale sectie"
                    :rules="[v => !!v || 'Kadastrale sectie is verplicht']"
                  />
                </v-col>
                <v-col class="py-0" cols="12" sm="4">
                  <v-text-field
                    v-model="selectedItem.kadastraleNummers"
                    label="Kadastrale nrs."
                    required
                    :rules="[v => !!v || 'Kadastrale nrs. is verplicht']"
                  />
                </v-col>
              </v-row>
              <v-row class="py-0">
                <v-col class="py-0" cols="12" sm="4">
                  <control-number
                    v-model="selectedItem.perceelBreedte"
                    label="Perceel breedte"
                    suffix="m"
                    required
                    requiredText="Perceel breedte is verplicht"
                  />
                </v-col>
                <v-col class="py-0" cols="12" sm="4">
                    <control-number
                      v-model="selectedItem.perceelOppervlakte"
                      label="Perceel oppervlakte"
                      suffix="m²"
                      required
                      requiredText="Perceel oppervlakte is verplicht"/>
                </v-col>
                <v-col class="py-0" cols="12" sm="4">
                    <control-number
                      v-model="selectedItem.bewoonbareOppervlakte"
                      label="Bewoonbare oppervlakte"
                      suffix="m²"
                      required
                      requiredText="Bewoonbare oppervlakte is verplicht"/>
                </v-col>
              </v-row>
              <v-row class="py-0">
                <v-col class="py-0" cols="6" sm="4">
                    <control-number
                      label="KI"
                      v-model="selectedItem.ki"
                      prefix="€"
                      required
                      requiredText="KI is verplicht"/>
                </v-col>
                <v-col class="py-0" cols="6" sm="4">
                  <control-number
                    v-model="selectedItem.epcScore"
                    label="EPC-score (kWh/m²/jaar)"
                    :decimals="0"
                  />
                </v-col>
              </v-row>
              <v-row class="py-0">
                <v-col class="py-0" cols="6" sm="3">
                  <control-number
                    v-model="selectedItem.bouwjaar"
                    label="Bouwjaar"
                    :withDotSeparator="false"
                    required
                    requiredText="Bouwjaar is verplicht"
                  />
                </v-col>
                <v-col class="py-0" cols="6" sm="3">
                  <control-number
                    v-model="selectedItem.renovatiejaar"
                    label="Renovatiejaar"
                    :withDotSeparator="false"
                    required
                    requiredText="Renovatiejaar is verplicht"
                  />
                </v-col>
                <v-col class="py-0" cols="6" sm="3">
                  <control-select
                    v-model="selectedItem.maandVerkoop"
                    label="Maand verkoop"
                    :items="months"
                    itemText="text"
                    itemValue="value"
                    required
                    requiredText="Maand verkoop is verplicht"
                  />
                </v-col>
                <v-col class="py-0" cols="6" sm="3">
                    <control-number
                      label="Jaar verkoop"
                      v-model="selectedItem.jaarVerkoop"
                      :withDotSeparator="false"
                      required
                      requiredText="Jaar verkoop is verplicht"/>
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <control-number
                    label="Verkoopprijs"
                    v-model="selectedItem.verkoopprijs"
                    prefix="€"
                    required
                    requiredText="Verkoopprijs is verplicht"/>
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <control-select
                    :items="verkoopTypeList"
                    label="Type verkoop"
                    v-model="selectedItem.idCodeVerkoopType"
                    :itemValue="'idCode'"
                    :itemText="'langeBeschrijving'"
                  />
                </v-col>
              </v-row>
              <label for="bijlage">Bijlage (afbeeldingen)</label>
              <control-file-input-drag-and-drop-vue id="bijlage" single v-model="bijlage" @download="Download($event)" :accept="['.png', '.jpg', '.jpeg', '.gif']"/>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary darken-1"
                text
                @click="editdialog = false">
                Terug
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!isEditVergelijkingspuntDialog"
                color="primary darken-1"
                @click="CreateVergelijkingspunt">
                Aanmaken
              </v-btn>
              <v-btn
                v-if="isEditVergelijkingspuntDialog"
                color="primary darken-1"
                @click="UpdateVergelijkingspunt">
                Opslaan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
  </v-card>
  <v-card class="ma-3" v-else>
    <h2 class="primary--text pa-3">Log in om de schattingenlijst te kunnen bekijken.</h2>
  </v-card>
</template>

<script>
import _ from 'lodash'
import errorMixin from '../mixins/errorMixin'
import ControlFileInputDragAndDropVue from './ControlFileInputDragAndDrop.vue'
import vergelijkingspuntService from '../services/vergelijkingspuntService'
import authenticationMixin from '../mixins/authenticationMixin'
import formatMixin from '../mixins/formatMixin'
import validationMixin from '../mixins/validationMixin'
import ContolPostcode from '../components/ControlPostcode.vue'
import ControlNumber from './ControlNumber.vue'
import ControlSelect from './ControlSelect.vue'
import postService from '../services/postService'
import codeService from '../services/codeService'
import guidService from '../services/guidService'
import commonMixin from '../mixins/commonMixin'
import OptionList from '../components/OptionList'
import blobMixin from '../mixins/blobMixin'
import moment from 'moment'

export default {
  components: {
    ControlFileInputDragAndDropVue,
    ContolPostcode,
    ControlNumber,
    ControlSelect,
    OptionList
  },
  mixins: [
    errorMixin,
    authenticationMixin,
    blobMixin,
    formatMixin,
    validationMixin,
    commonMixin
  ],
  name: 'Vergelijkingspunten',
  data () {
    return {
      aarden: [
        'Woning',
        'Appartement',
        'Bouwgrond',
        'Landbouwgrond',
        'Handelspand',
        'Kantoor',
        'Opbrengsteigendom',
        'Bosgrond',
        'Natuurgebied',
        'KMO',
        'Industrie',
        'Garage',
        'Boerderij',
        'Projectgrond'
      ],
      rollen: [],
      valid: true,
      showPassword: false,
      showPassword2: false,
      selectedItem: null,
      formattedSelectedItem: null,
      deletedialog: false,
      editdialog: false,
      isEditVergelijkingspuntDialog: false,
      editdialogTitle: null,
      editDialogWachtwoordWijzigen: false,
      totalItems: 0,
      items: [],
      postcodes: null,
      search: null,
      loading: true,
      options: {},
      months: [
        { value: '1', text: 'januari' },
        { value: '2', text: 'februari' },
        { value: '3', text: 'maart' },
        { value: '4', text: 'april' },
        { value: '5', text: 'mei' },
        { value: '6', text: 'juni' },
        { value: '7', text: 'juli' },
        { value: '8', text: 'augustus' },
        { value: '9', text: 'september' },
        { value: '10', text: 'oktober' },
        { value: '11', text: 'november' },
        { value: '12', text: 'december' }
      ],
      headers: [
        {
          text: 'Pand',
          align: 'start',
          value: 'pand'
        },
        {
          text: 'Adres',
          align: 'start',
          value: 'adres'
        },
        {
          text: 'Gemeente',
          align: 'start',
          value: 'idPostcode'
        },
        {
          text: 'Bouwjaar',
          align: 'end',
          value: 'bouwjaar'
        },
        {
          text: 'Renovatiejaar',
          align: 'end',
          value: 'renovatiejaar'
        },
        {
          text: 'Perceel breedte',
          align: 'end',
          value: 'perceelBreedte'
        },
        {
          text: 'Perceel opp.',
          align: 'end',
          value: 'perceelOppervlakte'
        },
        {
          text: 'Bewoonbare opp.',
          align: 'end',
          value: 'bewoonbareOppervlakte'
        },
        {
          text: 'KI',
          align: 'end',
          value: 'ki'
        },
        {
          text: 'Datum verkoop',
          align: 'end',
          value: 'datumVerkoop'
        },
        {
          text: 'Verkoopprijs',
          align: 'end',
          value: 'verkoopprijs'
        },
        {
          text: 'Verkooptype',
          align: 'end',
          value: 'verkoopType'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'acties'
        }
      ],
      bijlage: null,
      vergelijkingsPuntType: '8ab75f12-6f71-4ed2-92b6-03732d5e696b',
      verkoopTypeList: []
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  mounted () {
    vergelijkingspuntService.Init(this)
    postService.Init(this)
    guidService.Init(this)
    codeService.Init(this)
    this.GetPosten()
    this.GetVerkoopTypeList()
  },
  methods: {
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    async GetVerkoopTypeList () {
      const response = await codeService.GetVerkoopTypeList()
      if (response && response.data) {
        const data = response.data
        this.verkoopTypeList = data
      }
    },
    async GetVergelijkingspuntenPaged (sortBy, sortType, search, page, pageSize) {
      const response = await vergelijkingspuntService.GetVergelijkingspuntenPaged(sortBy, sortType, search, page, pageSize)
      if (response && response.data) {
        const data = response.data
        this.items = data.items
        this.totalItems = data.totalItems
      }
    },
    async getDataFromApi () {
      this.loading = true
      await this.GetVergelijkingspuntenPaged(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc', this.search, this.options.page, this.options.itemsPerPage).then(data => {
        this.loading = false
      })
    },
    getStatusColor (status) {
      switch (status) {
        case 'Afgehandeld':
          return '#2e7a2e'
        case 'Aanpassen':
          return '#356191'
        case 'Concept':
          return 'darkgrey'
      }
    },
    callDebounceSearch: _.debounce(function () {
      this.debounceSearch()
    }, 500),
    debounceSearch () {
      this.getDataFromApi()
    },
    GetGemeenteByIdPostcode (idPostcode) {
      if (this.postcodes != null) {
        var gemeente = this.postcodes.find(x => x.idPostcode === idPostcode)
        if (gemeente && gemeente.tekst) {
          return gemeente.tekst
        }
      }
    },
    async openVergelijkingspunt (vergelijkingspunt) {
      this.editDialogWachtwoordWijzigen = false
      this.showPassword = false
      this.showPassword2 = false
      this.$refs.form.resetValidation()
      if (vergelijkingspunt) {
        this.editdialogTitle = 'Wijzig vergelijkingspunt'
        this.isEditVergelijkingspuntDialog = true
        this.selectedItem = {
          idVergelijkingspunt: vergelijkingspunt.idVergelijkingspunt,
          pand: vergelijkingspunt.pand,
          straat: vergelijkingspunt.straat,
          huisnummer: vergelijkingspunt.huisnummer,
          idPostcode: vergelijkingspunt.idPostcode,
          bouwjaar: vergelijkingspunt.bouwjaar,
          perceelOppervlakte: vergelijkingspunt.perceelOppervlakte,
          bewoonbareOppervlakte: vergelijkingspunt.bewoonbareOppervlakte,
          perceelBreedte: vergelijkingspunt.perceelBreedte,
          ki: vergelijkingspunt.ki,
          jaarVerkoop: vergelijkingspunt.datumVerkoop ? new Date(vergelijkingspunt.datumVerkoop).getFullYear() : null,
          maandVerkoop: vergelijkingspunt.datumVerkoop ? (new Date(vergelijkingspunt.datumVerkoop).getMonth() + 1).toString() : null,
          datumVerkoop: vergelijkingspunt.datumVerkoop,
          verkoopprijs: vergelijkingspunt.verkoopprijs,
          idCodeVerkoopType: vergelijkingspunt.idCodeVerkoopType,
          verkoopType: vergelijkingspunt.verkoopType,
          epcScore: vergelijkingspunt.epcScore,
          renovatiejaar: vergelijkingspunt.renovatiejaar,
          kadastraleAfdeling: vergelijkingspunt.kadastraleAfdeling,
          kadastraleSectie: vergelijkingspunt.kadastraleSectie,
          kadastraleNummers: vergelijkingspunt.kadastraleNummers
        }
        var blobs = (await this.BlobMixin_GetFiles(this.selectedItem.idVergelijkingspunt, this.vergelijkingsPuntType))
        this.bijlage = []
        if (blobs.length > 0) {
          blobs.forEach(blob => {
            this.bijlage.push(blob)
          })
        }
      } else {
        this.editdialogTitle = 'Nieuw vergelijkingspunt'
        this.isEditVergelijkingspuntDialog = false
        const response = await guidService.New()
        let newGuid = null
        if (response && response.data) {
          newGuid = response.data
        }
        this.selectedItem = {
          idVergelijkingspunt: newGuid,
          pand: null,
          straat: null,
          huisnummer: null,
          idPostcode: null,
          bouwjaar: null,
          oppervlakte: null,
          ki: null,
          datumVerkoop: null,
          maandVerkoop: null,
          jaarVerkoop: null,
          verkoopprijs: null,
          kadastraleAfdeling: null,
          kadastraleSectie: null,
          kadastraleNummers: null
        }
        this.bijlage = []
      }
      this.editdialog = true
    },
    async GetPosten () {
      const response = await postService.GetPosten()
      if (response && response.data) {
        const data = response.data
        this.postcodes = data
      }
    },
    deleteItem (item) {
      this.selectedItem = item
      this.deletedialog = true
    },
    async CreateVergelijkingspunt () {
      if (this.$refs.form.validate()) {
        this.FormatSelectedItem()
        if (this.bijlage && this.bijlage.length > 0) {
          await this.BlobMixin_SaveFiles(this.bijlage, this.selectedItem.idVergelijkingspunt, this.vergelijkingsPuntType)
          var blobs = await this.BlobMixin_GetFiles(this.selectedItem.idVergelijkingspunt, this.vergelijkingsPuntType)
          this.CommonMixin_SuccessOrFailMessage((blobs && blobs.length === this.bijlage.length) || !this.bijlage, null, 'Bijlage is niet correct opgeslagen.')
        }

        const response = await vergelijkingspuntService.Create(this.formattedSelectedItem)
        if (response && response.data !== null) {
          this.editdialog = false
          this.getDataFromApi()
        }
      }
    },
    async UpdateVergelijkingspunt () {
      if (this.$refs.form.validate()) {
        this.FormatSelectedItem()
        if (this.bijlage && this.bijlage.length > 0) {
          await this.BlobMixin_SaveFiles(this.bijlage, this.selectedItem.idVergelijkingspunt, this.vergelijkingsPuntType)
          var blobs = await this.BlobMixin_GetFiles(this.selectedItem.idVergelijkingspunt, this.vergelijkingsPuntType)
          this.CommonMixin_SuccessOrFailMessage((blobs && blobs.length === this.bijlage.length) || !this.bijlage, null, 'Bijlage is niet correct opgeslagen.')
        }

        const response = await vergelijkingspuntService.Update(this.formattedSelectedItem)
        if (response && response.data !== null) {
          this.editdialog = false
          this.getDataFromApi()
        }
      }
    },
    async DeleteVergelijkingspunt () {
      var blobs = await this.BlobMixin_GetFiles(this.selectedItem.idVergelijkingspunt, this.vergelijkingsPuntType)
      blobs.forEach(blob => {
        this.BlobMixin_DeleteBlobById(blob.idBlob)
      })
      const response = await vergelijkingspuntService.Delete(this.selectedItem.idVergelijkingspunt)
      if (response && response.data) {
        this.editdialog = false
        this.deletedialog = false
        this.getDataFromApi()
      }
    },
    FormatSelectedItem () {
      this.formattedSelectedItem = this.CommonMixin_Clone(this.selectedItem)
      this.formattedSelectedItem.datumVerkoop = (this.selectedItem.jaarVerkoop && this.selectedItem.maandVerkoop) ? moment(new Date(this.selectedItem.jaarVerkoop, this.selectedItem.maandVerkoop)) : null
    }
  }
}
</script>
