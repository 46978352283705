<template>
      <v-sheet elevation="0" rounded="sm"
            class="fileuploader mb-2"
            style="border: 1px dashed #949494"
            v-cloak
            @drop.prevent="AddDropFile"
            @dragover.prevent
            :loading="isSelecting"
            @click="HandleFileImport">

        <p v-if="!VisibleFiles || !VisibleFiles.length" class="text-center ma-6" style="font-size:14px">Sleep bestand{{ single ? '' : 'en'}} naar dit vak of <span class="text-decoration-underline">upload bestand{{ single ? '' : 'en'}}</span>.</p>

        <input
          ref="uploader"
          class="d-none"
          type="file"
          :multiple="!single"
          @change="OnFileChanged"
          :accept="Accept"
        >

        <div v-if="VisibleFiles && VisibleFiles.length" class="px-2 pt-1">
          <v-chip-group active-class="primary--text" column>
            <v-chip v-for="file in VisibleFiles" :key="file.id" close @click:close="RemoveFile(file)" @click.prevent="Download(file)" :title="`Download ${file.name}`">
              {{ FormatFileName(file.name) }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-sheet>
</template>

<script>
export default {
  props: {
    single: {
      type: Boolean,
      default: false
    },
    value: Array,
    accept: null
  },
  data: () => (
    {
      files: [],
      filesDropped: 0,
      isSelecting: false,
      isDownloading: false,
      status: {
        unchanged: 0,
        new: 1,
        updated: 2,
        deleted: 3
      }
    }
  ),
  computed: {
    VisibleFiles () {
      return this.files.filter(f => f.status !== this.status.deleted)
    },
    Accept () {
      return this.accept !== null ? String(this.accept) : null
    }
  },
  mounted () {
    this.SetFiles()
  },
  watch: {
    value () {
      this.SetFiles()
    }
  },
  methods: {
    AddDropFile (e) {
      var droppedFiles = Array.from(e.dataTransfer.files)

      if (this.accept) {
        droppedFiles = this.GetAcceptedFilesFromSelected(droppedFiles)
      }

      if (this.single) {
        var firstFile = droppedFiles[0]
        droppedFiles = []
        droppedFiles.push(firstFile)
      }

      for (let j = 0; j < droppedFiles.length; j++) {
        const droppedFile = droppedFiles[j]
        this.files.push({
          id: this.filesDropped++,
          name: droppedFile.name,
          data: droppedFile,
          status: this.status.new
        })
      }
      this.Emit()
    },
    Download (file) {
      this.isDownloading = true
      if (file.idBlob) {
        this.DownloadExisting(file.idBlob, file.name)
      } else {
        this.DownloadNew(file.name, file.data)
      }
    },
    DownloadExisting (idBlob, filename) {
      this.$emit('download', {
        idBlob: idBlob,
        name: filename
      })
    },
    DownloadNew (filename, data) {
      var a = document.createElement('a')
      a.href = data
      a.download = filename
      a.click()
    },
    Emit () {
      var files = []
      this.files.forEach(file => {
        if (typeof (file.data) === 'object') {
          const reader = new FileReader()
          let data = null
          reader.onload = (e) => {
            data = e.target.result
            files.push({
              id: this.filesDropped++,
              data: data,
              size: this.GetFileSize(data, 'MB', false, false),
              status: file.status,
              name: file.data.name
            })
          }

          reader.readAsDataURL(file.data)
        } else {
          file.id = this.filesDropped++
          files.push(file)
        }
      })
      this.$emit('input', files)
    },
    FormatDecimal (value, digits = 2, showZero = true) {
      if (value || showZero) {
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'decimal',
          maximumFractionDigits: digits,
          minimumFractionDigits: digits
        })

        return formatter.format(value ?? 0)
      } else {
        return null
      }
    },
    FormatFileName (name) {
      const firstPart = 10
      const secondPart = 10
      const maxLength = firstPart + secondPart + 1

      if (name && name.length > maxLength) {
        return `${name.substr(0, firstPart)}…${name.substr(name.length - secondPart)}`
      } else {
        return name
      }
    },
    GetFileSize (base64, unit = '', showUnit = true, showZero = true) {
      if (base64) {
        let size = Buffer.from(base64, 'base64').length
        let exponent = 0

        if (showZero || size) {
          switch (unit) {
            case 'KB':
              exponent = 1
              break
            case 'MB':
              exponent = 2
              break
            case 'GB':
              exponent = 3
              break
          }

          size /= Math.pow(1024, exponent)

          return showUnit ? `${this.FormatDecimal(size)}${unit}` : size
        } else {
          return null
        }
      } else {
        return null
      }
    },
    HandleFileImport () {
      if (!this.isDownloading) {
        this.isSelecting = true

        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.uploader.value = null
        this.$refs.uploader.click()
      }
      this.isDownloading = false
    },
    GetAcceptedFilesFromSelected (selectedFiles) {
      return Array.from(selectedFiles).filter(x => {
        let acceptedFileExtension = false
        this.accept.forEach(a => {
          if (x.name.toLowerCase().endsWith(a)) {
            acceptedFileExtension = true
          }
        })
        return acceptedFileExtension
      })
    },
    OnFileChanged (e) {
      let selectedFiles = e.target.files

      if (this.accept) {
        selectedFiles = this.GetAcceptedFilesFromSelected(selectedFiles)
      }

      if (this.single && selectedFiles.length === 1) {
        this.files.forEach(f => this.RemoveFile(f))
        var firstFile = selectedFiles[0]
        selectedFiles = []
        selectedFiles.push(firstFile)
      }

      for (let j = 0; j < selectedFiles.length; j++) {
        const selectedFile = selectedFiles[j]
        this.files.push({
          id: this.filesDropped++,
          name: selectedFile.name,
          data: selectedFile,
          status: this.status.new
        })
      }
      this.Emit()
    },
    RemoveFile (file) {
      if (file.status === this.status.new) {
        var index = this.files.indexOf(file)
        this.files.splice(index, 1)
      } else {
        file.status = this.status.deleted
      }
      this.Emit()
      this.$forceUpdate()
    },
    SetFiles () {
      if (this.value) {
        this.files = []
        this.value.forEach(file => {
          this.files.push(file)
        })
      } else {
        this.files = []
      }
    }
  }
}
</script>

<style scoped>
    .fileuploader:hover {
        cursor: pointer;
    }
</style>
