<template>
  <detail
    ref="detail"
  />
</template>

<script>
import Detail from '../components/Detail.vue'

export default {
  name: 'DetailView',
  components: {
    Detail
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.detail.DetailMixin_Leave(next)
  }
}
</script>
