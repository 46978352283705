<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="sidebar"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="265"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${sidebarColor}`"
        v-bind="props"
      />
    </template>
    <v-list
      dense
      nav
      class="logo pa-5"
    >
      <v-list-item
        @click="Home()"
      >
        <v-img
          src="@/assets/images/LSG_Logo.svg"
          max-width="150"
        />
      </v-list-item>
    </v-list>
    <v-divider class="mb-2" />
    <v-list
      v-if="AuthenticationMixin_LoggedIn"
      expand
      nav
    >
      <div>
        <template v-for="(item, i) in computedItems">
          <menu-item-group
            v-if="AuthenticationMixin_HasRights(item.types) && (!item.optional || item.children.length > 0)"
            :key="`group-${i}`"
            :item="item"
          />
        </template>
      </div>
    </v-list>
    <template v-slot:append>
      <menu-item
        :item="LogItem"
        @click="LogItem.action === 'login' ? OpenLoginDialog() : Logout()"
      />
      <login-dialog :open="openLoginDialog" v-on:close="openLoginDialog=false" v-on:login="Login"/>
    </template>
  </v-navigation-drawer>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import authenticationService from '@/services/authenticationService'
import errorMixin from '@/mixins/errorMixin'
import recentMixin from '@/mixins/recentMixin'
import MenuItem from './MenuItem.vue'
import MenuItemGroup from './MenuItemGroup.vue'
import { mapState } from 'vuex'
import LoginDialog from './LoginDialog.vue'

export default {
  name: 'TheSidebar',
  components: {
    MenuItem,
    MenuItemGroup,
    LoginDialog
  },
  mixins: [
    authenticationMixin,
    errorMixin,
    recentMixin
  ],
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    openLoginDialog: false,
    account: undefined,
    items: [
      {
        icon: 'mdi-view-list',
        title: 'Schattingen',
        to: '/',
        types: [
          'admin',
          'gebruiker'
        ]
      },
      {
        icon: 'mdi-view-list',
        title: 'Vergelijkingspunten',
        to: 'Vergelijkingspunten',
        types: [
          'admin',
          'gebruiker'
        ]
      },
      {
        icon: 'mdi-cogs',
        title: 'Admin tools',
        children: [
          {
            dense: true,
            title: 'Gebruikers',
            to: 'Gebruikers',
            types: [
              'admin'
            ]
          },
          {
            dense: true,
            title: 'Templates',
            to: 'Templates',
            types: [
              'admin'
            ]
          }
        ],
        group: '',
        types: [
          'admin'
        ]
      }
    ],
    loginItem: {
      action: 'login',
      icon: 'mdi-login',
      title: 'Inloggen'
    },
    logoutItem: {
      action: 'logout',
      icon: 'mdi-logout',
      title: 'Uitloggen'
    }
  }),
  mounted () {
    authenticationService.Init(this)
  },
  computed: {
    ...mapState(['sidebarColor']),
    sidebar: {
      get () {
        return this.$store.state.sidebar
      },
      set (val) {
        this.$store.commit('SET_SIDEBAR', val)
      }
    },
    computedItems () {
      return this.items.map(this.mapItem)
    },
    LogItem () {
      return this.AuthenticationMixin_LoggedIn ? this.logoutItem : this.loginItem
    },
    profile () {
      return {
        avatar: true,
        title: this.$t('avatar')
      }
    }
  },
  methods: {
    Home () {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' })
      }
    },
    OpenLoginDialog () {
      this.openLoginDialog = true
    },
    async Login () {
      this.$emitter.emit('login')
    },
    async Logout () {
      this.$emitter.emit('logout', 'logging out')
      this.AuthenticationMixin_Logout()
    },
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title
      }
    }
  }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
