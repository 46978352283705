import axios from 'axios'

let _svc
let _that

export default {
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  },
  Download: (id) => {
    try {
      return _svc.get(`Blob/Download/${id}`, {
        responseType: 'blob'
      })
    } catch {
      return null
    }
  },
  GetBySearchCriteria: (searchCriteria) => {
    try {
      return _svc.post('Blob/GetBySearchCriteria', searchCriteria)
    } catch {
      return null
    }
  },
  Save: (blobs) => {
    try {
      return _svc.post('Blob/Save', blobs)
    } catch {
      return null
    }
  }
}
