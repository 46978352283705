<template>
  <v-container
    class="px-3"
    fluid
    tag="section"
  >
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        lg="3"
      >
        <v-card
          class="px-5 mt-15"
        >
          <v-img
            src="@/assets/images/construction.png"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UnderConstruction'
}
</script>
