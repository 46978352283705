import axios from 'axios'

let _svc
let _that

export default {
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  },
  Login: (account) => {
    try {
      return _svc.put('Authentication/Login', account)
    } catch {
      return null
    }
  },

  GetGebruikersPaged: (sortBy, sortType, search, page = 1, pageSize = 10) => {
    try {
      var res = _svc.get('Authentication/Paged', {
        params: {
          sortBy: sortBy === undefined || sortBy === null ? 'Achternaam' : sortBy,
          sortType: sortType === undefined || sortType === null ? 'asc' : sortType,
          search: search === undefined || search === null ? ' ' : search,
          page: page,
          pageSize: pageSize
        }
      })
      return res
    } catch {
      return null
    }
  },

  GetGebruikersList: () => {
    try {
      var res = _svc.get('Authentication/List')
      return res
    } catch {
      return null
    }
  },
  Create: (gebruiker) => {
    try {
      return _svc.post('Authentication/Create', gebruiker)
    } catch {
      return null
    }
  },
  Update: (gebruiker) => {
    try {
      return _svc.put('Authentication/Update', gebruiker)
    } catch {
      return null
    }
  },
  Delete: (idGebruiker) => {
    try {
      return _svc.delete('Authentication/Delete', {
        params: {
          idGebruiker: idGebruiker
        }
      })
    } catch {
      return null
    }
  }
}
