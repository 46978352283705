<template>
  <v-form class="my-2"
    v-model="detailMixin.valid"
    ref="formNutsvoorzieningComfort">
    <div
      v-if="detailMixin.element && detailMixin.element.multicheck && detailMixin.element.fields"
      :class="DetailMixin_Dirty ? 'dirtyForm' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editForm' : ''"
      >
      <h2 class="primary--text">Beschrijving van nutsvoorzieningen en comfort</h2>

      <div v-if="selectieControlTypes && selectieControlTypes.length">
        <control-multi-check :cols="3" :list="nutsvoorzieningTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[0].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[0].langeBeschrijving"/>
        <v-divider></v-divider>
        <control-multi-check :cols="3" :list="verwarmingssysteemTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[1].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[1].langeBeschrijving"/>
        <v-divider></v-divider>
        <control-multi-check :cols="3" :list="comfortTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[2].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[2].langeBeschrijving"/>
      </div>
      <v-divider class="mb-5"></v-divider>

      <h3 class="secondary--text my-3">Sanitair</h3>
      <v-row>
        <v-col cols="12" md="9">
          <v-row class="pb-0">
              <v-col cols="12" md="6">
                <v-textarea label="Badkamer" auto-grow outlined v-model="detailMixin.element.fields.sanitairBadkamerBeschrijving"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea label="Keuken" auto-grow outlined v-model="detailMixin.element.fields.sanitairKeukenBeschrijving"/>
              </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Bijlage</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6">
          <control-file-input-drag-and-drop v-model="detailMixin.element.files" @download="Download($event)" :accept="['.png', '.jpg', '.jpeg', '.gif']"/>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import ControlFileInputDragAndDrop from '../ControlFileInputDragAndDrop.vue'
import ControlMultiCheck from '../ControlMultiCheck.vue'
import codeService from '../../services/codeService'
import schattingService from '../../services/schattingService'
import errorMixin from '@/mixins/errorMixin'
import commonMixin from '@/mixins/commonMixin'
import detailMixin from '../../mixins/detailMixin'
import blobMixin from '../../mixins/blobMixin'

export default {
  components: {
    ControlFileInputDragAndDrop,
    ControlMultiCheck
  },
  mixins: [
    errorMixin,
    commonMixin,
    detailMixin,
    blobMixin
  ],
  mounted () {
    codeService.Init(this)
    schattingService.Init(this)
  },
  props: {
    idSchatting: {
      default: null,
      type: String
    },
    visible: Boolean
  },
  data () {
    return {
      referentieType: 'c427744c-1ff2-41f8-8667-72c6daec33f2',
      nutsvoorzieningTypes: null,
      verwarmingssysteemTypes: null,
      comfortTypes: null,
      selectieControlTypes: null,
      extraBeschrijving: null
    }
  },
  watch: {
    async visible () {
      if (this.visible) {
        await this.SetVariables()
        await this.loadEverything()
        await this.SetSelectionList()
      } else if (this.DetailMixin_Dirty) {
        this.DetailMixin_Save()
      }
    }
  },
  methods: {
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    async loadEverything () {
      this.CommonMixin_Load(this.$options.name, true)
      await this.SetNutsvoorzieningTypes()
      await this.SetVerwarmingssysteemTypes()
      await this.SetComfortTypes()
      await this.SetSelectieControlTypes()
      this.CommonMixin_Load(this.$options.name, false)
    },
    async SetNutsvoorzieningTypes () {
      const response = await codeService.GetNutsvoorzieningTypes()
      if (response && response.data) {
        const data = response.data
        this.nutsvoorzieningTypes = data
      }
    },
    async SetVerwarmingssysteemTypes () {
      const response = await codeService.GetVerwarmingssysteemTypes()
      if (response && response.data) {
        const data = response.data
        this.verwarmingssysteemTypes = data
      }
    },
    async SetComfortTypes () {
      const response = await codeService.GetComfortTypes()
      if (response && response.data) {
        const data = response.data
        this.comfortTypes = data
      }
    },
    async SetSelectionList () {
      const id = this.DetailMixin_GetId()

      if (id) {
        const responseBouwmaterialenMulticheck = await codeService.GetCodeSelectie(id, this.referentieType)
        var element = {
          multicheck: {},
          fields: {},
          files: []
        }

        element.files = await this.BlobMixin_GetFiles(this.idSchatting, this.referentieType)

        if (responseBouwmaterialenMulticheck && (responseBouwmaterialenMulticheck.data || responseBouwmaterialenMulticheck.data !== '')) {
          element.multicheck = this.CommonMixin_Clone(responseBouwmaterialenMulticheck.data)
          this.$forceUpdate()
        }

        const responseBouwmaterialen = await schattingService.GetNutsvoorzieningComfortByIdSchatting(id)
        if (responseBouwmaterialen && (responseBouwmaterialen.data || responseBouwmaterialen.data !== '')) {
          element.fields = this.CommonMixin_Clone(responseBouwmaterialen.data)
          this.$forceUpdate()
        }

        this.detailMixin.element = this.CommonMixin_Clone(element)

        this.DetailMixin_InitDirtyWatch()
      }
    },
    async SetSelectieControlTypes () {
      const response = await codeService.GetSelectieControlTypes()
      if (response && response.data) {
        const data = response.data
        this.selectieControlTypes = data.filter(x => x.waarde >= 500 && x.waarde <= 599)
      }
    },
    SetVariables () {
      this.detailMixin.key = 'id'
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.detailMixin.functions.validate = this.$refs.formNutsvoorzieningComfort.validate
      this.detailMixin.functions.save = this.save
    },
    async save (element) {
      var multicheck = await codeService.SaveMulticheck({ IdReferentie: this.idSchatting, IdCodeReferentieType: this.referentieType, MultiSelects: element.multicheck })
      var fields = await schattingService.UpdateSchattingNutsvoorzieningComfort(element.fields)
      await this.BlobMixin_SaveFiles(element.files, this.idSchatting, this.referentieType)
      var multicheckData = null
      var fieldsData = null
      if (multicheck && multicheck.data) {
        multicheckData = multicheck.data
      }
      if (fields && fields.data) {
        fieldsData = fields.data
      }
      return {
        data: {
          multicheck: multicheckData,
          fields: fieldsData
        }
      }
    },
    Validate () {
      this.$refs.formNutsvoorzieningComfort.validate()
      return this.detailMixin.valid
    }
  }
}
</script>
