<template>
  <v-dialog
    v-model="open"
    max-width="600px"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
    transition="scale-transition"
  >
    <v-card>
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation>
        <v-toolbar color="primary" dark>
          <v-btn icon disabled> </v-btn>
          <v-spacer />
          <v-toolbar-title>Inloggen</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="Close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-12 pt-10">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  required
                  class="pa-2"
                  label="Email"
                  v-model="account.email"
                  :rules="[v => !!v || 'Email is verplicht', validationMixin.rules.email]"
                  :prepend-inner-icon="'mdi-email'"
                  @keyup.enter="Login()"
                  :autofocus="!account.email"
                  :class="{'shake' : animated}"
                />
                <v-text-field
                  required
                  class="pa-2"
                  v-model="account.wachtwoord"
                  :prepend-inner-icon="'mdi-lock'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  name="input-10-1"
                  label="Wachtwoord"
                  @click:append="show = !show"
                  @keyup.enter="Login()"
                  :class="{'shake' : animated}"
                  :autofocus="account.email && true"
                  :rules="[v => !!v || 'Wachtwoord is verplicht']"
                />
                <v-checkbox v-model="remember" class="px-2 my-0" label="Onthoud mij"/>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between px-5 pb-5">
          <v-btn text @click="Close()">Sluiten</v-btn>
          <v-btn color="primary" @click="Login()">Inloggen</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import validationMixin from '@/mixins/validationMixin'
import authenticationMixin from '../mixins/authenticationMixin'
import errorMixin from '../mixins/errorMixin'

export default {
  mixins: [
    validationMixin,
    authenticationMixin,
    errorMixin
  ],
  props: {
    open: Boolean
  },
  mounted () {
    if (this.$cookie) {
      this.account.email = this.$cookie.get(this.authenticationMixin.cookie.name)
    }
  },
  data: () => ({
    animated: false,
    valid: false,
    show: false,
    account: {
      email: null,
      wachtwoord: null
    },
    rules: {
      required: (value) => !!value || 'Required.'
    },
    remember: true
  }),
  methods: {
    async Login () {
      this.wrongLogin = false
      this.$refs.form.validate()
      if (this.valid && this.account.email != null && this.account.wachtwoord != null) {
        var resp = await this.AuthenticationMixin_Login(this.account, this.remember)
        if (!resp) {
          this.Shake()
        }
      } else {
        this.Shake()
      }
    },
    Close () {
      this.$emit('close')
    },
    Shake () {
      this.animated = true
      setTimeout(() => {
        this.animated = false
      }, 1000)
      setTimeout(() => {
        this.wrongLogin = false
      }, 2500)
    }
  }
}
</script>
