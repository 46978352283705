import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import formatMixin from '@/mixins/formatMixin'
import textMixin from '@/mixins/textMixin'
import validationMixin from '@/mixins/validationMixin'
import lockService from '@/services/lockService'
export default {
  mixins: [
    authenticationMixin,
    commonMixin,
    formatMixin,
    textMixin,
    validationMixin
  ],
  data () {
    return {
      detailMixin: {
        autosaveTime: 1,
        code: [],
        dialog: false,
        edit: true,
        element: {},
        extraLocks: [],
        functions: {
          save: () => {},
          validate: () => {}
        },
        initializing: true,
        interval: null,
        key: null,
        lastSavedVersion: null,
        returnUrl: '',
        rightsNeeded: null,
        startVersion: null,
        subcomponent: null,
        valid: false
      }
    }
  },
  computed: {
    DetailMixin_Dirty () {
      const dirty = this.detailMixin.element && this.detailMixin.lastSavedVersion && !this.CommonMixin_HardCompare(this.detailMixin.element, this.detailMixin.lastSavedVersion)
      this.$emit('dirty', dirty)
      return dirty
    },
    DetailMixin_Revertable () {
      return (this.detailMixin.edit && !this.CommonMixin_HardCompare(this.detailMixin.element, this.detailMixin.startVersion))
    }
  },
  watch: {
    DetailMixin_Revertable (value) {
      this.$emit('edit', value)
    },
    DetailMixin_Dirty (value) {
      if (value && this.detailMixin.edit) {
        this.DetailMixin_HandleDirty()
      } else {
        if (this.detailMixin.initializing) {
          this.detailMixin.initializing = false
        } else if (!this.DetailMixin_Revertable) {
          this.DetailMixin_HandleNotDirty()
        }
      }
    },
    'detailMixin.rightsNeeded' (value) {
      if (value != null) {
        this.detailMixin.edit = this.AuthenticationMixin_HasRights(value)
      }
    }
  },
  beforeDestroy () {
    if (this.detailMixin.interval) {
      clearInterval(this.detailMixin.interval)
      this.detailMixin.interval = null
    }
  },
  mounted () {
    lockService.Init(this)
  },
  methods: {
    async DetailMixin_Autosave () {
      if (this.DetailMixin_Dirty && !this.detailMixin.element.isNew && !this.detailMixin.dialog) {
        // this.DetailMixin_Save(true)
      } else if (!this.DetailMixin_Dirty || this.detailMixin.element.isNew) {
        if (this.detailMixin.interval) {
          clearInterval(this.detailMixin.interval)
          this.detailMixin.interval = null
        }
      }
    },
    async DetailMixin_CheckIfReleased (editing = false) {
      return true
      // let resp = this.AuthenticationMixin_HasRights(this.detailMixin.rightsNeeded) || this.detailMixin.rightsNeeded == null
      // if (!resp) {
      //   this.CommonMixin_Snackbar(this.textMixin.messages.noAccess, 'error')
      // } else if (!this.detailMixin.element.isNew) {
      //   const request = {
      //     autoUpdateTimestamp: this.detailMixin.element.autoUpdateTimestamp,
      //     editing: editing,
      //     extraLocks: this.detailMixin.extraLocks,
      //     idComponent: this.detailMixin.element[this.detailMixin.key],
      //     subcomponent: this.detailMixin.subcomponent
      //   }
      //   const lock = await lockService.SetLock(request)
      //   if (lock && lock.data) {
      //     resp = lock.data
      //   } else {
      //     this.detailMixin.element = this.CommonMixin_Clone(this.detailMixin.startVersion)
      //     resp = false
      //   }
      // }
      // this.detailMixin.edit = resp
      // return resp
    },
    DetailMixin_GetId () {
      if (this.$route.query != null) {
        const id = this.$route.query[this.detailMixin.key]
        if (id && id !== '') {
          if (this.validationMixin.rules.guid(id)) {
            return id
          }
        }
        return this.formatMixin.guid.empty
      }
    },
    async DetailMixin_HandleDirty () {
      if (!this.detailMixin.element.isNew) {
        if (await this.DetailMixin_CheckIfReleased(true) && this.detailMixin.autosaveTime > 0) {
          this.detailMixin.interval = setInterval(() => this.DetailMixin_Autosave(), this.detailMixin.autosaveTime * 1000 * 60)
        }
      }
    },
    async DetailMixin_HandleNotDirty () {
      if (!this.detailMixin.element.isNew) {
        if (await this.DetailMixin_CheckIfReleased()) {
          if (this.detailMixin.interval) {
            clearInterval(this.detailMixin.interval)
            this.detailMixin.interval = null
          }
        }
      }
    },
    async DetailMixin_Leave (next) {
      if (this.CommonMixin_Confirmation(this.DetailMixin_Dirty, this.textMixin.messages.unsavedChanges, true)) {
        await this.DetailMixin_Release(true)
        if (next) {
          next()
        } else {
          return true
        }
      } else {
        return false
      }
    },
    async DetailMixin_Release (allSubcomponents = false) {
      if (!this.detailMixin.element.isNew) {
        const request = {
          allSubcomponents: allSubcomponents,
          extraLocks: this.detailMixin.extraLocks,
          idComponent: this.detailMixin.element[this.detailMixin.key],
          subcomponent: this.detailMixin.subcomponent
        }
        await lockService.ReleaseLock(request)
      }
    },
    async DetailMixin_RevertChanges () {
      if (this.CommonMixin_Confirmation((this.DetailMixin_Dirty) || this.detailMixin.autosaved, this.textMixin.messages.revertChangesConfirmation, true)) {
        this.detailMixin.element = this.CommonMixin_Clone(this.detailMixin.startVersion)
        if (this.detailMixin.autosaved) {
          this.detailMixin.element.autoUpdateTimestamp = this.detailMixin.lastSavedVersion.autoUpdateTimestamp
          await this.DetailMixin_Save(false, true)
        } else {
          this.DetailMixin_CheckIfReleased()
        }
      }
    },
    async DetailMixin_Save (autosave = false, reverting = false, redirect = false) {
      if (await this.DetailMixin_CheckIfReleased(true)) {
        this.detailMixin.functions.validate()
        if (this.detailMixin.valid || reverting) {
          this.CommonMixin_Load(this.$options.name, true)
          const duplicate = this.CommonMixin_Clone(this.detailMixin.element)
          const response = await this.detailMixin.functions.save(duplicate)
          if (response && response.data) {
            this.detailMixin.element = this.CommonMixin_Clone(response.data)
            if (autosave) {
              this.detailMixin.lastSavedVersion = this.CommonMixin_Clone(response.data)
              this.detailMixin.autosaved = true
            } else {
              if (redirect) {
                this.$router.push({ path: this.$options.name, query: { returnUrl: this.detailMixin.returnUrl, [this.detailMixin.key]: this.detailMixin.element[this.detailMixin.key] } })
              }
              this.DetailMixin_InitDirtyWatch()
            }
          } else {
            this.CommonMixin_Snackbar(autosave ? this.textMixin.messages.autosaveFailed : this.textMixin.messages.saveFailed, 'error')
          }
          this.CommonMixin_Load(this.$options.name, false)
        } else {
          if (autosave) {
            this.CommonMixin_Snackbar(`${this.textMixin.messages.autosaveFailed}\n${this.textMixin.messages.formInvalid}`, 'error')
          } else {
            this.CommonMixin_Snackbar(`${this.textMixin.messages.formInvalid}`, 'error')
          }
        }
      }
    },
    DetailMixin_SetReturnUrl (defaultUrl) {
      this.detailMixin.returnUrl = defaultUrl
      if (this.$route.query != null) {
        const returnUrl = this.$route.query.returnUrl
        if (returnUrl && returnUrl !== '') {
          this.detailMixin.returnUrl = returnUrl
        }
      }
    },
    async DetailMixin_SetUpLock () {
      this.autosaved = false
      this.detailMixin.autosaveTime = 1
      this.DetailMixin_InitDirtyWatch()
      await this.DetailMixin_CheckIfReleased()
    },
    async DetailMixin_InitDirtyWatch () {
      this.detailMixin.autosaveTime = 0.1
      this.detailMixin.startVersion = this.CommonMixin_Clone(this.detailMixin.element)
      this.detailMixin.lastSavedVersion = this.CommonMixin_Clone(this.detailMixin.element)
    }
  }
}
