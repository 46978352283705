<template>
  <div
    class="d-flex"
  >
    <v-btn
      x-small
      @click="CopyContentToClipboard()"
    >
      <v-icon
        x-small
      >
        mdi-content-copy
      </v-icon>
    </v-btn>
    <pre
      v-html="JsonTree(value, name)"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseDebugger',
  props: {
    // Value to visually debug
    value: null,
    // Name of object
    name: {
      default: 'debugger',
      type: String
    }
  },
  methods: {
    async CopyContentToClipboard () {
      try {
        await navigator.clipboard.writeText(JSON.stringify(this.value, null, 2))
      } catch (err) {
        alert('⚠️ Json niet gekopieerd!')
      }
    }
  }
}
</script>
