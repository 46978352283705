import axios from 'axios'

let _svc
let _that

export default {
  GetDocument: (template) => {
    try {
      return _svc.put('Gembox/Result', template)
    } catch {
      return null
    }
  },

  GetTemplatesPaged: (sortBy, sortType, search, page = 1, pageSize = 10) => {
    try {
      var res = _svc.get('Template/Paged', {
        params: {
          sortBy: sortBy === undefined || sortBy === null ? 'naam' : sortBy,
          sortType: sortType === undefined || sortType === null ? 'asc' : sortType,
          search: search === undefined || search === null ? ' ' : search,
          page: page,
          pageSize: pageSize
        }
      })
      return res
    } catch {
      return null
    }
  },
  Create: (template) => {
    try {
      return _svc.post('Template/Create', template)
    } catch (ex) {
      return null
    }
  },
  GetAll: (active = null) => {
    try {
      return _svc.get('Template/GetAll', {
        params: {
          active: active
        }
      })
    } catch {
      return null
    }
  },
  Update: (template) => {
    try {
      return _svc.put('Template/Update', template)
    } catch {
      return null
    }
  },
  Delete: (idTemplate) => {
    try {
      return _svc.delete('Template/Delete', {
        params: {
          idTemplate: idTemplate
        }
      })
    } catch {
      return null
    }
  },

  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  }
}
