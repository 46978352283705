export default {
  data () {
    return {
      textMixin: {
        messages: {
          autosaveFailed: 'Uw gegevens konden niet via autosave worden opgeslagen.',
          deleteConfirmation: 'Weet u zeker dat u @@item wil verwijderen?',
          error: 'Er is iets fout gelopen.\nGelieve later opnieuw te proberen.',
          formInvalid: 'Gelieve eerst alle velden correct in te vullen.',
          generatedItem: '@@item werd gegenereerd door het systeem en kan dus maar beperkt worden aangepast.',
          invalidNumber: 'Ongeldig getal.',
          logoutInProgress: 'U wordt binnen enkele ogenblikken uitgelogd.',
          maxFileSize: 'Het bestand is te groot. (Maximum @@maxFileSizeMB)',
          required: 'Vereist.',
          revertChangesConfirmation: 'Weet u zeker dat u alle wijzigingen ongedaan wil maken?',
          saved: 'Uw gegevens werden opgeslagen.',
          saveFailed: 'Uw gegevens konden niet worden opgeslagen.',
          saveFailedFiles: 'Bijlages konden niet worden opgeslagen.',
          systemItem: '@@item wordt gebruikt door het systeem en kan dus maar beperkt worden aangepast.',
          unlinkedEmployee: 'Deze werknemer is nog niet gekoppeld aan een account.\nGelieve een gekend e-mailadres in te geven en op te slaan.',
          unsavedChanges: 'Weet u zeker dat u wil verder gaan?\nEr zijn nog niet-opgeslagen gegevens!'
        }
      }
    }
  }
}
