<template>
  <v-row
    class="mx-3"
  >
    <v-col
      cols="12"
      lg="12"
    >
      <v-card>
        <div class="pa-3">
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <filter-periode
                :invalidNumberText="textMixin.messages.invalidNumber"
                :jaar="backendFilters.jaar"
                :maand="backendFilters.maand"
                @jaar="backendFilters.jaar = $event"
                @maand="backendFilters.maand = $event"
                @change="GetFacturatieList()"
              />
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <v-btn
                class="float-right ml-3 mr-0 mt-2"
                color="primary"
                :disabled="CommonMixin_Disable"
                small
                @click="ListMixin_Add()"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-text-field
                v-model="listMixin.filter"
                append-icon="mdi-magnify"
                label="Zoeken"
              />
            </v-col>
          </v-row>
        </div>
        <v-data-table
          :headers="headers"
          :items="listMixin.listFiltered"
          :search="listMixin.filter"
          :sort-by="sortBy"
        >
          <template
            v-slot:header
          >
            <filter-header
              v-model="listMixin.listFiltered"
              :headers="headers"
              :list="listMixin.list"
            />
          </template>
          <template v-slot:item="props">
            <tr
              class="clickAction"
              :style="CommonMixin_SelectedStyle(ListMixin_Selected(props.item))"
              @click="Test(props.item)"
              @dblclick="ListMixin_OpenDetailPage(props.item)"
            >
              <td>
                {{ props.item.naam }}
              </td>
              <td>
                {{ props.item.value }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FilterHeader from './FilterHeader.vue'
import FilterPeriode from './FilterPeriode.vue'
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import testService from '@/services/testService'

export default {
  name: 'List',
  components: {
    FilterHeader,
    FilterPeriode
  },
  mixins: [
    commonMixin,
    errorMixin,
    listMixin
  ],
  props: {
    returnUrl: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      backendFilters: {
        jaar: null,
        maand: null
      },
      headers: [
        {
          align: ' d-none',
          sortable: true,
          text: 'ID',
          value: 'idTest'
        },
        {
          sortable: true,
          text: 'Naam',
          value: 'naam'
        },
        {
          sortable: true,
          text: 'Value',
          value: 'value'
        }
      ],
      sortBy: 'naam'
    }
  },
  mounted () {
    testService.Init(this)
    this.SetVariables()
    this.GetList()
  },
  methods: {
    async GetList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = { data: [{ naam: 'Test', value: '20' }, { naam: 'Test 2', value: '50' }] } // await testService.GetList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = this.CommonMixin_HardCopy(resp.data)
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    SetVariables () {
      this.listMixin.headers = this.headers
      this.listMixin.detailPage = 'Detail'
      this.listMixin.key = 'idTest'
    }
  }
}
</script>
