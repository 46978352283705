import formatMixin from '@/mixins/formatMixin'
import textMixin from '@/mixins/textMixin'
import blobService from '@/services/blobService'
import _ from 'lodash'

export default {
  mixins: [
    formatMixin,
    textMixin
  ],
  data () {
    return {
      commonMixin: {
        loggedIn: false,
        snackbar: {
          color: '',
          show: false,
          text: '',
          timeout: -1
        }
      }
    }
  },
  mounted () {
    blobService.Init(this)
  },
  computed: {
    CommonMixin_Disable () {
      // return this.CommonMixin_Error || this.CommonMixin_Loading
      return this.CommonMixin_Loading
    },
    CommonMixin_Error () {
      return this.$store.state.error
    },
    CommonMixin_Loading () {
      return this.$store.state.loading && this.$store.state.loading.length > 0
    },
    CommonMixin_Theme () {
      if (this.$vuetify.theme.isDark) {
        return this.$vuetify.theme.themes.dark
      } else {
        return this.$vuetify.theme.themes.light
      }
    }
  },
  methods: {
    CommonMixin_ArrayRemoveElement (array, element) {
      const index = array.indexOf(element)

      if (index !== -1) {
        array.splice(index, 1)
      }
    },
    CommonMixin_Clone (source) {
      let clone = _.clone(source)

      if (source != null) {
        if (Array.isArray(source)) {
          clone = []
          for (let index = 0; index < source.length; index++) {
            clone[index] = this.CommonMixin_Clone(source[index])
          }
        } else if (typeof (source) === 'object') {
          const keys = (Object.keys(source))

          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            clone[key] = this.CommonMixin_Clone(source[key])
          }
        }
      }

      return clone
    },
    CommonMixin_Confirmation (condition, text, defaultValue) {
      let confirmation = defaultValue

      if (condition) {
        confirmation = window.confirm(text)
      }

      return confirmation
    },
    CommonMixin_GetDate () {
      const today = new Date()
      const date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      return this.FormatMixin_FormatDateTime(date, this.formatMixin.date.form, this.formatMixin.date.database)
    },
    CommonMixin_GetElementByPropertyValue (list, property, value) {
      if (list == null) {
        return {}
      } else {
        return list.find(element => element[property] === value) ?? {}
      }
    },
    CommonMixin_GetElementsByPropertyValue (list, property, value) {
      if (list == null) {
        return []
      } else {
        return list.filter(element => element[property] === value) ?? []
      }
    },
    CommonMixin_GetFileNameFromHeader (contentDisposition) {
      const property = 'filename='
      const valueIndex = contentDisposition.indexOf(property) + property.length
      const endIndex = contentDisposition.indexOf(';', valueIndex)
      const name = contentDisposition.substr(valueIndex, endIndex - valueIndex)

      return name
    },
    CommonMixin_GetFileNameShortened (blob) {
      if (blob) {
        const name = blob.bestandsnaam
        const firstPart = 10
        const secondPart = 10
        const maxLength = firstPart + secondPart + 1

        if (name && name.length > maxLength) {
          return `${blob.bestandsnaam.substr(0, firstPart)}…${blob.bestandsnaam.substr(name.length - secondPart)}`
        } else {
          return blob.bestandsnaam
        }
      } else {
        return null
      }
    },
    CommonMixin_GetFileSize (blob, unit = '', showUnit = true, showZero = true) {
      if (blob && blob.blobDataString) {
        let size = Buffer.from(blob.blobDataString, 'base64').length
        let exponent = 0

        if (showZero || size) {
          switch (unit) {
            case 'KB':
              exponent = 1
              break
            case 'MB':
              exponent = 2
              break
            case 'GB':
              exponent = 3
              break
          }

          size /= Math.pow(1024, exponent)

          return showUnit ? `${this.FormatMixin_FormatDecimal(size)}${unit}` : size
        } else {
          return null
        }
      } else {
        return null
      }
    },
    CommonMixin_GetPageCount (list, numberPerPage) {
      const minimum = Math.floor(list.length / numberPerPage)
      const extra = list.length % numberPerPage > 0
      const total = minimum + (extra ? 1 : 0)
      return total > 0 ? total : 1
    },
    CommonMixin_GetPageList (list, page, numberPerPage) {
      const end = page * numberPerPage > list.length ? list.length : page * numberPerPage
      return list.slice((page - 1) * numberPerPage, end)
    },
    CommonMixin_HandleFileUpload (blobProperty, event, maxFileSize) {
      const files = event.target.files || event.dataTransfer.files

      if (files.length > 0) {
        if (maxFileSize && files[0].size > maxFileSize * 1024 * 1024) {
          alert(this.textMixin.messages.maxFileSize.replaceAll('@@maxFileSize', maxFileSize))
        } else {
          this.CommonMixin_ReadFileUpload(blobProperty, files[0], event.srcElement.value.replace(/^.*?([^\\/]*)$/, '$1'))
        }
      }
    },
    CommonMixin_HandleVFileUpload (blobProperty, file) {
      if (file == null) {
        blobProperty = null
      } else {
        this.CommonMixin_ReadFileUpload(blobProperty, file, file.name)
      }
    },
    CommonMixin_HardCompare (source, target) {
      return _.isEqual(source, target)
    },
    CommonMixin_Load (component, loading) {
      const object = {
        component: component,
        loading: loading
      }

      this.$store.dispatch('showLoader', object)
    },
    CommonMixin_ReadFileUpload (blobProperty, file, fileName) {
      this.CommonMixin_Load(this.$options.name, true)
      const reader = new FileReader()

      reader.onload = (e) => {
        blobProperty.blobDataString = e.target.result
        blobProperty.bestandsnaam = fileName
      }

      reader.readAsDataURL(file)
      this.CommonMixin_Load(this.$options.name, false)
    },
    CommonMixin_SelectedStyle (selected) {
      const backgroundColor = this.CommonMixin_Theme.primary ? this.CommonMixin_Theme.primary : 'gray'

      if (selected) {
        return {
          'background-color': backgroundColor,
          color: 'white'
        }
      }
    },
    CommonMixin_SetVCardHeights (elementId1, elementId2) {
      const element1 = document.getElementById(elementId1)
      const element2 = document.getElementById(elementId2)

      if (element1 && element2) {
        const rectangle1 = element1.getBoundingClientRect()
        const rectangle2 = element2.getBoundingClientRect()

        if (rectangle1.left !== rectangle2.left) {
          if (rectangle1.bottom > rectangle2.bottom) {
            element2.style.height = `${rectangle1.bottom - rectangle2.top}px`
          } else if (rectangle2.bottom > rectangle1.bottom) {
            element1.style.height = `${rectangle2.bottom - rectangle1.top}px`
          }
        }
      }
    },
    CommonMixin_Snackbar (text, color, timeout = 10) {
      const snackbar = {
        color: color,
        show: true,
        text: text,
        timeout: timeout
      }

      this.$store.dispatch('showSnackbar', snackbar)
    },
    CommonMixin_SuccessOrFailMessage (success, successMessage, failMessage) {
      if (success) {
        if (successMessage) {
          this.CommonMixin_Snackbar(successMessage, 'success')
        }
      } else {
        if (failMessage) {
          this.CommonMixin_Snackbar(failMessage, 'error')
        }
      }
    }
  }
}
