import axios from 'axios'

let _svc
let _that

export default {
  GetKamerTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/KAMER_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetMetselwerkTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/METSELWERK_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetDakTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/DAK_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetDakbedekkingTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/DAKBEDEKKING_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetSchrijnwerkTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/SCHRIJNWERK_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetBeglazingTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/BEGLAZING_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetIsolatieTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/ISOLATIE_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetNutsvoorzieningTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/NUTSVOORZIENING_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetVerwarmingssysteemTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/VERWARMINGSSYSTEEM_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetVerkoopbaarheidTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/VERKOOPBAARHEID_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetGebruikPandTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/GEBRUIK_PAND_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetErfdienstbaarheidTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/ERFDIENSTBAARHEID_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetAspectBetrokkenOmgevingTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/ASPECT_BETROKKEN_OMGEVING_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetStaatOnderhoudTuinTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/STAAT_ONDERHOUD_TUIN', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetStaatOnderhoudGebouwTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/STAAT_ONDERHOUD_GEBOUW', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetComfortTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/COMFORT_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetBijgebouwTypes: (bevatAnders = true) => {
    try {
      return _svc.get('Code/BIJGEBOUW_TYPE', {
        params: {
          bevatAnders: bevatAnders
        }
      })
    } catch {
      return null
    }
  },

  GetSelectieControlTypes: () => {
    try {
      return _svc.get('Code/SELECTIE_CONTROL_TYPE')
    } catch {
      return null
    }
  },

  GetCodeSelectie: (idReferentie, referentieType, selectieControlType) => {
    try {
      return _svc.get('CodeSelectie', {
        params: {
          idReferentie: idReferentie,
          referentieType: referentieType,
          selectieControlType: selectieControlType
        }
      })
    } catch {
      return null
    }
  },

  SaveMulticheck: (params) => {
    try {
      return _svc.put('CodeSelectie/Update', params)
    } catch {
      return null
    }
  },

  GetBanken: () => {
    try {
      return _svc.get('Code/BANK')
    } catch {
      return null
    }
  },

  GetBebouwingen: () => {
    try {
      return _svc.get('Code/BEBOUWING_TYPE')
    } catch {
      return null
    }
  },

  GetAardenGoed: () => {
    try {
      return _svc.get('Code/AARD_GOED')
    } catch {
      return null
    }
  },

  GetAsbestInventarisList: () => {
    try {
      return _svc.get('Code/ASBESTINVENTARIS')
    } catch {
      return null
    }
  },

  GetVerkoopTypeList: () => {
    try {
      return _svc.get('Code/VERKOOPTYPE')
    } catch {
      return null
    }
  },

  GetElektriciteitConformList: () => {
    try {
      return _svc.get('Code/ELEKTRICITEIT_CONFORM')
    } catch {
      return null
    }
  },

  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  }
}
