<template>
  <v-form
    v-model="detailMixin.valid"
    class="mx-3"
    ref="form"
  >
    <router-link
      class="urlNoUnderline"
      :to="detailMixin.returnUrl"
    >
      <v-icon
        class="iconBack iconPrimaryColor"
      >
        mdi-arrow-left-circle
      </v-icon>
      Terug naar overzicht
    </router-link>
    <v-card
      v-if="detailMixin.element && (detailMixin.element[detailMixin.key] || detailMixin.element.isNew)"
      class="pa-3 pt-6"
      :class="DetailMixin_Dirty ? 'dirtyCard' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editCard' : ''"
      id="vCardTest"
    >
      <v-row>
        <v-col
          cols="12"
          lg="2"
        >
          <v-text-field
            v-model="detailMixin.element.naam"
            :disabled="detailMixin.element.readOnly"
            label="Naam"
            :readonly="!detailMixin.edit || detailMixin.element.readOnly"
            :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <control-edit-commands
            :dirty="DetailMixin_Dirty"
            :disabled="CommonMixin_Disable"
            :edit="detailMixin.edit"
            :revertable="DetailMixin_Revertable && !detailMixin.element.isNew"
            @cancel="DetailMixin_SetUpLock()"
            @edit="DetailMixin_Edit()"
            @revertChanges="DetailMixin_RevertChanges()"
            @save="DetailMixin_Save(false, false, detailMixin.element.isNew)"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script>
import ControlEditCommands from './ControlEditCommands.vue'
import commonMixin from '@/mixins/commonMixin'
import detailMixin from '@/mixins/detailMixin'
import errorMixin from '@/mixins/errorMixin'
import testService from '@/services/testService'

export default {
  name: 'Detail',
  components: {
    ControlEditCommands
  },
  mixins: [
    commonMixin,
    detailMixin,
    errorMixin
  ],
  data () {
    return {}
  },
  mounted () {
    testService.Init(this)
    this.SetVariables()
    this.GetDetail()
  },
  methods: {
    async GetDetail () {
      this.CommonMixin_Load(this.$options.name, true)
      const id = this.DetailMixin_GetId()

      if (id) {
        const resp = { data: { idTest: 'Test', naam: 'Test' } } // await testService.GetDetail(id)

        if (resp && (resp.data || resp.data !== '')) {
          this.detailMixin.element = this.CommonMixin_Clone(resp.data)
          this.$forceUpdate()
        }
      }

      await this.DetailMixin_SetUpLock()

      this.CommonMixin_Load(this.$options.name, false)
    },
    SetVariables () {
      this.detailMixin.key = 'idTest'
      this.detailMixin.functions.save = testService.SaveDetail
      this.detailMixin.functions.validate = this.$refs.form.validate
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.DetailMixin_SetReturnUrl('List')
    }
  }
}
</script>
