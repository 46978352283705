<template>
  <schatting />
</template>

<script>
import Schatting from '../components/SchattingList'

export default {
  name: 'SchattingListView',
  components: {
    Schatting
  }
}
</script>
