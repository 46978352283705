<template>
  <div
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-autocomplete
      v-if="!readonly && !disabled && (hovering || focus)"
      v-model="selectedValue"
      :append-icon="ShowControls ? '$dropdown' : ''"
      :clearable="ShowControls"
      :dense="dense"
      :disabled="disabled"
      :itemValue="itemValue"
      :itemText="itemText"
      :items="Items"
      :label="label"
      :readonly="readonly"
      ref="control"
      :reverse="reverse"
      :rules="Rules"
      @blur="focus = false"
      @change="$emit('change', selectedValue)"
      @focus="focus = true"
    />
    <v-text-field
      v-if="readonly || disabled || !(hovering || focus) || focusWrong"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :readonly="readonly"
      :reverse="reverse"
      :rules="Rules"
      :value="TextValue"
      @focus="FocusWrong()"
    />
  </div>
</template>
<script>
export default {
  name: 'ControlSelect',
  props: {
    dense: Boolean,
    disabled: Boolean,
    items: Array,
    itemText: String,
    itemValue: String,
    label: String,
    readonly: Boolean,
    required: Boolean,
    requiredText: {
      default: 'Required.',
      type: String
    },
    reverse: Boolean,
    value: [Number, Object, String]
  },
  data () {
    return {
      focus: false,
      focusWrong: false,
      hovering: false,
      requiredRule: value => !!value || this.requiredText,
      selectedValue: null
    }
  },
  mounted () {
    this.FormatData()
  },
  computed: {
    Items () {
      return this.items ?? []
    },
    Rules () {
      if (this.required) {
        return [this.requiredRule]
      } else {
        return []
      }
    },
    ShowControls () {
      return !this.readonly && (this.hovering || this.focus)
    },
    TextValue () {
      if (this.items == null || this.selectedValue == null) {
        return null
      } else if (this.itemValue == null || this.itemText == null) {
        return this.selectedValue
      } else {
        const item = this.items.find(item => item[this.itemValue] === this.selectedValue)
        return item == null ? null : item[this.itemText]
      }
    }
  },
  watch: {
    selectedValue () {
      this.Emit()
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      this.FormatData()
    }
  },
  methods: {
    Emit () {
      if (this.selectedValue) {
        this.$emit('input', this.selectedValue)
      } else {
        this.$emit('input', null)
      }
    },
    FocusWrong () {
      this.focusWrong = true
      this.focus = true
      this.$nextTick(() => {
        this.$refs.control.focus()
        this.focusWrong = false
      })
    },
    FormatData () {
      if (this.value) {
        this.selectedValue = this.value
      } else {
        this.selectedValue = null
      }
    },
    MouseLeave () {
      this.hovering = false
      this.$emit('mouseleave')
    },
    MouseOver () {
      this.hovering = true
      this.$emit('mouseover')
    }
  }
}
</script>
