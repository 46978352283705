import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const theme = {
  primary: '#496592',
  secondary: '#356191',
  accent: '#E4ECF5',
  info: '#1a95ad',
  danger: '#aa0000',
  alert: '#ee7700'
}

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
})
