import axios from 'axios'

let _svc
let _that

export default {
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  },

  GetVergelijkingspuntenPaged: (sortBy, sortType, search, page = 1, pageSize = 10, aard = null, idPostcode = null, excludedVergelijkingspunten = []) => {
    try {
      let excludedVergelijkingspuntenQuery = ''
      let postcodeQuery = ''
      let aardQuery = ''
      if (excludedVergelijkingspunten.length) {
        excludedVergelijkingspunten.forEach(idVergelijkingspunt => {
          if (idVergelijkingspunt) {
            excludedVergelijkingspuntenQuery += `&excludedVergelijkingspunten=${idVergelijkingspunt}`
          }
        })
      }
      if (aard != null) {
        aardQuery = `&aard=${aard}`
      }
      if (idPostcode != null) {
        postcodeQuery = `&idPostcode=${idPostcode}`
      }
      var res = _svc.get('Vergelijkingspunt/Paged?' +
      `sortBy=${sortBy === undefined || sortBy === null ? 'Pand' : sortBy}` +
      `&sortType=${sortType === undefined || sortType === null ? 'asc' : sortType}` +
      `&search=${search === undefined || search === null ? ' ' : search}` +
      `&page=${page}` +
      `&pageSize=${pageSize}` +
      aardQuery +
      postcodeQuery +
      excludedVergelijkingspuntenQuery
      )
      return res
    } catch {
      return null
    }
  },
  Create: (vergelijkingspunt) => {
    try {
      return _svc.post('Vergelijkingspunt/Create', vergelijkingspunt)
    } catch {
      return null
    }
  },
  Update: (vergelijkingspunt) => {
    try {
      return _svc.put('Vergelijkingspunt/Update', vergelijkingspunt)
    } catch {
      return null
    }
  },
  Delete: (idVergelijkingspunt) => {
    try {
      return _svc.delete('Vergelijkingspunt/Delete', {
        params: {
          idVergelijkingspunt: idVergelijkingspunt
        }
      })
    } catch {
      return null
    }
  },
  GetById: (idVergelijkingspunt) => {
    try {
      return _svc.get(`Vergelijkingspunt/GetById?id=${idVergelijkingspunt}`)
    } catch {
      return null
    }
  }
}
