<template>
  <div class="pa-5">
    <v-btn class="primary" @click="GetGeoMaps(-1, true)"
      :loading="loadingMaps">Laad Kaarten</v-btn>
    <v-row>
      <v-col>
        <v-slider
          :disabled="loadingMaps"
          max="2.5"
          min="0.5"
          step="0.5"
          v-model="zoom1"
          @input="Zoom()"
          style="width: 250px"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          @click:append="zoomIn(1)"
          @click:prepend="zoomOut(1)"
        ></v-slider>
        <div v-if="!loadingMap1 && mapimage1 && mapimage1 !== 'error'">
          <img :src="mapimage1" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else-if="mapimage3 === 'error'">
          <img src="../assets/images/blank-map-not-found.png" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else>
          <v-progress-circular
            v-if="loadingMap1"
            :size="50"
            color="primary"
            style="position: absolute; margin: 10px"
            indeterminate
          ></v-progress-circular>
          <img src="../assets/images/blank-map.png" width="256" height="256" class="ma-1"/>
        </div>
      </v-col>
      <v-col>
        <v-slider
          :disabled="loadingMaps"
          max="2.5"
          min="0.5"
          step="0.5"
          v-model="zoom2"
          @input="Zoom()"
          style="width: 250px"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          @click:append="zoomIn(2)"
          @click:prepend="zoomOut(2)"
        ></v-slider>
        <div v-if="!loadingMap2 && mapimage2 && mapimage2 !== 'error'">
          <img :src="mapimage2" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else-if="mapimage3 === 'error'">
          <img src="../assets/images/blank-map-not-found.png" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else>
          <v-progress-circular
            v-if="loadingMap2"
            :size="50"
            color="primary"
            style="position: absolute; margin: 10px"
            indeterminate
          ></v-progress-circular>
          <img src="../assets/images/blank-map.png" width="256" height="256" class="ma-1"/>
        </div>
      </v-col>
      <v-col>
        <v-slider
          :disabled="loadingMaps"
          max="2.5"
          min="0.5"
          step="0.5"
          v-model="zoom3"
          @input="Zoom()"
          style="width: 250px;"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          @click:append="zoomIn(3)"
          @click:prepend="zoomOut(3)"
        ></v-slider>
        <div v-if="!loadingMap3 && mapimage3 && mapimage3 !== 'error'">
          <img :src="mapimage3" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else-if="mapimage3 === 'error'">
          <img src="../assets/images/blank-map-not-found.png" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else>
          <v-progress-circular
            v-if="loadingMap3"
            :size="50"
            color="primary"
            style="position: absolute; margin: 10px"
            indeterminate
          ></v-progress-circular>
          <img src="../assets/images/blank-map.png" width="256" height="256" class="ma-1"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import constantMixin from '../mixins/constantMixin'
import geoService from '../services/geoService'

export default {
  mixins: [
    constantMixin
  ],
  props: {
    straat: String,
    huisnummer: String,
    gemeente: String,
    sliderZoom1: Number,
    sliderZoom2: Number,
    sliderZoom3: Number
  },
  data () {
    return {
      postcodes: null,
      mapimages1: null,
      mapimages2: null,
      mapimages3: null,
      mapimage1: null,
      mapimage2: null,
      mapimage3: null,
      location: {
        X_lambert72: 0,
        Y_lambert72: 0
      },
      loadingMap1: false,
      loadingMap2: false,
      loadingMap3: false,
      zoom1: this.sliderZoom1,
      zoom2: this.sliderZoom2,
      zoom3: this.sliderZoom3
    }
  },
  computed: {
    loadingMaps () {
      return this.loadingMap1 && this.loadingMap2 && this.loadingMap3
    }
  },
  mounted () {
    geoService.Init(this)
    this.GetGeoMaps(-1)
  },
  watch: {
    sliderZoom1 (value) {
      this.zoom1 = value
      if (this.mapimages1 && this.mapimages1.find(element => element.scale === this.zoom1)) {
        this.mapimage1 = this.mapimages1.find(element => element.scale === this.zoom1).base64
      }
    },
    sliderZoom2 (value) {
      this.zoom2 = value
      if (this.mapimages2 && this.mapimages2.find(element => element.scale === this.zoom2)) {
        this.mapimage2 = this.mapimages2.find(element => element.scale === this.zoom2).base64
      }
    },
    sliderZoom3 (value) {
      this.zoom3 = value
      if (this.mapimages3 && this.mapimages3.find(element => element.scale === this.zoom3)) {
        this.mapimage3 = this.mapimages3.find(element => element.scale === this.zoom3).base64
      }
    },
    zoom1 (value) {
      this.GetGeoMaps(1)
    },
    zoom2 (value) {
      this.GetGeoMaps(2)
    },
    zoom3 (value) {
      this.GetGeoMaps(3)
    }
  },
  methods: {
    GetGeoMaps (map = -1, forceReload = false) {
      if (this.straat && this.gemeente && this.huisnummer) {
        if (map === -1) {
          this.loadingMap1 = true
          this.loadingMap2 = true
          this.loadingMap3 = true
          this.mapimage1 = null
          this.mapimage2 = null
          this.mapimage3 = null
          this.GetGeoMapGRB(forceReload)
          this.GetGeoMap2GRB(forceReload)
          this.GetGeoMapGewestplan(forceReload)
        } else {
          if (map === 1) {
            this.loadingMap1 = true
            this.mapimage1 = null
            this.GetGeoMapGRB(forceReload)
          }
          if (map === 2) {
            this.loadingMap2 = true
            this.mapimage2 = null
            this.GetGeoMap2GRB(forceReload)
          }
          if (map === 3) {
            this.loadingMap3 = true
            this.mapimage3 = null
            this.GetGeoMapGewestplan(forceReload)
          }
        }
      }
    },
    async GetGeoMap (forceReload, number, mapType) {
      if (this.straat && this.huisnummer && this.gemeente) {
        const mapimages = `mapimages${number}`
        const mapimage = `mapimage${number}`
        const loadingMap = `loadingMap${number}`
        const image = !this[mapimages] || forceReload ? await geoService.GetGeoMapGRB(this.straat, this.huisnummer, this.gemeente, 300, 300, mapType) : null

        if (image?.data || this[mapimages]) {
          this[mapimages] ??= image.data.images
          this[mapimages] = forceReload ? image.data.images : this[mapimages]

          if (this[mapimages]) {
            const mapimageElement = this[mapimages].find(element => element.scale === 3 - this[`zoom${number}`])

            if (mapimageElement) {
              this[mapimage] = mapimageElement.base64
            } else {
              this[mapimage] = 'error'
            }
          } else {
            this[mapimage] = 'error'
          }

          this[loadingMap] = false
        }
      }
    },
    async GetGeoMapGRB (forceReload) {
      await this.GetGeoMap(forceReload, 1, this.constantMixin.mapType.google_stratenplan)
    },
    async GetGeoMap2GRB (forceReload) {
      await this.GetGeoMap(forceReload, 2, this.constantMixin.mapType.grb_bsk)
    },
    async GetGeoMapGewestplan (forceReload) {
      await this.GetGeoMap(forceReload, 3, this.constantMixin.mapType.gewestplan)
    },
    Zoom () {
      this.$emit('zoom', {
        zoom1: this.zoom1,
        zoom2: this.zoom2,
        zoom3: this.zoom3
      })
    },
    zoomIn (map) {
      switch (map) {
        case 1:
          this.zoom1 += this.zoom1 < 2.5 ? 0.5 : 0
          break
        case 2:
          this.zoom2 += this.zoom2 < 2.5 ? 0.5 : 0
          break
        case 3:
          this.zoom3 += this.zoom3 < 2.5 ? 0.5 : 0
          break
      }
      this.Zoom()
    },
    zoomOut (map) {
      switch (map) {
        case 1:
          this.zoom1 -= this.zoom1 > 0.5 ? 0.5 : 0
          break
        case 2:
          this.zoom2 -= this.zoom2 > 0.5 ? 0.5 : 0
          break
        case 3:
          this.zoom3 -= this.zoom3 > 0.5 ? 0.5 : 0
          break
      }
      this.Zoom()
    }
  }
}
</script>
