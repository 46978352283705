<template>
  <v-card
    class="ma-3"
    v-if="AuthenticationMixin_LoggedIn && AuthenticationMixin_HasRights(['admin'])">
    <v-row class="justify-space-between">
      <v-col cols="9" md="6" lg="4">
        <v-text-field
          class="pa-2"
          clearable
          v-model="search"
          v-on:input="callDebounceSearch"
          append-icon="mdi-magnify"
          label="Zoeken"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="6" lg="8">
        <v-btn
          class="ma-5 float-right d-none d-md-block"
          color="primary"
          @click="openGebruiker()"
        >
          <v-icon>mdi-plus</v-icon>
          Nieuwe gebruiker
        </v-btn>
        <v-btn
        class="ma-5 float-right d-md-none"
        color="primary"
        @click="openGebruiker()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :search="search"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Gebruikers per pagina',
        'items-per-page-options': [25, 25, 50, 100]
      }"
      class="elevation-1"
      @click:row="openGebruiker"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip
        style="width: 100px;"
          small
          :color="getRol(item.type).color"
          dark
        >
        <span style="width: 100px; text-align: center;">{{ getRol(item.type).name }}</span>
        </v-chip>
      </template>

      <template v-slot:[`item.acties`]="{ item }">
        <v-btn
        icon
        class="deleteItem"
        @click="deleteItem(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
    </template>

    <template v-slot:[`item.actief`]="{ item }">
        <v-simple-checkbox
        class="ma-0"
          color="green"
          v-model="item.actief"
          disabled
        ></v-simple-checkbox>
      </template>

    </v-data-table>

      <v-dialog
        v-model="deletedialog"
        max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Gebruiker verwijderen
          </v-card-title>
          <v-card-text v-if="selectedItem">
            Ben je zeker dat je '<strong>{{ selectedItem.achternaam }} {{ selectedItem.voornaam }}</strong>' wilt verwijderen?
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              @click="deletedialog = false">
              Nee
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="DeleteGebruiker">
              Ja, verwijderen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-form
        v-model="valid"
        ref="form"
        lazy-validation>
        <v-dialog
          v-model="editdialog"
          max-width="500">
          <v-card>
            <v-card-title class="text-h5">
              {{ editdialogTitle }}
            </v-card-title>
            <v-card-text v-if="selectedItem">
                <v-text-field
                  v-model="selectedItem.voornaam"
                  label="Voornaam*"
                  required
                  :rules="[v => !!v || 'Voornaam is verplicht']"/>
                <v-text-field
                  v-model="selectedItem.achternaam"
                  label="Achternaam*"
                  required
                  :rules="[v => !!v || 'Achternaam is verplicht']"/>
                <v-text-field
                  label="Email*"
                  v-model="selectedItem.email"
                  :rules="[validationMixin.rules.email, v => !!v || 'Email is verplicht']"
                  :prepend-inner-icon="'mdi-email'"
                  required/>
                <v-text-field
                  v-model="selectedItem.lanNummer"
                  label="LAN nummer"/>
                <v-text-field
                  v-model="selectedItem.vlabelNummer"
                  label="VLABEL nummer"/>
                <control-select
                  label="Rol"
                  v-model="selectedItem.type"
                  :items="rollen"
                  itemText="name"
                  itemValue="value"
                  required/>
                <v-checkbox
                v-if="isEditGebruikerDialog"
                label="Wachtwoord wijzigen"
                v-model="editDialogWachtwoordWijzigen"/>
                <template
                  v-if="!isEditGebruikerDialog || editDialogWachtwoordWijzigen"
                >
                  <v-text-field
                    class="mt-5"
                    :disabled="isEditGebruikerDialog && !editDialogWachtwoordWijzigen"
                    v-model="selectedItem.wachtwoord"
                    :prepend-inner-icon="'mdi-lock'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="Wachtwoord*"
                    @click:append="showPassword = !showPassword"
                    :rules="[v => (!!v || isEditGebruikerDialog && !editDialogWachtwoordWijzigen) || 'Wachtwoord is verplicht']"/>
                  <v-text-field
                    :disabled="isEditGebruikerDialog && !editDialogWachtwoordWijzigen"
                    v-model="selectedItem.herhaalwachtwoord"
                    :prepend-inner-icon="'mdi-lock'"
                    :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword2 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Herhaal wachtwoord*"
                    @click:append="showPassword2 = !showPassword2"
                    :rules="[v => (!!v || isEditGebruikerDialog && !editDialogWachtwoordWijzigen) || 'Herhaal wachtwoord is verplicht', selectedItem.wachtwoord === selectedItem.herhaalwachtwoord || 'Wachtwoorden komen niet overeen']"/>
                </template>
                <v-checkbox
                  label="Actief"
                  v-model="selectedItem.actief"
                  required/>
                <label for="template">Handtekening (afbeelding)</label>
                <control-file-input-drag-and-drop-vue
                  id="handtekening"
                  single
                  v-model="handtekening"
                  @download="Download($event)"
                  :accept="['.png', '.jpg', '.jpeg', '.gif']"
                  required
                />
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary darken-1"
                text
                @click="editdialog = false">
                Terug
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!isEditGebruikerDialog"
                color="primary darken-1"
                @click="CreateGebruiker">
                Aanmaken
              </v-btn>
              <v-btn
                v-if="isEditGebruikerDialog"
                color="primary darken-1"
                @click="UpdateGebruiker">
                Opslaan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>

    </v-card>
    <v-card class="ma-3" v-else>
    <h2 class="primary--text pa-3">Log in als admin om de gebruikerlijst te kunnen bekijken.</h2>
  </v-card>
</template>

<script>
import _ from 'lodash'
import errorMixin from '../mixins/errorMixin'
import authenticationService from '../services/authenticationService'
import authenticationMixin from '../mixins/authenticationMixin'
import blobMixin from '../mixins/blobMixin'
import validationMixin from '../mixins/validationMixin'
import constantMixin from '../mixins/constantMixin'
import ControlSelect from './ControlSelect.vue'
import ControlFileInputDragAndDropVue from './ControlFileInputDragAndDrop.vue'

export default {
  components: {
    ControlFileInputDragAndDropVue,
    ControlSelect
  },
  mixins: [
    errorMixin,
    authenticationMixin,
    blobMixin,
    validationMixin,
    constantMixin
  ],
  name: 'Gebruikers',
  data () {
    return {
      rollen: [],
      valid: true,
      showPassword: false,
      showPassword2: false,
      selectedItem: null,
      deletedialog: false,
      editdialog: false,
      isEditGebruikerDialog: false,
      editdialogTitle: null,
      action: false,
      totalItems: 0,
      items: [],
      search: null,
      loading: true,
      options: {},
      editDialogWachtwoordWijzigen: false,
      handtekening: null,
      handtekeningType: '5420493B-6CB5-42DB-92AD-6C22DD796F6F',
      headers: [
        {
          text: 'Rol',
          align: 'start',
          value: 'type'
        },
        {
          text: 'Voornaam',
          align: 'start',
          value: 'voornaam'
        },
        {
          text: 'Achternaam',
          align: 'start',
          value: 'achternaam'
        },
        {
          text: 'Email',
          align: 'start',
          value: 'email'
        },
        {
          text: 'LAN',
          align: 'start',
          value: 'lanNummer'
        },
        {
          text: 'VLABEL',
          align: 'start',
          value: 'vlabelNummer'
        },
        {
          text: 'Actief',
          align: 'start',
          value: 'actief'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'acties'
        }
      ]
    }
  },
  mounted () {
    authenticationService.Init(this)
    this.setRollen()
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    deletedialog () {
      if (!this.deletedialog) {
        this.action = false
      }
    }
  },
  methods: {
    async GetGebruikersPaged (sortBy, sortType, search, page, pageSize) {
      const response = await authenticationService.GetGebruikersPaged(sortBy, sortType, search, page, pageSize)
      if (response && response.data) {
        const data = response.data
        this.items = data.items
        var blobs = await this.BlobMixin_GetFiles(this.handtekeningType, null)
        this.items.forEach(x => {
          var blob = blobs.filter(b => b.idBlob === x.idBlobHandtekening)
          x.bestand = blob.length > 0 ? blob[0].name : null
          return x
        })
        this.totalItems = data.totalItems
      }
    },
    async getDataFromApi () {
      this.loading = true
      await this.GetGebruikersPaged(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc', this.search, this.options.page, this.options.itemsPerPage).then(data => {
        this.loading = false
      })
    },
    getRol (rol) {
      switch (rol) {
        case 1:
          return { color: 'alert', name: 'Admin' }
        case 2:
          return { color: 'primary', name: 'Gebruiker' }
      }
    },
    callDebounceSearch: _.debounce(function () {
      this.debounceSearch()
    }, 500),
    debounceSearch () {
      this.getDataFromApi()
    },
    async openGebruiker (gebruiker) {
      this.editDialogWachtwoordWijzigen = false
      this.showPassword = false
      this.showPassword2 = false
      this.$refs.form.resetValidation()
      if (!this.action) {
        if (gebruiker) {
          this.editdialogTitle = 'Wijzig gebruiker'
          this.isEditGebruikerDialog = true
          this.selectedItem = {
            idGebruiker: gebruiker.idGebruiker,
            voornaam: gebruiker.voornaam,
            achternaam: gebruiker.achternaam,
            email: gebruiker.email,
            lanNummer: gebruiker.lanNummer,
            vlabelNummer: gebruiker.vlabelNummer,
            type: gebruiker.type,
            actief: gebruiker.actief,
            idBlobHandtekening: gebruiker.idBlobHandtekening
          }
          var blobs = (await this.BlobMixin_GetFiles(this.handtekeningType, null)).filter(x => x.idBlob === this.selectedItem.idBlobHandtekening)
          this.handtekening = []
          if (blobs.length > 0) {
            this.handtekening.push(blobs[0])
          }
        } else {
          this.editdialogTitle = 'Nieuwe gebruiker'
          this.isEditGebruikerDialog = false
          this.selectedItem = {
            voornaam: null,
            achternaam: null,
            email: null,
            lanNummer: null,
            vlabelNummer: null,
            type: 2,
            actief: true,
            wachtwoord: null,
            herhaalwachtwoord: null
          }
          this.handtekening = []
        }
        this.editdialog = true
      }
    },
    deleteItem (item) {
      this.selectedItem = item
      this.action = true
      this.deletedialog = true
    },
    setRollen () {
      const type = this.constantMixin.authenticatie.type
      const types = Object.keys(type)
      this.rollen = []
      types.forEach((key) => {
        if (type[key] !== 0) {
          this.rollen.push(
            {
              name: key,
              value: type[key]
            }
          )
        }
      })
    },
    async HandleBlob (handleType = 'create') {
      var success = false
      this.selectedItem.IdBlob = null

      if (this.$refs.form.validate()) {
        if (this.handtekening !== null) {
          // save new or updated blobs
          const savedFiles = await this.BlobMixin_SaveFiles(this.handtekening.filter(x => x.status !== this.blobMixin.status.deleted), this.handtekeningType, null)

          if (savedFiles != null) {
            this.selectedItem.idBlobHandtekening = savedFiles[0].idBlob
          }
        }

        let response = null
        switch (handleType) {
          case 'create' :
            response = await authenticationService.Create(this.selectedItem)
            break
          case 'update' :
            response = await authenticationService.Update(this.selectedItem)
            break
        }

        if (this.handtekening !== null) {
          // delete 'deleted' blobs
          await this.BlobMixin_SaveFiles(this.handtekening.filter(x => x.status === this.blobMixin.status.deleted), this.handtekeningType, null)
        }

        if (response && response.data) {
          success = true
          this.editdialog = false
          this.getDataFromApi()
        }

        switch (handleType) {
          case 'create' :
            this.CommonMixin_SuccessOrFailMessage(success, 'Gebruiker aangemaakt.', 'Gebruiker aanmaken mislukt.')
            break
          case 'update' :
            this.CommonMixin_SuccessOrFailMessage(success, 'Gebruiker aangepast.', 'Gebruiker aanpassen mislukt.')
            break
        }
      }
    },
    async CreateGebruiker () {
      this.HandleBlob()
    },
    async UpdateGebruiker () {
      this.HandleBlob('update')
    },
    async DeleteGebruiker () {
      this.BlobMixin_DeleteBlobById(this.selectedItem.idBlobHandtekening)

      const response = await authenticationService.Delete(this.selectedItem.idGebruiker)
      if (response && response.data) {
        this.editdialog = false
        this.deletedialog = false
        this.getDataFromApi()
      }
    }
  }
}
</script>
