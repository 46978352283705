<template>
  <v-form class='my-2'
    v-model="detailMixin.valid"
    ref="formBouwmateriaal">
    <div
      v-if="detailMixin.element && detailMixin.element.multicheck && detailMixin.element.fields"
      :class="DetailMixin_Dirty ? 'dirtyForm' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editForm' : ''"
      >
      <h2 class='primary--text'>Beschrijving van de gebruikte bouwmaterialen</h2>

      <div v-if="selectieControlTypes && selectieControlTypes.length">
        <control-multi-check :cols="3" radio :list="metselwerkTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[0].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[0].langeBeschrijving"/>
        <v-divider></v-divider>
        <control-multi-check :cols="3" :list="dakTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[1].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[1].langeBeschrijving"/>
        <v-divider></v-divider>
        <control-multi-check :cols="3" :list="dakbedekkingTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[2].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[2].langeBeschrijving"/>
        <v-divider></v-divider>
        <control-multi-check :cols="3" :list="schrijnwerkTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[3].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[3].langeBeschrijving"/>
        <v-divider></v-divider>
        <control-multi-check :cols="3" :list="beglazingTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[4].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[4].langeBeschrijving"/>
        <v-divider></v-divider>
        <control-multi-check :cols="3" radio :list="isolatieTypes" v-model="detailMixin.element.multicheck[selectieControlTypes[5].idCode]" :selectieControlTypes="selectieControlTypes" :title="selectieControlTypes[5].langeBeschrijving"/>
      </div>
      <v-divider class="mb-5"></v-divider>

      <h3 class="secondary--text my-3">Overige</h3>
      <v-row class="pb-0">
        <v-col cols="12">
          <v-row class="pb-0">
              <v-col cols="12" md="4">
              <v-textarea label="Funderingen" auto-grow outlined v-model="detailMixin.element.fields.funderingen"/>
              </v-col>
              <v-col cols="12" md="4">
              <v-textarea label="Vloeren" auto-grow outlined v-model="detailMixin.element.fields.vloeren"/>
              </v-col>
              <v-col cols="12" md="4">
              <v-textarea label="Afwerking" auto-grow outlined v-model="detailMixin.element.fields.afwerking"/>
              </v-col>
              <v-col cols="12" md="4">
                <control-number label="EPC-score (kWh/m²/jaar)" :decimals="0" v-model="detailMixin.element.fields.epcScore"/>
              </v-col>
              <v-col cols="12" md="4">
                <control-select
                  :items="elektriciteitConformList"
                  label="Elektriciteit"
                  v-model="detailMixin.element.fields.idCodeElectriciteitConform"
                  :itemValue="'idCode'"
                  :itemText="'langeBeschrijving'"
                />
              </v-col>
              <v-col cols="12" md="4">
                <control-select
                  :items="asbestInventarisList"
                  label="Asbest inventaris"
                  v-model="detailMixin.element.fields.idCodeAsbestInventaris"
                  :itemValue="'idCode'"
                  :itemText="'langeBeschrijving'"
                />
              </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="mb-5 mt-10"></v-divider>

      <h3 class="secondary--text my-3">Bijlage</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6">
          <control-file-input-drag-and-drop v-model="detailMixin.element.files" @download="Download($event)" :accept="['.png', '.jpg', '.jpeg', '.gif']"/>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import blobMixin from '../../mixins/blobMixin'
import commonMixin from '../../mixins/commonMixin'
import detailMixin from '../../mixins/detailMixin'
import errorMixin from '../../mixins/errorMixin'
import codeService from '../../services/codeService'
import schattingService from '../../services/schattingService'
import ControlFileInputDragAndDrop from '../ControlFileInputDragAndDrop.vue'
import ControlMultiCheck from '../ControlMultiCheck.vue'
import ControlNumber from '../ControlNumber.vue'
import ControlSelect from '../ControlSelect.vue'

export default {
  components: {
    ControlFileInputDragAndDrop,
    ControlMultiCheck,
    ControlNumber,
    ControlSelect
  },
  mixins: [
    errorMixin,
    commonMixin,
    detailMixin,
    blobMixin
  ],
  props: {
    idSchatting: {
      default: null,
      type: String
    },
    visible: Boolean
  },
  data () {
    return {
      referentieType: '0b197cc7-144e-4684-a1e3-6f2c07c21238',
      metselwerkTypes: null,
      dakTypes: null,
      dakbedekkingTypes: null,
      schrijnwerkTypes: null,
      beglazingTypes: null,
      isolatieTypes: null,
      selectieControlTypes: {},
      elektriciteitConformList: [],
      asbestInventarisList: []
    }
  },
  mounted () {
    codeService.Init(this)
  },
  watch: {
    async visible () {
      if (this.visible) {
        await this.SetVariables()
        await this.loadEverything()
        await this.SetSelectionList()
      } else if (this.DetailMixin_Dirty) {
        this.DetailMixin_Save()
      }
    }
  },
  methods: {
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    async loadEverything () {
      this.CommonMixin_Load(this.$options.name, true)
      await this.SetMetselwerkTypes()
      await this.SetDakTypes()
      await this.SetDakbedekkingTypes()
      await this.SetSchrijnwerkTypes()
      await this.SetBeglazingTypes()
      await this.SetIsolatieTypes()
      await this.SetSelectieControlTypes()
      await this.GetElektriciteitConformList()
      await this.GetAsbestInventarisList()
      this.CommonMixin_Load(this.$options.name, false)
    },
    async GetAsbestInventarisList () {
      const response = await codeService.GetAsbestInventarisList()
      if (response && response.data) {
        const data = response.data
        this.asbestInventarisList = data
      }
    },
    async GetElektriciteitConformList () {
      const response = await codeService.GetElektriciteitConformList()
      if (response && response.data) {
        const data = response.data
        this.elektriciteitConformList = data
      }
    },
    async SetMetselwerkTypes () {
      const response = await codeService.GetMetselwerkTypes()
      if (response && response.data) {
        const data = response.data
        this.metselwerkTypes = data
      }
    },
    async SetDakTypes () {
      const response = await codeService.GetDakTypes()
      if (response && response.data) {
        const data = response.data
        this.dakTypes = data
      }
    },
    async SetDakbedekkingTypes () {
      const response = await codeService.GetDakbedekkingTypes()
      if (response && response.data) {
        const data = response.data
        this.dakbedekkingTypes = data
      }
    },
    async SetSchrijnwerkTypes () {
      const response = await codeService.GetSchrijnwerkTypes()
      if (response && response.data) {
        const data = response.data
        this.schrijnwerkTypes = data
      }
    },
    async SetBeglazingTypes () {
      const response = await codeService.GetBeglazingTypes()
      if (response && response.data) {
        const data = response.data
        this.beglazingTypes = data
      }
    },
    async SetIsolatieTypes () {
      const response = await codeService.GetIsolatieTypes()
      if (response && response.data) {
        const data = response.data
        this.isolatieTypes = data
      }
    },
    async SetSelectionList () {
      const id = this.DetailMixin_GetId()

      if (id) {
        const responseBouwmaterialenMulticheck = await codeService.GetCodeSelectie(id, this.referentieType)
        var element = {
          multicheck: {},
          fields: {},
          files: []
        }

        element.files = await this.BlobMixin_GetFiles(this.idSchatting, this.referentieType)

        if (responseBouwmaterialenMulticheck && (responseBouwmaterialenMulticheck.data || responseBouwmaterialenMulticheck.data !== '')) {
          element.multicheck = this.CommonMixin_Clone(responseBouwmaterialenMulticheck.data)
          this.$forceUpdate()
        }

        const responseBouwmaterialen = await schattingService.GetBouwmateriaalByIdSchatting(id)
        if (responseBouwmaterialen && (responseBouwmaterialen.data || responseBouwmaterialen.data !== '')) {
          element.fields = this.CommonMixin_Clone(responseBouwmaterialen.data)
          this.$forceUpdate()
        }

        this.detailMixin.element = this.CommonMixin_Clone(element)

        this.DetailMixin_InitDirtyWatch()
      }
    },
    async SetSelectieControlTypes () {
      const response = await codeService.GetSelectieControlTypes()
      if (response && response.data) {
        const data = response.data
        this.selectieControlTypes = data.filter(x => x.waarde >= 400 && x.waarde <= 499)
      }
    },
    SetVariables () {
      this.detailMixin.key = 'id'
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.detailMixin.functions.validate = this.$refs.formBouwmateriaal.validate
      this.detailMixin.functions.save = this.save
    },
    async save (element) {
      var multicheck = await codeService.SaveMulticheck({ IdReferentie: this.idSchatting, IdCodeReferentieType: this.referentieType, MultiSelects: element.multicheck })
      var fields = await schattingService.UpdateSchattingBouwmateriaal(element.fields)
      await this.BlobMixin_SaveFiles(element.files, this.idSchatting, this.referentieType)
      var multicheckData = null
      var fieldsData = null
      if (multicheck && multicheck.data) {
        multicheckData = multicheck.data
      }
      if (fields && fields.data) {
        fieldsData = fields.data
      }
      return {
        data: {
          multicheck: multicheckData,
          fields: fieldsData
        }
      }
    },
    Validate () {
      this.$refs.formBouwmateriaal.validate()
      return this.detailMixin.valid
    }
  }
}
</script>
