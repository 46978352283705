import { render, staticRenderFns } from "./AdminTemplatesView.vue?vue&type=template&id=33ba7c8a&"
import script from "./AdminTemplatesView.vue?vue&type=script&lang=js&"
export * from "./AdminTemplatesView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports