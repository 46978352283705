import axios from 'axios'

let _svc
let _that

export default {
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  },
  ReleaseLock: (releaseRequest) => {
    try {
      return _svc.post('Lock/Release', releaseRequest)
    } catch {
      return null
    }
  },
  SetLock: (lockRequest) => {
    try {
      return _svc.post('Lock', lockRequest)
    } catch {
      return null
    }
  }
}
