<template>
  <list />
</template>

<script>
import List from '../components/List'

export default {
  name: 'ListView',
  components: {
    List
  }
}
</script>
