<template>
  <div>
    <v-file-input
      v-model="file"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      prepend-icon=""
      :prepend-inner-icon="Icon"
      :readonly="readonly"
      :rules="Rules"
    />
  </div>
</template>

<script>
export default {
  name: 'ControlInput',
  props: {
    dense: Boolean,
    disabled: Boolean,
    label: String,
    maxFileSize: Number,
    maxFileSizeText: {
      default: 'File size should be less than @@maxFileSizeMB.',
      type: String
    },
    multiple: Boolean,
    readonly: Boolean,
    required: Boolean,
    requiredText: {
      default: 'Required.',
      type: String
    },
    value: Object
  },
  data () {
    return {
      ignore: true,
      file: null,
      formattedFile: null,
      rules: {
        maxFileSize: value => !value || !value.size || value.size < (this.maxFileSize * 1024 * 1024) || this.maxFileSizeText.replaceAll('@@maxFileSize', this.maxFileSize),
        required: value => !!value || this.requiredText
      }
    }
  },
  mounted () {
    this.FormatData()

    if (!this.value.bestandsnaam) {
      this.ignore = false
    }
  },
  computed: {
    Icon () {
      // return 'mdi-paperclip'
      return ''
    },
    Rules () {
      const rules = []

      if (this.maxFileSize) {
        rules.push(this.rules.maxFileSize)
      }

      if (this.required) {
        rules.push(this.rules.required)
      }

      return rules
    }
  },
  watch: {
    file () {
      if (!this.ignore) {
        this.Emit()
      } else {
        this.ignore = false
      }
    },
    value () {
      this.ignore = this.value.isEdited === false
      this.FormatData()
    }
  },
  methods: {
    Emit () {
      if (this.file) {
        if (this.file.size) {
          this.$emit('name', this.file.name)

          const reader = new FileReader()

          reader.onload = (e) => {
            this.$emit('data', e.target.result)
          }

          reader.readAsDataURL(this.file)
        }
      } else {
        this.$emit('clear')
        this.$emit('name', null)
        this.$emit('data', null)
      }

      this.$emit('change')
    },
    FormatData () {
      if (this.value && this.value.bestandsnaam && this.value.bestandsnaam.trim() !== '') {
        this.file = {
          name: this.value.bestandsnaam
        }
      } else {
        this.file = null
      }
    }
  }
}
</script>
